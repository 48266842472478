@import "_variables";
@import "_mixins";

.pm-note
{
	&s-list
	{
		list-style:none;
		margin:0;
		padding:0;
	}

	position:relative;
	list-style:none;
	margin:0 0 2rem;
	padding:0;
	cursor:pointer;

	&-title
	{
		border:1px solid @gray;
		padding:0.35rem;
		text-align:center;
		background-color: rgba(255,255,255,0.6);
	}
	&-body {
		border: 1px solid #999;
		border-top: none;
		margin-bottom:0.5rem;

		pre {
			background-color: transparent;
			font-family: inherit;
			border-radius: 0;
			border:none;
			white-space: normal;
		}
	}

	.actions
	{
		position: absolute;
		right: 18px;
		top: 4px;
	}
}