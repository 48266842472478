@import "_variables";
@import "_mixins";

.nav-tabs
{
	background-color:lighten(@body-bg, 10%);
	margin:0;
	li
	{
        &.print-menu-component.export-toggle {
          top: 0;
        }

		a, span
		{
			background-color:transparent;
			border-color:transparent;
			color:darken(@color-action, 10%);
			cursor:pointer;
			text-transform:uppercase;

			&:focus, &:active, &:hover
			{
				background-color:transparent;
				border-color:transparent;
			}

			&:hover {
				border-bottom: 1px solid @color-action;
				color:@color-action;
				transition: @gobal-transition;
			}

			&.active {
				border-bottom:1px solid @color-action;
				&::after {
					content: "";
					display: block;
					position: absolute;
					bottom: 0;
					left: 0;
					right: 0;
					height: 3px;
					background-color: @color-action;
				}
			}
		}

        span
        {
          position: relative;
          display: block;
          padding: 10px 15px;
        }
	}
}
