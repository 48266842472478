@import "_variables";

html
{
	line-height:24px;
	font-size:24px;
}
html, body
{
	margin:0;
	padding:0;
}
body
{
	font-family:@font-body;
	font-size:16px;
	color:@color-text;
	background-color:@body-bg;
}

label
{
	font-family:@font-labels;
	font-weight: normal;
}

input, textarea, select, option
{
	font-family:@font-values;
}

h1, h2, h3, h4, h5, h6
{
	font-family:@font-titles;
}

.btn:focus
{
	outline:none !important;
}

.btn-cta
{
	background-color:@color-action;
	color:@white;

	&:hover
	{
		color:@white;
		background-color:lighten(@color-action, 10%);
	}
}
