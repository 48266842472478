@import "_variables";
@import "_mixins";


.labor-codes-component {
    table tr.new-entry:nth-child(odd) {
        background-color: transparent;
    }
    table tr.new-entry:nth-child(even) {
        background-color: @white-pure;
    }
    table tr.new-entry td {
        padding: .3rem .5rem .5rem;
    }
    .checkbox-container {
        width:3%;
    }
    .code-id {
        width: 20%;
    }
    .code-name {
        width: 20%;
    }
    .code-title {
        width: 40%;
    }
    .buttons {
        width:17%;
        min-width:100px;
        text-align:right;
        .save {
            position: relative;
            top:6px;
        }
    }
    .attached {
        table {
            tr {
                td {
                    vertical-align: middle;
                    &.buttons {
                        width:auto;
                    }
                }
                &:hover td {
                    background-color: @white-pure;
                    transition: @gobal-transition;
                }
            }
        }
    }

    @media screen and (max-width:991px) {
        section.labor-codes {
            table.table.table-select-list tbody tr td {
                font-size:.5rem;
                .entry-label {
                    font-size:.4rem;
                }
    
            }
        }
    }
}
