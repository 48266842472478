@import "_mixins";

.purchase-order-component {
    .print-menu-component {
        .nav-tabs {
            a {
                padding:9px 15px 11px;
            }
        }
    }
}
.purchase-order-invoice-connection-component {
    .connection-section {
        .scrollable-container();
        .name {
            width:40%;
        }
        .connection-actions {
            text-align:right;
        }
    }
}

.purchase-order-costs-component {
    .hide-for-po {
        display:none;
    }
}
.purchase-order-receipts-component {
    .receipt-actions {
        padding-bottom: 1rem;

        &-date {
            float: left;
        }

        &-add {
            float: right;
        }
    }
}

.po-receipts-container {
	tbody::after {
		content: "";
		display: block;
		height: 2rem;
	}

	.caption {
		font-weight: bold;
		padding: 0.5rem;
		background-color: rgba(255, 255, 255, 0.8);
		border: 1px solid #ddd;
	}
}

table .quantity{ width:40px;}
table .unit{width:48px;}
table .recd{width:86px;}
table .location{width:100px;}
table .damaged{width:100px;}
table .buttons
{width:100px;}

.purchaseOrder-cost {
    tr {
        line-height: 1.5rem;
    }
}

//Work Order Attachment page
.wo-attachment-summary
{
	.date
	{
		display:block;
	}

	.number
	{
		font-weight:bold;
	}
}

.po-cost-list-container
{
	tbody
	{
		&::after
		{
			content:"";
			display:block;
		}
	}
}
.documents {
    padding:1rem;
}
input.form-control.combo-date {
    border-radius: 3px 0 0 3px;
    width: calc(~"100% - 35px");
}
.date-selector-overlay {
    input[type="date"],
    .month-selector {
        background-color: @seafoam;
        border:none;
        cursor: pointer;
        outline:none;
        position: absolute;
        right: 0;
        top: -34px;
        height:34px;
        width: 3rem;
        -webkit-appearance: button;
    }
    .month-selector {
        border: 1px solid @input-border;
        border-radius: 0 @global-border-radius @global-border-radius 0;
        width:1.5rem;
        -webkit-appearance: unset;
        i {
            background-color: transparent;
            right:14px;
            top:3px;
        }
    }
    select::-ms-expand {
        display:none;
    }
    i {
        background-color: @seafoam;
        line-height: 1.2rem;
        padding-left: 0.6rem;
        pointer-events: none;
        position: absolute;
        right: 15px;
        top: -31px;
        width: 1rem;
    }
}

.date-button {
    .btn.btn-outline.btn-warning.active {
        color: #fff;
        background-color: @alert-color;
        border-color: @alert-color;
    }
}
.mobile-button {
    display:none;
}
.desktop-button {
    display:block;
}
.purchase-order-component {
    .print-menu-component {
        top:0;
    }
    .print-menu-option {
        white-space: nowrap;
    }
}
.purchase-order-search-component {
    .batch-check {
        width:4%;
    }
    .po-col {
        width:7%;
    }
    .customer-col {
        width:20%;
    }
    .pm-col {
        width:15%;
    }
    .name-col {
        width:15%;
    }
    .supplier-col {
        width:15%;
    }
    .status-col {
        width:10%;
    }
    .date-col {
        width:10%;
    }
    .list-actions {
        width:8%;
        padding-right:0;
    }
    .is-admin {
        .customer-col {
            width:19%;
        }
        .pm-col {
            width:14%;
        }
        .name-col {
            width:14%;
        }
        .supplier-col {
            width:14%;
        }
    }
}

@media screen and (max-width:1299px) {
    .purchase-order-component {
        .nav-tabs {
            li {
                font-size:.55rem;
            }
        }

    }
}
@media screen and (max-width:1199px) {
    .mobile-button {
        display:block;
    }
    .desktop-button {
        display:none;
    }
    
}
@media screen and (min-width:991px) and (max-width:1024px) {
    .purchase-order-component {
        .nav-tabs {
            li {
                font-size:.4rem;
            }
        }

    }
}
@media screen and (max-width:991px) {
    .po-receipts-add,
    .po-receipts-container {
        table thead th {
            font-size:.4rem;
        }
    }
    .date-selector-overlay {
        input[type="date"] {
            top: -74px;
        }
    
        i {
            top: -71px;
        }
    }
    .date-selector-overlay {
        .month-selector {
            top: -54px;
        }
    }
    .purchase-order-search-component {
        .po-col {
            width:8%;
        }
        .customer-col {
            width:17%;
        }
        .pm-col {
            width:14%;
        }
        .name-col {
            width:14%;
        }
        .supplier-col {
            width:15%;
        }
        .status-col {
            width:12%;
        }
        .date-col {
            width:12%;
        }
        .list-actions {
            width:8%;
        }
    }
}