.purchase-order-payable-tab-component {
    .connection-section {
        .scrollable-container();
        .amount,
        .connection-actions {
            text-align:right;
        }
        .date,
        .number,
        .amount,
        .connection-actions {
            width:25%;
        }
    }
}

.payable-search-component {
    table {
        table-layout: fixed;
    }
    #infinite-scroll-list {
        th {
            white-space: normal;
            word-break: break-word;
        }
    }
    .check-col {
        width:6%;
    }
    .invoice-col {
        width:10%;
    }
    .job-col {
        width:14%;
    }
    .supplier-col {
        width:13%;
    }
    .status-col {
        width:10%;
    }
    .rec-col {
        width:10%;
    }
    .date-col {
        width:10%;
    }
    .posted-col {
        width:10%;
    }
    .amount-col {
        width:10%;
    }
    .list-actions {
        width:7%;
        padding-right:0;
    }
    .infinite-scroll-list {
        white-space: normal;
    }
    @media screen and (max-width:1199px){
        #infinite-scroll-list table th, .infinite-scroll-list table th, #infinite-scroll-list table td, .infinite-scroll-list table td {
            font-size:.5rem;
        }
    }
}