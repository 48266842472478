@color-invoice-pm:orange;
@color-invoice-accounting:magenta;
@color-invoice-customer:transparent;
@color-invoice-order:yellow;
@color-invoice-workorder:orange;
@color-invoice-tagging:green;

@color-po-supplier:transparent;
@color-po-accounting:yellow;
@color-po-shop:orange;
@color-po-job:transparent;

@color-wo-job:transparent;
@color-wo-field:orange;
@color-wo-control:transparent;
@color-wo-shop:green;

@import "_variables";

.print-button-component {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
}

.print-menu-component {
    position: absolute;
    right: 0;
    top: 36px;
    z-index: 10;

    .print-menu {
        .print-menu-option {
            padding: 0.25rem;
            white-space: nowrap;
            button {
                margin-right: 0.5rem;
            }
        }

        .print-menu-execute {
            text-align: center;

            button {
                width: 95%;
            }
        }
    }
    .nav-tabs {
        li a.dropdown-toggle {
            border-radius: 0;
        }
    }
}

.print-bold {
    font-weight: bold;
}

.print-table-row-spacer {
    height: 0.5rem;

    hr {
        border: 1px solid @black;
        margin: 0.15rem 0.6rem 0 0.1rem;
    }
}

.print-header-indicator {
    border-style: solid;
    border-width: 0.25rem 1rem;
    position: absolute;
    height: 0.5rem;
    width: 2rem;
}

table {
    thead.print-only,
    tr.print-only {
        line-height: 0;
    }
    tr.print-only {
        display:none;
    }

    &.two-column tr td {
        width: 50%;
    }

	&.three-column tr td {
		width: 33%;
	}

    &.grid {
        td,
        th {
            border: 1px solid @gray-light;
            padding: 0.2rem;
        }
    }
}
@media print {
    body {
        font-size: 12px;
    }
    @page :first {
        margin-top: .3625in;
    }
    @page {
        margin-top:.675in;
    }

    .print-controls,
    .print-hide,
    div#toast,
    div.sidebar,
    header,
    .sidebar-toggler,
    .anchors {
        display: none!important;
    }

    a[href]:after {
        content: none !important;
        // override link address printing
    }

    table {
        width: 100%;

        thead.print-only,
        tr.print-only {
            line-height: inherit !important;
        }
        tr.print-only {
            display:table-row;
        }
        // work-around for tbody not always able to page-break:
        // http://stackoverflow.com/questions/27753255/page-break-not-working-with-tbody-issue
        // tbody.print-page-break::after {
        //     content: '';
        //     display: block !important;
        //     page-break-after: always;
        //     page-break-inside: avoid;
        //     page-break-before: avoid;
        // }
        // another example solution: http://stackoverflow.com/questions/1763639/how-to-deal-with-page-breaks-when-printing-a-large-html-table
        table {
            page-break-inside: auto;
        }

        tr {
            page-break-inside: avoid;
            page-break-after: auto;
        }

        thead {
            display: table-header-group;
        }

        tfoot {
            display: table-footer-group;
        }

        td,
        th {
            vertical-align: top;
            font-size: 15px;
        }

		&.table-zebra tbody tr:nth-child(odd) td
		{
            -webkit-print-color-adjust:exact;
			background-color:#eee !important;
        }
        &.report-total {
            margin-top:1.75rem!important;
        }
    }
    .layout-sidebar.narrow {
        padding-left:0;
        .sidebar-main.sidebar-narrow-main {
            left:0!important;
        }
    }
    .report-window-component {
        display: block !important;

        .report-job-detail-component,
        .report-job-outcome-component,
        .report-job-summary-component {
            display: block !important;

            .screen-title {
                font-size: 18pt!important;
                margin: 0!important;
                text-align: center;
            }

            #reportPane {
                display: block !important;
                font-size: 0.4rem;
                position: relative;
                left:0;
                max-width:100%;
                flex-basis: 100%;

                table {
                    caption {
                        font-size: 14pt;
                    }
                    th {
                        font-size: 10pt;
                        padding:3px;
                    }
                    td {
                        font-size: 10pt;
                        padding:3px;
                    }
                }
            }
        }
        .report-job-outcome-component {
            padding-left:0;
            table {
                th, td {
                    font-size:9px!important;
                }
            }
        }
    }
    #reportPane,
    .jobList-print {
        margin:0;
        .screen-title {
            font-size: 18pt!important;
            text-align: center;
            margin: 0!important;
        }
        table {
            caption {
                font-size: 14pt;
            }
            th {
                font-size: 10pt;
                padding:3px;
            }
            td {
                font-size: 10pt;
                padding:3px;
            }
        }
    }

    hr {
        margin: 0.2rem;
    }

    .print {
        font-size: 0.6rem;
        min-height: 100%;
        page-break-after: always;
        position: relative;

        .print-header {
            font-size: 0.7rem;
            font-weight: bold;
            position: absolute;
            right: 0;
            top: 0;
        }
        &.invoice-print {
            .invoice-details tbody tr td,
            .invoice-footer-cost tr td,
            .invoice-footer-totals tr td {
                font-size: 10pt!important;
            }
            .print-header {
                left: 0;
                max-width: 8rem;
                top: 0;
            }
    
            .invoice-indicator {
                left: 0;
    
                &-projectManager {
                    border-color: @color-invoice-pm;
                }
    
                &-order {
                    border-color: @color-invoice-order;
                }
    
                &-accounting {
                    border-color: @color-invoice-accounting;
                }
    
                &-customer {
                    border-color: @color-invoice-customer;
                }
    
                &-field {
                    border-color: @color-invoice-workorder;
                }
    
                &-tagging {
                    border-color: @color-invoice-tagging;
                }
            }
    
            .invoice-header {
                tr {
                    margin-top: 1rem;
                }
    
                .logo {
                    display:inline-block;
                    width:auto;
                    position: relative;
                    top:-22px;
                    img {
                        max-width: 200px;
                    }
                }
                .invoice-remit {
                    display:block;
                    font-size:1em;
                    font-weight:bold;
                    margin-top:-5px;
                }
                .print-header {
                    display:block;
                    position: relative;
                    text-align: right;
                    margin:0 0 .125in 0;
                    width:auto;
                    max-width: none;
                    .print-header-indicator {
                        display:block;
                        position: relative;
                        margin-top:3px;
                        float: right;
                    }
                }
            }
    
            .invoice-meta {
                float:right;
                .two-column {
                    max-width:2in;
                    text-align: left;
                    float:right;
                    white-space: nowrap;
                    td {
                        padding:3px;
                    }
                }
            }
    
    
            .invoice-meta td + td {
                border:1px solid #999;
            }
    
            .invoice-location {
                display:inline-block;
                font-size:.75em; 
                padding-left:.25in;
            }
    
            .invoice-info {
                td,
                th {
                    font-size: 0.5rem;
                    text-align: center;
                    width: 16%;
                    border:1px solid #999;
                }
            }
            .top-align {
                display:flex;
                flex-direction: row;
                align-items: baseline;
            }
            .invoice-flag {   
                .glyphicon {
                    margin-right:3px;
                }
            }
    
            .invoice-notes {
                legend {
                    margin-bottom: 0;
                }
            }
            .invoiceNotes-banner {
                border: 1px solid @black;
                padding: 0.1rem 0;
                text-align: center;
            }
    
            .invoice-address,
            .invoice-details,
            .invoice-footer,
            .invoice-info,
            .invoice-notes {
                margin-top: 1rem;
            }
    
            .invoice-footer2 {
                position: fixed; 
                bottom: 0; 
                margin-bottom: 3em;
            }
    
            &.Customer,
            &.invoice-print {
                .invoice-address-customer {
                    height:9em;
                    font-size:0.8em;
                    #customer-address {
                        position: absolute;
                        top: 1.8in;
                        left: .75in;
                    }
                }
    
                .invoice-address-delivery {
                    font-size:0.8em;
                    #delivery-address {
                        position: absolute;
                        left: 4.5in;
                        top: 1.8in;
                    }
                }
            }
    
            .invoice-footer {
                &-disclaimer {
                    font-size: 0.4rem;
                    padding-right: 1rem;
                }
            }
    
            .invoice-total {
                background-color: #000;
                color: #fff;
            }
        }

    }

    .purchaseOrder-print {
        margin-top:.3125in;
    }
    .purchaseOrder-print,
    .exported-po {
        .purchaseOrder-indicator {
            right: 0;

            &-job {
                border-color: @color-po-job;
            }

            &-shop {
                border-color: @color-po-shop;
            }

            &-accounting {
                border-color: @color-po-accounting;
            }

            &-supplier {
                border-color: @color-po-supplier;
            }
        }

        .purchaseOrder-info {
            text-align: center;

            .date {
                text-align: left;
            }

            .location {
                &-name {
                    font-size: 0.8rem;
                }
            }

            .purchaseOrder-number {
                font-size: 0.4rem;
                padding-top: 2rem;

                &-box {
                    border: 1px solid @black;
                    padding: 0.5rem;
                }

                &-number {
                    font-size: 0.6rem;
                }
            }
        }

        .shipping-misc {
            td {
                border: 1px solid #666;
                padding: 0.2rem;
            }
        }

        .purchaseOrder-parts {
            .purchaseOrder-banner {
                border: 1px solid @black;
                padding: 0.1rem 0;
                text-align: center;
            }

            table {
                thead {
                    margin: 0.5rem 0;

                    th.quantity {
                        width: 3rem;
                    }

                    th.unit {
                        width: 3rem;
                    }

                    th.cost {
                        width: 5rem;
                    }
                }

                tbody {
                    tr {
                        margin: 0.25rem 0;
                    }
                }
            }
        }

        .purchaseOrder-notes,
        .purchaseOrder-parts,
        .shipping-misc {
            margin-top: 1rem;
        }
    }

    .workOrder-print {
        margin-top:.3125in;
        .workOrder-indicator {
            right: 0;

            &-job {
                border-color: @color-wo-job;
            }

            &-shop {
                border-color: @color-wo-shop;
            }

            &-field {
                border-color: @color-wo-field;
            }

            &-control {
                border-color: @color-wo-control;
            }
        }

        .workOrder-info {
            .projectManager {
                width: 30%;
            }

            .customer {
                width: 45%;
            }

            .workOrder-number {
                width: 20%;
            }
        }

        .job-info,
        .purchaseOrder-info,
        .workOrder-notes,
        .workOrder-otherParts,
        .workOrder-pmNotes {
            margin-top: 0.5rem;
        }

        .workOrder-pmNotes {
            &-title {
                border: 2px solid @black;
                font-weight: bold;
                margin-bottom: 0.25rem;
                padding: 0.25rem;
                text-align: center;
            }

            &-description {
                margin-bottom: 0.5rem;
            }
        }

        .purchaseOrder-info {
            .purchaseOrder-summary {
                font-size: 0.8rem;
                font-weight: bold;
            }

            .purchaseOrder-notes {
                clear: both;
            }
        }

        .purchaseOrder-name {
            border: 2px solid @black;
            font-weight: bold;
            padding: 0.25rem;
            text-align: center;
        }

        .workOrder-otherParts {
            &-header {
                font-size: 0.7rem;
                font-weight: bold;
            }

            .purchaseOrder-parts {
                th.part-quantity {
                    width: 80px;
                }

                th.part-unit {
                    width: 120px;
                }
            }
        }
    }



    .jobList-print {
        .jobList-header {
            font-size: 1.2rem;
            text-align: center;
        }

        .jobList-flag {
            font-size: 0.6rem;
            font-weight: bold;
        }
    }
}
@media screen {
    table {
        width: 100%;

        td,
        th {
            vertical-align: top;
        }
    }

    .print-controls {
        background-color: @header-bg;
        height: @header-height;
        padding: 0.4rem;
        position: absolute;
        right: 0;
        text-align: right;
        top: 0;
        width: 70%;
        z-index: 1000;
    }

    .print {
        background-color: #fff;
        border: 1px solid @black;
        font-size: 0.6rem !important;
        height: 11in;
        left: 50%;
        margin: 0.25in;
        padding: 0.25in;
        position: relative;
        transform: translateX(-50%);
        width: 8.5in;

        .print-header {
            font-size: 0.7rem;
            font-weight: bold;
            margin: 0.25in;
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    .workOrder-print {
        .workOrder-indicator {
            right: 0;

            &-job {
                border-color: @color-wo-job;
            }

            &-shop {
                border-color: @color-wo-shop;
            }

            &-field {
                border-color: @color-wo-field;
            }

            &-control {
                border-color: @color-wo-control;
            }
        }

        .workOrder-info {
            .projectManager {
                width: 30%;
            }

            .customer {
                width: 50%;
            }

            .workOrder-number {
                width: 19%;
            }
        }

        .job-info,
        .purchaseOrder-info,
        .workOrder-notes,
        .workOrder-otherParts,
        .workOrder-pmNotes {
            margin-top: 1rem;
        }

        .workOrder-pmNotes {
            &-title {
                border: 2px solid @black;
                font-weight: bold;
                margin-bottom: 0.25rem;
                padding: 0.25rem;
                text-align: center;
            }

            &-description {
                margin-bottom: 0.5rem;
            }
        }

        .purchaseOrder-info {
            .purchaseOrder-summary {
                font-size: 0.8rem;
                font-weight: bold;

                &-basic {
                    float: left;
                }
            }

            .purchaseOrder-notes {
                clear: both;
            }
        }

        .purchaseOrder-name {
            border: 2px solid @black;
            font-weight: bold;
            padding: 0.25rem;
            text-align: center;
        }

        .workOrder-otherParts {
            &-header {
                font-size: 0.7rem;
                font-weight: bold;
            }

            .purchaseOrder-parts {
                th.part-quantity {
                    width: 80px;
                }

                th.part-unit {
                    width: 120px;
                }
            }
        }
    }

    .purchaseOrder-print {
        .purchaseOrder-indicator {
            right: 0;

            &-job {
                border-color: @color-po-job;
            }

            &-shop {
                border-color: @color-po-shop;
            }

            &-accounting {
                border-color: @color-po-accounting;
            }

            &-supplier {
                border-color: @color-po-supplier;
            }
        }
    }

    .invoice-print {
        .print-header {
            left: 0;
            max-width: 8rem;
            top: 0;
        }

        .invoice-indicator {
            left: 0;

            &-projectManager {
                border-color: @color-invoice-pm;
            }

            &-order {
                border-color: @color-invoice-order;
            }

            &-accounting {
                border-color: @color-invoice-accounting;
            }

            &-customer {
                border-color: @color-invoice-customer;
            }

            &-field {
                border-color: @color-invoice-workorder;
            }

            &-tagging {
                border-color: @color-invoice-tagging;
            }
        }

        .invoice-header {
            tr {
                margin-top: 1rem;
            }

            .logo {
                display: inline-block;
                text-align: center;
                position: relative;
                top:-28px;
                img {
                    max-width: 200px;
                }
            }
        }

        .invoice-meta {
            width:100%;
            .print-header {
                display:block;
                position: relative;
                text-align: right;
                margin:0 0 .125in 0;
                width:auto;
                max-width: none;
                .print-header-indicator {
                    display:block;
                    position: relative;
                    margin-top:3px;
                    float: right;
                }
            }
            .two-column {
                float:right;
            }
        }
        .invoice-remit {
            display:block;
            font-size:1em;
            font-weight:bold;
            margin-top:-5px;
        }

        .invoice-location {
            display: inline-block;
            font-size: 12px;
            padding-left:20px;
            position: relative;
            margin-top:50px;
        }

        .invoice-info {
            td,
            th {
                font-size: 0.5rem;
                text-align: center;
                width: 16%;
            }
        }

        .invoice-notes {
            legend {
                margin-bottom: 0;
            }
        }

        .invoice-address {
            margin-top: 1.25rem;
        }
        .invoice-details,
        .invoice-footer,
        .invoice-info,
        .invoice-notes {
            margin-top: 1rem;
        }

        .invoice-footer {
            &-disclaimer {
                font-size: 0.4rem;
                padding-right: 1rem;
            }
        }

        .invoice-total {
            background-color: #000;
            color: #fff;
        }
    }

    .jobList-print {
        .jobList-header {
            font-size: 1.2rem;
            text-align: center;
        }

        .jobList-flag {
            font-size: 0.6rem;
            font-weight: bold;
        }
    }
}
