@import "_variables";

td.copy {
    button {
        margin: -5px 0 0 0;
    }
}

.invoice-flag {
    display: inline-block;
    width: auto;
    margin-right:.5rem;
    &:last-child {
        margin-right:0;
    }
}

.invoice-amount {
    width: 6rem;
}

.tax-rate {
    float: left;
    width: 4rem;
}

.invoice-edit {
    label {
        margin-top:5px;
    }
}
ul.dropdown-menu.openDropdown {
    display:block;
    li {
        padding: 10px 5px;
        cursor: pointer;
        &:hover {
            background-color:@button-primary-color;
            color:@white-pure;
            transition: @gobal-transition;
        }
    }
}
.invoice-edit-component {
    form {
        table th {
            padding:8px;
        }
    }
}

.receivable-amounts {
    float:right;
    table tr td:first-child {
        text-align:right;
    }
}
.invoice-search-component {
    .check-col {
        width:6%;
    }
    .invoice-col {
        width:13%;
    }
    .date-col {
        width:10%;
    }
    .customer-col {
        width:18%;
    }
    .job-col {
        width:16%;
    }
    .job-col-lg {
        width:21%;
    }
    .status-col {
        width:12%;
    }
    .amount-col {
        width:15%;
    }
    .list-actions {
        width:10%;
        padding-right:0;
    }
}
@media screen and (max-width:767px){
    .receivable-amounts {
        float:left;
        table tr td:first-child {
            text-align:left;
        }
    }
}
