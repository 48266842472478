@import "_variables";

//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
// Additional sidebar layout styles in _layout.less
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

.sidebar-colorize(@color:@gray) {
	.sidebar-tabs-main .nav-tabs li a {
		&:hover::after {
			background-color:@color;
			transition: @gobal-transition;
		}

		&.active {
			color: @white;
			background-color: @color;
			&:after {
				background-color: transparent;
			}
		}
	}

	.table-select-list {
		tr.active {
			&::after {
				border-left-color:@color !important;
			}
			td {
				background-color: @color;
			}
		}
	}
}

.sidebar {
	background-color:@aqua-deep;
	color:@white;
	.sidebar-sidetab-main {
		flex-direction: column;
	}

	a {
		color:@white;
	}

	.sidebar-sidetab-tabs {
		display:block;
		background-color:darken(@sidebar-header-bg, 10);

		.nav-tabs
		{
			border-bottom:0;
			width:100%;
			text-align: center;
			li {
				display:flex;
				width:16%;
				a {
					border:none;
					border-radius: 0;
					background-color:transparent;
					color:@seafoam;
					margin-right:0;
					padding: 7px;
					&.active
					{
						background-color:@sidebar-header-bg;
						color:@white;
					}
					&:hover {
						color:@white-pure;
						transition: @gobal-transition;
					}
				}
			}
		}
	}

	.sidebar-sidetab-main
	{
		flex:1 0 0;
		display:flex;
		flex-direction:column;
	}

	.sidebar-tabs
	{
		position:relative;
	}

	.sidebar-tabs-main .nav-tabs {
		background-color: @sidebar-header-bg;
		padding:0;
		border-bottom:1px solid @white;
		width:100%;
		display:flex;
		flex-wrap: wrap;

		li {
			display:flex;
			flex-flow: column;
			margin-bottom:0;
			// width:50%;
			// @media screen and (min-width:1790px) {
			// 	width:auto;
			// }
			// @media screen and (max-width:991px) {
			// 	width:100%;
			// }
			a {
				background-color:lighten(@sidebar-header-bg, 5%);
				margin:0 1px 0 0;
				color:darken(@white-pure, 5%);
				border-radius: 0;
				display:flex;
				height:100%;
				border-top:1px solid @aqua-deep;
				@media screen and (max-width:991px) { 
					font-size:0.6rem;
				}
				&:hover {
					color: @white;
					border-bottom-color:@nav-color-light;
					background-color:lighten(@sidebar-header-bg, 7%);
					transition: @gobal-transition;
				}

				&.active {
					border-bottom-color:@nav-color-light;
					background-color:transparent;
					&:after
					{
						background-color:@white;
					}
				}
			}
		}
	}

	.sidebar-tabs-areas
	{
		display:block;
		position: relative;
		width:100%;
		background-color:transparent;
		top:0;
		bottom:0;
		left:0;

		.nav-tabs
		{
			background-color:transparent;
		}

		a {
			color: @color-text-inverted;
			&.active {
				color:@seafoam;
				background-color:@white;
				border-bottom-color: @white;
				&:after
				{
					background-color: transparent;
				}
			}
		}
	}
	//sidebar list header for search and filtering
	header
	{
		padding:1rem .5rem;
	}

	.WorkOrders {
		.sidebar-colorize(@color-workorder);
	}
	.PurchaseOrders
	{
		.sidebar-colorize(@color-purchaseorder);
	}
	.ChangeOrders
	{
		.sidebar-colorize(@color-changeorder);
	}
	.Invoices
	{
		.sidebar-colorize(@color-invoice);
	}
	.Payables
	{
		.sidebar-colorize(@color-payable);
	}
	.job-work-order-list-component,
	.job-purchase-order-list-component,
	.job-change-order-list-component,
	.job-invoice-list-component,
	.job-payable-list-component {
		background-color:@gray;
		position:absolute;
		top:0;
		width:100%;
	}

}

