@import "_variables";

.account-info-component, 
.site-area-component {
	margin:0 auto;

	a:hover, a:active, a:focus
	{
		text-decoration:none;
	}

	ul.dropdown-menu
	{
		background-color:@header-bg;

		li
		{
			text-align:center;

			a:hover
			{
				background-color:lighten(@header-bg, 10);
				transition: @gobal-transition;
			}
		}
	}
}

.center-pills
{
	display:flex;
	justify-content: center;
}

.site-area-component {
	display:block;
	margin:-10px auto;
	opacity:0.2;
	.navbar.nav {
		transition:opacity 1s ease;
	}
	&:hover {
		opacity:1;
		transition:opacity 0.2s linear;
	}

	a span
	{
		display:none;
		font-size: 0.5rem;
		line-height: 0.5rem;
		margin: -0.25rem 0;
		text-align:center;
	}
	nav {
		&.navbar-default {
			background-color: transparent;
			border: none;
			margin-bottom:0;
			button.navbar-toggle {
				border:none;
				margin-right:0;
				&:hover {
					background-color:transparent;
				}
				.icon-bar {
					background-color:#fff;
				}
			}
			.collapse.navbar-collapse {
				padding:0;
				&.menu-open {
					display:block;
				}
			}
			.navbar-nav {
				margin:0 100px 0 auto;
				@media screen and (max-width:1199px) {
					margin:0 30px 0 auto;
				}
				@media screen and (max-width:991px) {
					margin:0 0 0 auto;
				}
				li {
					display:inline-block;
					float:none;
					a {
						font-size:1rem;
						-webkit-border-radius:0;
						-moz-border-radius:0;
						border-radius:0;
						color:@color-text-inverted;
						padding:10px 15px;
						opacity:0.8;
				
						&:hover
						{
							background-color:lighten(@header-bg,7.5);
				
				
						}
				
						&.active
						{
							opacity:1;
							background-color:lighten(@header-bg,10);
						}
					}
				}
			}
		}
	}
}
@media screen and (max-width:767px) {
	.site-area-component {
		.navbar-header .navbar-toggle {
			margin: 10px 20px 0 0;	
		}
		nav.navbar-default .navbar-nav li a {
			padding:15px 24px;
		}
	}
	body header#header .container .flex-container .nav-container .collapse.navbar-collapse.menu-open .account-container {
		padding-bottom: 1rem;
	}
}