@import 'https://fonts.googleapis.com/css?family=Noto+Sans|Noto+Serif';
html {
  line-height: 24px;
  font-size: 24px;
}
html,
body {
  margin: 0;
  padding: 0;
}
body {
  font-family: 'Noto Sans', Tahoma, sans-serif;
  font-size: 16px;
  color: #0A1419;
  background-color: #F6F6FF;
}
label {
  font-family: 'Noto Sans', 'Helvetica', Arial, sans-serif;
  font-weight: normal;
}
input,
textarea,
select,
option {
  font-family: 'Helvetica', Tahoma, sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Noto Serif', serif;
}
.btn:focus {
  outline: none !important;
}
.btn-cta {
  background-color: #91AA9D;
  color: #F6F6FF;
}
.btn-cta:hover {
  color: #F6F6FF;
  background-color: #aec0b7;
}
body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
body a:hover {
  transition: all 0.25s ease-in-out;
}
.my-app-component {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.my-app-component .logo-container a.disabled {
  pointer-events: none;
}
header#header {
  display: flex;
  justify-content: space-evenly;
  background-color: #193441;
  color: #F6F6FF;
  flex: 0 0 2.3rem;
  padding: 0.5rem;
  z-index: 100;
  position: fixed;
  width: 100%;
  height: 52px;
}
header#header a {
  color: #F6F6FF;
  line-height: 30px;
  /* logo height */
}
header#header .flex-container {
  display: flex;
  justify-content: space-between;
}
header#header .flex-container .nav-container {
  text-align: center;
}
/* scrolling and sticky header */
main {
  display: flex;
  flex-direction: column;
  flex: 1;
  flex: 1 1 0;
  position: relative;
}
main .labor-codes-component,
main .job-costs-component,
main .job-time-entry-component {
  height: 100%;
  overflow: hidden;
  padding: 1rem 0;
}
.job-list-component,
.job-component,
.customer-component,
.customer-list-component,
.work-order-search-component,
.purchase-order-search-component,
.invoice-search-component,
.payable-search-component,
.report-component,
.admin-component,
.supplier-list-component,
.customer-add-component,
.profile-edit-form-component,
.dashboard {
  margin-top: 52px;
}
.job-list-component div.container.fixed,
.customer-list-component div.container.fixed,
.work-order-search-component div.container.fixed,
.purchase-order-search-component div.container.fixed,
.invoice-search-component div.container.fixed,
.payable-search-component div.container.fixed,
.supplier-list-component div.container.fixed {
  z-index: 99;
  background-color: #F6F6FF;
  left: 50%;
  margin-left: calc(-1170px / 2);
}
.job-list-component .customer-list-container,
.customer-list-component .customer-list-container,
.work-order-search-component .customer-list-container,
.purchase-order-search-component .customer-list-container,
.invoice-search-component .customer-list-container,
.payable-search-component .customer-list-container,
.supplier-list-component .customer-list-container {
  margin-top: 178px;
}
.job-list-component .list-container,
.customer-list-component .list-container,
.work-order-search-component .list-container,
.purchase-order-search-component .list-container,
.invoice-search-component .list-container,
.payable-search-component .list-container,
.supplier-list-component .list-container {
  margin-top: 219px;
}
.payable-search-component .list-container {
  margin-top: 155px;
}
.customer-list-component table > tbody > tr > td,
.supplier-list-component table > tbody > tr > td {
  vertical-align: middle;
}
.supplier-list-component .filter-list-component .filters .col-xs-12 {
  width: 100%!important;
}
@media screen and (max-width: 991px) {
  .supplier-list-component .filter-row {
    align-items: center;
  }
}
.admin-component {
  display: flex;
  flex: 1;
}
.location-component {
  display: block;
  width: 100%;
  text-align: center;
}
.location-component .container {
  text-align: left;
}
#scroll-container {
  height: 100%;
  overflow-y: scroll;
  width: 100%;
}
#list-container {
  height: 100%;
  width: 100%;
}
.sticky-header {
  background-color: #F6F6FF;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;
}
/*---------------------------*/
.layout-sidebar {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-direction: row;
}
.layout-sidebar .sidebar-toggler {
  background-color: #193441;
  border: none;
  bottom: 0;
  box-shadow: none;
  color: #fff;
  position: fixed;
  top: 52px;
  min-width: 40px;
  height: 100vh;
  z-index: 90;
  left: 0;
  transition: all 0.5s ease-in-out;
}
.layout-sidebar .sidebar-toggler:focus,
.layout-sidebar .sidebar-toggler:active {
  outline: none;
}
.layout-sidebar.narrow {
  padding-left: 40px;
}
.layout-sidebar.narrow .sidebar.sidebar-narrow {
  background-color: #3E606F;
  display: inline-block;
  width: 20%;
  position: relative;
  left: 0;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
  flex-basis: auto;
}
.layout-sidebar.narrow .sidebar-narrow-main {
  display: inline-block;
  left: 0;
  width: 80%;
  transition: all 0.5s ease-in-out;
  flex-basis: auto;
}
.layout-sidebar.narrow.hidden-sidebar .sidebar.sidebar-narrow {
  left: -30%;
  transition: all 0.5s ease-in-out;
}
.layout-sidebar.narrow.hidden-sidebar .sidebar-narrow-main {
  width: 100%;
  left: -20%;
  transition: all 0.5s ease-in-out;
}
.sidebar {
  display: flex;
  flex-direction: column;
  flex: 1;
  flex: 0 0 30%;
  /* override default flex options */
}
.sidebar.sidebar-wide {
  flex-basis: 30%;
}
.sidebar .padding-collapse {
  margin: 0 -1rem;
}
.sidebar .btn {
  white-space: normal;
}
.sidebar-main {
  flex-basis: 70%;
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
}
#content {
  padding: 1rem;
}
article {
  padding: 1rem 0;
}
/* loading screen before angular kicks in */
#loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  font-weight: bold;
  color: #ddddff;
  text-shadow: 1px 1px 1px #ffffff;
}
#loading.data-loading {
  top: 100%;
}
.screen-title {
  font-family: 'Noto Sans', Tahoma, sans-serif;
  font-size: 1.2rem;
  margin-bottom: 1rem;
  position: relative;
}
.screen-subtitle {
  font-family: 'Noto Sans', Tahoma, sans-serif;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  position: relative;
}
.currency-display {
  padding-right: 0.5rem;
  text-align: right;
}
.no-wrap {
  white-space: nowrap;
}
/*----------------------------
the following are unused container nodes output by angular2 component router
They need to be styled in order to allow the flex layout to work properly
*/
.component {
  display: block;
  width: 100%;
}
.customer-component,
.supplier-component,
.customer-list-component,
.supplier-list-component,
.job-component,
.job-list-component,
.report-component,
.report-window-component,
.time-entry-component,
.job-search-component,
.time-entry-list.component,
.costs-list-component {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.work-order-component,
.purchase-order-component,
.invoice-component {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 -1rem -1rem -1rem;
  position: relative;
}
.work-order-component nav,
.purchase-order-component nav,
.invoice-component nav {
  flex: 0 0 20px;
}
.timeout-component {
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
}
.job-edit-component,
.job-change-order-add-component,
.job-change-order-edit-component,
.job-invoice-add-component,
.job-invoice-edit-component,
.job-payable-add-component,
.job-payable-edit-component,
.job-warranty-component,
.work-order-add-component,
.purchase-order-add-component {
  flex: 1;
  overflow: auto;
  display: block;
  margin: 0 -1rem -1rem -1rem;
  padding: 1rem 1rem 4rem;
}
.labor-codes-component,
.job-time-entry-component,
.job-costs-component {
  margin: 0 -1rem -1rem -1rem;
}
.labor-codes-component,
.job-time-entry-component,
.job-costs-component,
.work-order-edit-component,
.work-order-connection-component,
.work-order-pm-notes-component,
.work-order-parts-component,
.purchase-order-edit-component,
.purchase-order-connection-component,
.purchase-order-invoice-connection-component,
.purchase-order-payable-tab-component,
.purchase-order-parts-component,
.purchase-order-costs-component,
.purchase-order-receipts-component,
.invoice-edit-component,
.invoice-connection-component,
.report-job-list-component,
.report-journal-cost-component,
.report-time-entry-component,
.report-job-outcome-component,
.report-monthly-costs-component,
.report-job-detail-component,
.report-job-summary-component,
.report-invoice-summary-component {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 1rem;
}
.large-text {
  font-size: 2rem;
  font-weight: bold;
}
.large-text.light {
  color: #ddddff;
  text-shadow: 1px 1px 1px #ffffff;
}
.sortable-header-component .glyphicon.glyphicon-chevron-down {
  font-size: 0.65rem;
}
.entry-controls {
  margin-bottom: 0.5rem;
  position: relative;
  min-height: 1rem;
}
.entry-controls .entry-control {
  float: left;
  width: 100%;
}
.entry-controls .entry-control .entry-control-tab {
  position: absolute;
  top: 0;
}
.entry-controls .entry-control .entry-control-tab:nth-child(2) {
  left: 200px;
}
.entry-controls .entry-control .entry-control-content {
  position: relative;
  left: 0;
  top: 24px;
  right: 0;
  margin-bottom: 1rem;
}
.fixed {
  position: fixed;
}
/* bootstrap overrides
 @media screen and (-webkit-min-device-pixel-ratio: 0){
 	input[type=date].form-control, input[type=time].form-control, input[type=datetime-local].form-control, input[type=month].form-control {
 		line-height: 1;
 	}
 }
*/
@media (min-width: 768px) {
  .form-horizontal .control-label {
    padding-top: 0;
    margin-bottom: 4px;
  }
}
@media screen and (max-width: 1199px) {
  .job-list-component div.container.fixed,
  .customer-list-component div.container.fixed,
  .work-order-search-component div.container.fixed,
  .purchase-order-search-component div.container.fixed,
  .invoice-search-component div.container.fixed,
  .payable-search-component div.container.fixed,
  .supplier-list-component div.container.fixed {
    margin-left: calc(-970px / 2);
  }
  .invoice-search-component .list-container,
  .work-order-search-component .list-container,
  .purchase-order-search-component .list-container {
    margin-top: 292px;
  }
  .sidebar {
    flex-basis: 40%;
  }
  .sidebar-main {
    flex-basis: 60%;
  }
}
.align-center {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 991px) {
  h2 {
    font-size: 1rem;
  }
  .job-list-component div.container.fixed,
  .customer-list-component div.container.fixed,
  .work-order-search-component div.container.fixed,
  .purchase-order-search-component div.container.fixed,
  .invoice-search-component div.container.fixed,
  .payable-search-component div.container.fixed,
  .supplier-list-component div.container.fixed {
    margin-left: calc(-750px / 2);
  }
  .job-list-component .customer-list-container,
  .customer-list-component .customer-list-container,
  .work-order-search-component .customer-list-container,
  .purchase-order-search-component .customer-list-container,
  .invoice-search-component .customer-list-container,
  .payable-search-component .customer-list-container,
  .supplier-list-component .customer-list-container,
  .customer-list-component .job-list-container,
  .work-order-search-component .list-container,
  .purchase-order-search-component .list-container,
  .invoice-search-component .list-container,
  .payable-search-component .list-container,
  .supplier-list-component .job-list-container {
    margin-top: 162px;
  }
  .customer-list-component .wo-list-container,
  .supplier-list-component .wo-list-container {
    margin-top: 128px;
  }
  .customer-list-component .wo-list-container {
    margin-top: 128px;
  }
  .work-order-search-component .list-container,
  .purchase-order-search-component .list-container,
  .invoice-search-component .list-container {
    margin-top: 275px;
  }
  .job-list-component .list-container {
    margin-top: 202px;
  }
  .job-list-component .supplier-list-container,
  .customer-list-component .supplier-list-container,
  .work-order-search-component .supplier-list-container,
  .purchase-order-search-component .supplier-list-container,
  .invoice-search-component .supplier-list-container,
  .payable-search-component .supplier-list-container,
  .supplier-list-component .supplier-list-container {
    margin-top: 145px;
  }
  .table-select-list th,
  .sortable-header-component div,
  .infinite-scroll-header-component.sortable div {
    font-size: 0.5rem;
  }
  .entry-controls .entry-control .entry-control-content input {
    margin-bottom: 0.5rem;
  }
  .add-supplier-component fieldset {
    padding-right: 0!important;
  }
  .sidebar.sidebar-wide {
    flex-basis: 35%;
  }
  .sidebar-main.sidebar-wide-main {
    flex-basis: 65%;
  }
  .layout-sidebar.narrow .sidebar.sidebar-narrow {
    width: 30%;
  }
  .layout-sidebar.narrow .sidebar-narrow-main {
    width: 70%;
    min-width: 70%;
  }
  .layout-sidebar.narrow.hidden-sidebar .sidebar.sidebar-narrow {
    left: -40%;
  }
  .layout-sidebar.narrow.hidden-sidebar .sidebar-narrow-main {
    left: -30%;
    min-width: 100%;
  }
}
#document-uploader .my-drop-zone,
#file-uploader .my-drop-zone {
  border: dashed 2px #C0C0C0;
  color: #777;
  margin: 0;
  min-height: 90px;
  padding: 32px;
  text-align: center;
}
#document-uploader .my-drop-zone label,
#file-uploader .my-drop-zone label {
  font-weight: normal;
  cursor: pointer;
  margin: 0;
}
#document-uploader .my-drop-zone.nv-file-over,
#file-uploader .my-drop-zone.nv-file-over {
  border-color: #08910B;
}
#document-uploader .my-drop-zone.another-file-over-class,
#file-uploader .my-drop-zone.another-file-over-class {
  border-color: #08910B;
}
#document-uploader.dnd-hover .my-drop-zone,
#file-uploader.dnd-hover .my-drop-zone {
  border-color: #08910B;
}
.document-upload-component li .afs,
.gu-unselectable li .afs,
.document-upload-component li.gu-transit .afs,
.gu-unselectable li.gu-transit .afs {
  font-style: italic;
  margin-left: 5px;
}
.document-upload-component li .glyphicon-lock,
.gu-unselectable li .glyphicon-lock,
.document-upload-component li.gu-transit .glyphicon-lock,
.gu-unselectable li.gu-transit .glyphicon-lock {
  color: #777;
  padding: 9px 11px;
}
.timestamp {
  font-size: 0.5rem;
  text-transform: uppercase;
  color: #777;
}
.custom-label.supplier-status.preferred {
  color: #8dbb8d;
}
.custom-label.supplier-status.neutral {
  color: #e89c30;
}
.custom-label.supplier-status.onwatch {
  color: #6daaec;
}
.supplier-status-container {
  padding-right: 0!important;
}
.supplier-status-container .supplier-status-icon {
  border-radius: 10px;
  display: inline-block;
  height: 10px;
  width: 10px;
}
.supplier-status-container .supplier-status-icon.preferred {
  background-color: #8dbb8d;
}
.supplier-status-container .supplier-status-icon.neutral {
  background-color: #e89c30;
}
.supplier-status-container .supplier-status-icon.onwatch {
  background-color: #6daaec;
}
.supplier-status-container.color-key {
  font-size: 14px;
  margin-bottom: 0.5rem;
}
.supplier-status-container.color-key .supplier-status-icon {
  margin-right: 8px;
}
.supplier-status.typeahead.preferred,
#supplier-list tr.preferred {
  background-color: rgba(141, 187, 141, 0.3);
}
.supplier-status.typeahead.neutral,
#supplier-list tr.neutral {
  background-color: rgba(232, 156, 48, 0.3);
}
.supplier-status.typeahead.onwatch,
#supplier-list tr.onwatch {
  background-color: rgba(109, 171, 236, 0.3);
}
@media (min-width: 768px) and (max-width: 991px) {
  header .account-container {
    font-size: 0.5rem;
  }
  header .site-area-component nav.navbar-default .navbar-nav li a {
    padding: 10px 8px;
  }
  body header#header .container .logo-container img {
    width: 64px;
  }
  .layout-sidebar.narrow.hidden-sidebar .sidebar-narrow-main {
    left: -30%;
    min-width: 80%;
  }
}
@media screen and (max-width: 767px) {
  body header#header {
    justify-content: space-evenly;
    padding: 0;
    position: relative;
  }
  body header#header .container {
    padding: 0;
    margin: 0;
    width: 100vw;
  }
  body header#header .container .logo-container {
    position: absolute;
    left: 0.5rem;
    z-index: 2;
    padding: 0.5rem 0;
  }
  body header#header .container .flex-container {
    background-color: #193441;
    width: 100vw;
    z-index: 1;
  }
  body header#header .container .flex-container .nav-container {
    padding: 0.5rem 0 0.5rem 0;
    width: 100%;
  }
  body header#header .container .flex-container .nav-container .collapse.navbar-collapse.menu-open {
    border: none;
    box-shadow: none;
  }
  body header#header .container .flex-container .nav-container .collapse.navbar-collapse.menu-open .account-container {
    width: 100%;
    max-width: none;
  }
  body header#header .container .flex-container .nav-container .collapse.navbar-collapse.menu-open .account-container #location {
    float: left;
    padding-left: 0.75rem;
    top: 0;
  }
  body header#header .container .flex-container .nav-container .collapse.navbar-collapse.menu-open .account-container #account-actions {
    float: right;
    padding-right: 0.75rem;
    top: 0;
  }
  body header#header .container .flex-container .nav-container .collapse.navbar-collapse.menu-open .account-container ul.dropdown-menu {
    margin-top: -1px;
  }
  body .btn-group-lg > .btn,
  body .btn-lg,
  body #searchField {
    font-size: 0.65rem;
  }
  body .screen-title {
    font-size: 1rem;
  }
  body .job-list-component,
  body .job-component,
  body .customer-list-component,
  body .work-order-search-component,
  body .purchase-order-search-component,
  body .invoice-search-component,
  body .payable-search-component,
  body .report-component,
  body .admin-component,
  body .supplier-list-component,
  body .supplier-summary-component,
  body .customer-summary-component,
  body .customer-edit-tabs-component,
  body .customer-add-component,
  body .profile-edit-form-component {
    margin-top: 0;
  }
  body .job-list-component .container,
  body .job-component .container,
  body .customer-list-component .container,
  body .work-order-search-component .container,
  body .purchase-order-search-component .container,
  body .invoice-search-component .container,
  body .payable-search-component .container,
  body .report-component .container,
  body .admin-component .container,
  body .supplier-list-component .container,
  body .supplier-summary-component .container,
  body .customer-summary-component .container,
  body .customer-edit-tabs-component .container,
  body .customer-add-component .container,
  body .profile-edit-form-component .container {
    margin: 0;
  }
  body .job-list-component .container.fixed,
  body .job-component .container.fixed,
  body .customer-list-component .container.fixed,
  body .work-order-search-component .container.fixed,
  body .purchase-order-search-component .container.fixed,
  body .invoice-search-component .container.fixed,
  body .payable-search-component .container.fixed,
  body .report-component .container.fixed,
  body .admin-component .container.fixed,
  body .supplier-list-component .container.fixed,
  body .supplier-summary-component .container.fixed,
  body .customer-summary-component .container.fixed,
  body .customer-edit-tabs-component .container.fixed,
  body .customer-add-component .container.fixed,
  body .profile-edit-form-component .container.fixed {
    margin: 0;
    left: 0;
    position: relative;
    top: 7px;
  }
  body .job-list-component .container.fixed .searchbox,
  body .job-component .container.fixed .searchbox,
  body .customer-list-component .container.fixed .searchbox,
  body .work-order-search-component .container.fixed .searchbox,
  body .purchase-order-search-component .container.fixed .searchbox,
  body .invoice-search-component .container.fixed .searchbox,
  body .payable-search-component .container.fixed .searchbox,
  body .report-component .container.fixed .searchbox,
  body .admin-component .container.fixed .searchbox,
  body .supplier-list-component .container.fixed .searchbox,
  body .supplier-summary-component .container.fixed .searchbox,
  body .customer-summary-component .container.fixed .searchbox,
  body .customer-edit-tabs-component .container.fixed .searchbox,
  body .customer-add-component .container.fixed .searchbox,
  body .profile-edit-form-component .container.fixed .searchbox {
    padding: 0.5rem;
  }
  body .job-list-component .container.fixed .list-actions,
  body .job-component .container.fixed .list-actions,
  body .customer-list-component .container.fixed .list-actions,
  body .work-order-search-component .container.fixed .list-actions,
  body .purchase-order-search-component .container.fixed .list-actions,
  body .invoice-search-component .container.fixed .list-actions,
  body .payable-search-component .container.fixed .list-actions,
  body .report-component .container.fixed .list-actions,
  body .admin-component .container.fixed .list-actions,
  body .supplier-list-component .container.fixed .list-actions,
  body .supplier-summary-component .container.fixed .list-actions,
  body .customer-summary-component .container.fixed .list-actions,
  body .customer-edit-tabs-component .container.fixed .list-actions,
  body .customer-add-component .container.fixed .list-actions,
  body .profile-edit-form-component .container.fixed .list-actions {
    text-align: left;
  }
  body .job-list-component .container.fixed .list-actions .list-filters,
  body .job-component .container.fixed .list-actions .list-filters,
  body .customer-list-component .container.fixed .list-actions .list-filters,
  body .work-order-search-component .container.fixed .list-actions .list-filters,
  body .purchase-order-search-component .container.fixed .list-actions .list-filters,
  body .invoice-search-component .container.fixed .list-actions .list-filters,
  body .payable-search-component .container.fixed .list-actions .list-filters,
  body .report-component .container.fixed .list-actions .list-filters,
  body .admin-component .container.fixed .list-actions .list-filters,
  body .supplier-list-component .container.fixed .list-actions .list-filters,
  body .supplier-summary-component .container.fixed .list-actions .list-filters,
  body .customer-summary-component .container.fixed .list-actions .list-filters,
  body .customer-edit-tabs-component .container.fixed .list-actions .list-filters,
  body .customer-add-component .container.fixed .list-actions .list-filters,
  body .profile-edit-form-component .container.fixed .list-actions .list-filters {
    margin-bottom: 0.5rem;
  }
  body .job-list-component table.table-select-list th,
  body .job-component table.table-select-list th,
  body .customer-list-component table.table-select-list th,
  body .work-order-search-component table.table-select-list th,
  body .purchase-order-search-component table.table-select-list th,
  body .invoice-search-component table.table-select-list th,
  body .payable-search-component table.table-select-list th,
  body .report-component table.table-select-list th,
  body .admin-component table.table-select-list th,
  body .supplier-list-component table.table-select-list th,
  body .supplier-summary-component table.table-select-list th,
  body .customer-summary-component table.table-select-list th,
  body .customer-edit-tabs-component table.table-select-list th,
  body .customer-add-component table.table-select-list th,
  body .profile-edit-form-component table.table-select-list th,
  body .job-list-component table.table-select-list td,
  body .job-component table.table-select-list td,
  body .customer-list-component table.table-select-list td,
  body .work-order-search-component table.table-select-list td,
  body .purchase-order-search-component table.table-select-list td,
  body .invoice-search-component table.table-select-list td,
  body .payable-search-component table.table-select-list td,
  body .report-component table.table-select-list td,
  body .admin-component table.table-select-list td,
  body .supplier-list-component table.table-select-list td,
  body .supplier-summary-component table.table-select-list td,
  body .customer-summary-component table.table-select-list td,
  body .customer-edit-tabs-component table.table-select-list td,
  body .customer-add-component table.table-select-list td,
  body .profile-edit-form-component table.table-select-list td {
    font-size: 0.5rem;
  }
  body .job-list-component .customer-list-container,
  body .customer-list-component .customer-list-container,
  body .work-order-search-component .customer-list-container,
  body .purchase-order-search-component .customer-list-container,
  body .invoice-search-component .customer-list-container,
  body .payable-search-component .customer-list-container,
  body .supplier-list-component .customer-list-container,
  body .job-list-component .list-container,
  body .customer-list-component .list-container,
  body .work-order-search-component .list-container,
  body .purchase-order-search-component .list-container,
  body .invoice-search-component .list-container,
  body .payable-search-component .list-container,
  body .supplier-list-component .list-container,
  body .job-list-component .supplier-list-container,
  body .customer-list-component .supplier-list-container,
  body .work-order-search-component .supplier-list-container,
  body .purchase-order-search-component .supplier-list-container,
  body .invoice-search-component .supplier-list-container,
  body .payable-search-component .supplier-list-container,
  body .supplier-list-component .supplier-list-container {
    margin-top: 6px!important;
  }
  body .layout-sidebar.narrow .sidebar.sidebar-narrow {
    width: 60%;
  }
  body .layout-sidebar.narrow .sidebar-narrow-main {
    width: 30%;
    min-width: 30%;
  }
  body .layout-sidebar.narrow.hidden-sidebar .sidebar.sidebar-narrow {
    left: -70%;
  }
  body .layout-sidebar.narrow.hidden-sidebar .sidebar-narrow-main {
    left: -60%;
    min-width: 100%;
  }
}
/* loading animation */
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  top: 30px;
}
.lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #ddddff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}
.sidebar {
  background-color: #3E606F;
  color: #F6F6FF;
}
.sidebar .sidebar-sidetab-main {
  flex-direction: column;
}
.sidebar a {
  color: #F6F6FF;
}
.sidebar .sidebar-sidetab-tabs {
  display: block;
  background-color: #2c444e;
}
.sidebar .sidebar-sidetab-tabs .nav-tabs {
  border-bottom: 0;
  width: 100%;
  text-align: center;
}
.sidebar .sidebar-sidetab-tabs .nav-tabs li {
  display: flex;
  width: 16%;
}
.sidebar .sidebar-sidetab-tabs .nav-tabs li a {
  border: none;
  border-radius: 0;
  background-color: transparent;
  color: #91AA9D;
  margin-right: 0;
  padding: 7px;
}
.sidebar .sidebar-sidetab-tabs .nav-tabs li a.active {
  background-color: #3E606F;
  color: #F6F6FF;
}
.sidebar .sidebar-sidetab-tabs .nav-tabs li a:hover {
  color: #fff;
  transition: all 0.25s ease-in-out;
}
.sidebar .sidebar-sidetab-main {
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
}
.sidebar .sidebar-tabs {
  position: relative;
}
.sidebar .sidebar-tabs-main .nav-tabs {
  background-color: #3E606F;
  padding: 0;
  border-bottom: 1px solid #F6F6FF;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.sidebar .sidebar-tabs-main .nav-tabs li {
  display: flex;
  flex-flow: column;
  margin-bottom: 0;
}
.sidebar .sidebar-tabs-main .nav-tabs li a {
  background-color: #476e7f;
  margin: 0 1px 0 0;
  color: #f2f2f2;
  border-radius: 0;
  display: flex;
  height: 100%;
  border-top: 1px solid #3E606F;
}
@media screen and (max-width: 991px) {
  .sidebar .sidebar-tabs-main .nav-tabs li a {
    font-size: 0.6rem;
  }
}
.sidebar .sidebar-tabs-main .nav-tabs li a:hover {
  color: #F6F6FF;
  border-bottom-color: #476e7f;
  background-color: #4b7486;
  transition: all 0.25s ease-in-out;
}
.sidebar .sidebar-tabs-main .nav-tabs li a.active {
  border-bottom-color: #476e7f;
  background-color: transparent;
}
.sidebar .sidebar-tabs-main .nav-tabs li a.active:after {
  background-color: #F6F6FF;
}
.sidebar .sidebar-tabs-areas {
  display: block;
  position: relative;
  width: 100%;
  background-color: transparent;
  top: 0;
  bottom: 0;
  left: 0;
}
.sidebar .sidebar-tabs-areas .nav-tabs {
  background-color: transparent;
}
.sidebar .sidebar-tabs-areas a {
  color: #F6F6FF;
}
.sidebar .sidebar-tabs-areas a.active {
  color: #91AA9D;
  background-color: #F6F6FF;
  border-bottom-color: #F6F6FF;
}
.sidebar .sidebar-tabs-areas a.active:after {
  background-color: transparent;
}
.sidebar header {
  padding: 1rem 0.5rem;
}
.sidebar .WorkOrders .sidebar-tabs-main .nav-tabs li a:hover::after {
  background-color: #6daaec;
  transition: all 0.25s ease-in-out;
}
.sidebar .WorkOrders .sidebar-tabs-main .nav-tabs li a.active {
  color: #F6F6FF;
  background-color: #6daaec;
}
.sidebar .WorkOrders .sidebar-tabs-main .nav-tabs li a.active:after {
  background-color: transparent;
}
.sidebar .WorkOrders .table-select-list tr.active::after {
  border-left-color: #6daaec !important;
}
.sidebar .WorkOrders .table-select-list tr.active td {
  background-color: #6daaec;
}
.sidebar .PurchaseOrders .sidebar-tabs-main .nav-tabs li a:hover::after {
  background-color: #cabd64;
  transition: all 0.25s ease-in-out;
}
.sidebar .PurchaseOrders .sidebar-tabs-main .nav-tabs li a.active {
  color: #F6F6FF;
  background-color: #cabd64;
}
.sidebar .PurchaseOrders .sidebar-tabs-main .nav-tabs li a.active:after {
  background-color: transparent;
}
.sidebar .PurchaseOrders .table-select-list tr.active::after {
  border-left-color: #cabd64 !important;
}
.sidebar .PurchaseOrders .table-select-list tr.active td {
  background-color: #cabd64;
}
.sidebar .ChangeOrders .sidebar-tabs-main .nav-tabs li a:hover::after {
  background-color: #d2625d;
  transition: all 0.25s ease-in-out;
}
.sidebar .ChangeOrders .sidebar-tabs-main .nav-tabs li a.active {
  color: #F6F6FF;
  background-color: #d2625d;
}
.sidebar .ChangeOrders .sidebar-tabs-main .nav-tabs li a.active:after {
  background-color: transparent;
}
.sidebar .ChangeOrders .table-select-list tr.active::after {
  border-left-color: #d2625d !important;
}
.sidebar .ChangeOrders .table-select-list tr.active td {
  background-color: #d2625d;
}
.sidebar .Invoices .sidebar-tabs-main .nav-tabs li a:hover::after {
  background-color: #8dbb8d;
  transition: all 0.25s ease-in-out;
}
.sidebar .Invoices .sidebar-tabs-main .nav-tabs li a.active {
  color: #F6F6FF;
  background-color: #8dbb8d;
}
.sidebar .Invoices .sidebar-tabs-main .nav-tabs li a.active:after {
  background-color: transparent;
}
.sidebar .Invoices .table-select-list tr.active::after {
  border-left-color: #8dbb8d !important;
}
.sidebar .Invoices .table-select-list tr.active td {
  background-color: #8dbb8d;
}
.sidebar .Payables .sidebar-tabs-main .nav-tabs li a:hover::after {
  background-color: #a471ab;
  transition: all 0.25s ease-in-out;
}
.sidebar .Payables .sidebar-tabs-main .nav-tabs li a.active {
  color: #F6F6FF;
  background-color: #a471ab;
}
.sidebar .Payables .sidebar-tabs-main .nav-tabs li a.active:after {
  background-color: transparent;
}
.sidebar .Payables .table-select-list tr.active::after {
  border-left-color: #a471ab !important;
}
.sidebar .Payables .table-select-list tr.active td {
  background-color: #a471ab;
}
.sidebar .job-work-order-list-component,
.sidebar .job-purchase-order-list-component,
.sidebar .job-change-order-list-component,
.sidebar .job-invoice-list-component,
.sidebar .job-payable-list-component {
  background-color: #777;
  position: absolute;
  top: 0;
  width: 100%;
}
.account-info-component,
.site-area-component {
  margin: 0 auto;
}
.account-info-component a:hover,
.site-area-component a:hover,
.account-info-component a:active,
.site-area-component a:active,
.account-info-component a:focus,
.site-area-component a:focus {
  text-decoration: none;
}
.account-info-component ul.dropdown-menu,
.site-area-component ul.dropdown-menu {
  background-color: #193441;
}
.account-info-component ul.dropdown-menu li,
.site-area-component ul.dropdown-menu li {
  text-align: center;
}
.account-info-component ul.dropdown-menu li a:hover,
.site-area-component ul.dropdown-menu li a:hover {
  background-color: #275166;
  transition: all 0.25s ease-in-out;
}
.center-pills {
  display: flex;
  justify-content: center;
}
.site-area-component {
  display: block;
  margin: -10px auto;
  opacity: 0.2;
}
.site-area-component .navbar.nav {
  transition: opacity 1s ease;
}
.site-area-component:hover {
  opacity: 1;
  transition: opacity 0.2s linear;
}
.site-area-component a span {
  display: none;
  font-size: 0.5rem;
  line-height: 0.5rem;
  margin: -0.25rem 0;
  text-align: center;
}
.site-area-component nav.navbar-default {
  background-color: transparent;
  border: none;
  margin-bottom: 0;
}
.site-area-component nav.navbar-default button.navbar-toggle {
  border: none;
  margin-right: 0;
}
.site-area-component nav.navbar-default button.navbar-toggle:hover {
  background-color: transparent;
}
.site-area-component nav.navbar-default button.navbar-toggle .icon-bar {
  background-color: #fff;
}
.site-area-component nav.navbar-default .collapse.navbar-collapse {
  padding: 0;
}
.site-area-component nav.navbar-default .collapse.navbar-collapse.menu-open {
  display: block;
}
.site-area-component nav.navbar-default .navbar-nav {
  margin: 0 100px 0 auto;
}
@media screen and (max-width: 1199px) {
  .site-area-component nav.navbar-default .navbar-nav {
    margin: 0 30px 0 auto;
  }
}
@media screen and (max-width: 991px) {
  .site-area-component nav.navbar-default .navbar-nav {
    margin: 0 0 0 auto;
  }
}
.site-area-component nav.navbar-default .navbar-nav li {
  display: inline-block;
  float: none;
}
.site-area-component nav.navbar-default .navbar-nav li a {
  font-size: 1rem;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
  color: #F6F6FF;
  padding: 10px 15px;
  opacity: 0.8;
}
.site-area-component nav.navbar-default .navbar-nav li a:hover {
  background-color: #244a5d;
}
.site-area-component nav.navbar-default .navbar-nav li a.active {
  opacity: 1;
  background-color: #275166;
}
@media screen and (max-width: 767px) {
  .site-area-component .navbar-header .navbar-toggle {
    margin: 10px 20px 0 0;
  }
  .site-area-component nav.navbar-default .navbar-nav li a {
    padding: 15px 24px;
  }
  body header#header .container .flex-container .nav-container .collapse.navbar-collapse.menu-open .account-container {
    padding-bottom: 1rem;
  }
}
.nav-tabs {
  background-color: #ffffff;
  margin: 0;
}
.nav-tabs li.print-menu-component.export-toggle {
  top: 0;
}
.nav-tabs li a,
.nav-tabs li span {
  background-color: transparent;
  border-color: transparent;
  color: #749483;
  cursor: pointer;
  text-transform: uppercase;
}
.nav-tabs li a:focus,
.nav-tabs li span:focus,
.nav-tabs li a:active,
.nav-tabs li span:active,
.nav-tabs li a:hover,
.nav-tabs li span:hover {
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs li a:hover,
.nav-tabs li span:hover {
  border-bottom: 1px solid #91AA9D;
  color: #91AA9D;
  transition: all 0.25s ease-in-out;
}
.nav-tabs li a.active,
.nav-tabs li span.active {
  border-bottom: 1px solid #91AA9D;
}
.nav-tabs li a.active::after,
.nav-tabs li span.active::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3px;
  background-color: #91AA9D;
}
.nav-tabs li span {
  position: relative;
  display: block;
  padding: 10px 15px;
}
.actions {
  text-align: right;
}
.list-actions {
  text-align: right;
}
.list-item-actions {
  text-align: right;
}
.btn-icon i {
  display: none;
}
@media (min-width: 768px) and (max-width: 991px) {
  .report-window-component .actions {
    text-align: left;
  }
}
form.form-horizontal {
  width: 100%;
}
form legend {
  border-bottom: none;
}
.form-group {
  margin-bottom: 20px;
}
.form-group label {
  font-weight: bold;
  font-size: 0.5rem;
  text-align: left;
  white-space: normal;
}
@media (min-width: 768px) and (max-width: 991px) {
  .form-group label {
    font-size: 0.45rem;
  }
}
.form-group label.control-label input[type=checkbox].inline {
  position: relative;
  top: 2px;
}
.form-group label.inline-label {
  padding-top: 8px;
  width: 100%;
}
.checkbox {
  width: 1rem;
}
input[type=checkbox].large {
  height: 20px;
  width: 20px;
  position: relative;
  top: 3px;
}
.btn-checkbox i {
  opacity: 0;
}
.btn-checkbox.btn-danger i,
.btn-checkbox.btn-primary i,
.btn-checkbox.btn-warning i {
  opacity: 1;
}
.form-control {
  max-width: 100%;
}
.form-control-auto {
  float: right;
  width: auto;
}
.form-control:focus {
  border-left: 1px solid #6daaec !important;
}
.ta-results {
  background-color: #fff;
  border-width: 0 1px 1px 1px;
  border-color: #ccc;
  border-style: solid;
  width: auto;
  z-index: 5;
}
.ta-results .ta-item {
  border: none;
  border-radius: none;
  font-size: 0.58rem;
  padding: 3px 20px;
}
.ta-results .ta-item:hover {
  background-color: #337ab7;
  color: #fff;
}
label.city-st-zip {
  width: 100%;
  text-align: left!important;
}
label .city {
  display: inline-block;
  width: 50%;
}
label .city.full-width {
  width: 100%;
}
label .state {
  display: inline-block;
  width: 20%;
}
label .zip {
  display: inline-block;
  width: 30%;
}
label.province-pcode {
  width: 100%;
  text-align: left!important;
}
label .province {
  display: inline-block;
  width: 75%;
}
label .postal-code {
  display: inline-block;
  width: 25%;
}
.form-control.city {
  width: 50%;
}
.form-control.city.full-width {
  width: 100%;
}
.form-control.state {
  width: 20%;
}
.form-control.zip {
  width: 30%;
}
.form-control.province {
  display: inline-block;
  width: 75%;
}
.form-control.postal-code {
  display: inline-block;
  width: 25%;
}
.full-width {
  width: 100%;
}
.input-group-addon {
  cursor: default;
}
.read-only {
  background-color: transparent;
  border: none;
  box-shadow: none;
  cursor: not-allowed;
  font-size: 0.7rem;
  font-weight: bold;
  padding-left: 0;
}
table.line-items tr {
  line-height: 1.5rem;
}
table.table-select-list label {
  font-weight: bold;
  font-size: 0.5rem;
}
table tr.new-entry td {
  width: 14.29%;
  padding: 0.5rem;
}
table tr.new-entry:nth-child(even) {
  background-color: #ffffff;
}
.progress {
  height: 7px;
}
.progress .progress-bar {
  background-color: #8dbb8d;
}
#file-uploader {
  background-color: transparent!important;
}
.document-upload-component label.add-on span {
  font-size: 0.6rem;
  font-weight: normal;
}
.uploader thead tr th {
  font-size: 0.5rem;
}
.upload-controls .progress {
  background-color: #777;
}
.upload-controls .pseudo-link {
  display: inline-block;
  position: relative;
  top: 2px;
  text-transform: capitalize;
}
ul.docs-list {
  margin-bottom: 0.5rem;
}
ul.docs-list li {
  align-items: center;
  border-top: 1px solid #ccc;
  display: flex;
  padding: 0.5rem 0;
}
ul.docs-list li:last-child {
  border-bottom: 1px solid #ccc;
  padding-bottom: 11px;
  margin-bottom: 0.5rem;
}
ul.docs-list li a {
  color: #777;
  text-decoration: underline;
}
table tr.docs-list:last-child td {
  border-bottom: 1px solid #ccc;
}
table tr.docs-list td {
  padding: 0.5rem 0.2rem;
  line-height: 2.2;
}
.documents-section {
  padding: 1rem;
}
input[enforceDecimal] {
  text-align: right;
}
input,
select,
option,
textarea {
  font-family: 'Noto Sans', Tahoma, sans-serif;
}
input.ng-invalid,
select.ng-invalid,
option.ng-invalid,
textarea.ng-invalid,
input.unmatched,
select.unmatched,
option.unmatched,
textarea.unmatched {
  border-left: solid 5px #F25050 !important;
}
input[required].ng-valid,
select[required].ng-valid,
textarea[required].ng-valid {
  border-left: solid 5px #08910B;
}
.ng-select-component[required].ng-invalid .ng-value-container {
  border-left: solid 5px #F25050 !important;
}
.ng-select-component[required].ng-valid .ng-value-container {
  border-left: solid 5px #08910B;
}
select.status-options {
  font-size: 0.5rem;
  width: auto;
  text-transform: uppercase;
}
.status-options.green {
  color: #8dbb8d;
}
.status-options.bright-green {
  color: #08910B;
}
.status-options.blue {
  color: #6daaec;
}
.status-options.orange {
  color: #e89c30;
}
.status-options.red {
  color: #F25050;
}
.status-options.gray {
  color: #777;
}
.status-options option {
  font-weight: bold;
  font-size: 0.5rem;
  text-transform: uppercase;
}
.status-options option.green {
  color: #8dbb8d;
}
.status-options option.bright-green {
  color: #08910B;
}
.status-options option.blue {
  color: #6daaec;
}
.status-options option.orange {
  color: #e89c30;
}
.status-options option.red {
  color: #F25050;
}
.status-options option.black {
  color: #0A1419;
}
.status-options option.gray {
  color: #777;
}
.status-options option:disabled {
  display: none;
}
.status-options option:disabled.locked {
  display: block;
}
.status-options option.locked {
  color: #C0C0C0;
}
.filtered-select option[disabled] {
  color: #C0C0C0;
}
.width-auto {
  width: auto;
}
.vertial-middle {
  vertical-align: middle;
}
.inline-control label {
  float: left;
}
.inline-control input,
.inline-control select {
  float: right;
}
label {
  white-space: nowrap;
}
textarea.notes {
  min-height: 1.4rem;
  height: 1.4rem;
}
textarea.notes:focus {
  min-height: 4rem;
}
textarea.notes.notes-large {
  height: 8.5rem;
  min-height: 8.5rem;
  padding: 0.25rem;
  width: 100%;
}
textarea.notes.notes-large:focus {
  min-height: 8.5rem;
}
textarea.text-area-md {
  min-height: 3rem;
}
.readonly-multiline {
  white-space: pre-wrap;
}
textarea {
  border-radius: 4px;
  border-color: #ccc;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  width: 100%;
}
.ng-select-component .ng-select-container.ng-has-value .ng-placeholder {
  display: none;
}
.ng-select-component .ng-select-container .ng-value-container {
  text-overflow: ellipsis;
  width: 100%;
  min-height: 34px;
  padding: 3px 5px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.ng-select-component .ng-select-container .ng-value-container .ng-placeholder {
  padding: 3px 10px 1px 5px;
  position: absolute;
  z-index: 1;
}
.ng-select-component .ng-select-container .ng-value-container .ng-value {
  background-color: #fff;
  border: 1px solid #ccc;
  color: #555;
  padding: 2px 5px 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
  margin: 1px 3px 1px 8px;
  outline: 0;
  position: relative;
  z-index: 10;
}
.ng-select-component .ng-select-container .ng-value-container .ng-input input {
  padding: 3px 13px!important;
}
.ng-select-component .ng-select-container .ng-clear-wrapper {
  position: absolute;
  right: 0;
  top: 3px;
}
.ng-select-component .ng-value .ng-value-icon,
.ng-select-component .ng-clear-wrapper {
  color: #337ab7;
  float: right;
  font-weight: bold;
  font-size: 1.6em;
  line-height: 0.75;
  margin-top: 1px;
  margin-left: 5px;
  opacity: 1;
  padding: 0;
}
.ng-select-component .ng-dropdown-panel {
  padding: 0;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}
.ng-select-component .ng-dropdown-panel .ng-dropdown-panel-items {
  padding: 0;
}
.ng-select-component .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding: 6px 12px;
  width: 100%;
}
.ng-select-component .ng-dropdown-panel .ng-dropdown-panel-items .ng-option:hover {
  color: #fff;
  background-color: #428bca;
  font-weight: 400;
}
.ng-select-component.ng-select-multiple .ng-select-container {
  overflow: unset;
}
.ng-select-component.ng-select-single .ng-value-container .ng-value {
  border: none;
  padding: 1px 5px 1px 5px;
  font-size: 14px;
}
.ng-select-component:focus {
  border-color: #66afe9;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08), 0 0 8px rgba(102, 175, 233, 0.6);
}
.my-date-range-picker-component {
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 32px;
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
}
.my-date-range-picker-component button.btnpicker {
  margin-bottom: 0!important;
}
.my-date-range-picker-component .selectiongroup input,
.my-date-range-picker-component .selectiongroup .selbtngroup {
  height: 32px!important;
}
.form-group.month-field {
  width: 180px;
}
.month-picker-component .month-picker {
  background-color: #fff;
  border: 1px solid #ccc;
  max-width: 150px;
  position: absolute;
  z-index: 100;
}
.month-picker-component .month-picker .header,
.month-picker-component .month-picker .body {
  background-color: transparent;
  border-radius: 0;
  width: 100%;
}
.month-picker-component .month-picker button {
  background-color: transparent;
  border-radius: 0;
  font-size: 0.675rem;
}
.month-picker-component .month-picker button.btn-primary {
  background-color: #337ab7;
}
.custom-checkbox {
  display: block;
  position: relative;
  padding-left: 30px;
  padding-top: 3px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.custom-checkbox input:checked ~ .checkmark {
  background-color: #286090;
  border: 1px solid #286090;
}
.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}
.custom-checkbox .custom-label {
  margin-left: 10px;
  font-size: 0.65rem;
  position: relative;
  top: -9px;
}
.custom-checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ccc;
}
.custom-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.custom-checkbox:hover input ~ .checkmark {
  background-color: #6daaec;
  transition: all 0.25s ease-in-out;
}
.custom-checkbox:hover input:checked ~ .checkmark {
  background-color: #204d74;
  transition: all 0.25s ease-in-out;
}
/* Customize the label (the container) */
.custom-radio {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.custom-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.custom-radio input:checked ~ .radiobtn {
  background-color: #286090;
}
.custom-radio input:checked ~ .radiobtn:after {
  display: block;
}
.custom-radio .custom-label {
  font-size: 0.65rem;
}
.custom-radio .radiobtn {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #ccc;
}
.custom-radio .radiobtn:after {
  content: "";
  position: absolute;
  display: none;
  top: 6px;
  left: 6px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #fff;
}
.custom-radio:hover input ~ .radiobtn {
  background-color: #6daaec;
  transition: all 0.25s ease-in-out;
}
.custom-radio:hover input:checked ~ .radiobtn {
  background-color: #204d74;
  transition: all 0.25s ease-in-out;
}
.ui-select-container.ui-select-multiple.dropdown.form-control {
  min-height: 34px;
}
.ui-select-container.ui-select-multiple.dropdown.form-control .close {
  color: #337ab7;
  font-weight: bold;
  margin-top: 1px;
  opacity: 1;
}
.ui-select-container.ui-select-multiple.dropdown.form-control input.ui-select-search {
  font-family: 'Noto Sans', Tahoma, sans-serif;
  color: #0A1419;
  padding: 2px 7px 0;
}
.ui-select-container.ui-select-multiple.dropdown.form-control input.ui-select-search:focus {
  border-left: none!important;
}
typeahead-container.dropdown.open {
  width: 100%;
}
button.dropdown-item.ng-trigger {
  border: none;
  background-color: transparent;
  display: block;
  padding: 5px 12px;
  text-align: left;
  width: 100%;
}
button.dropdown-item.ng-trigger:hover,
button.dropdown-item.ng-trigger.active {
  background-color: #337ab7;
  color: #fff;
  transition: all 0.25s ease-in-out;
}
.ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: #337ab7;
  color: #fff;
  transition: all 0.25s ease-in-out;
}
@media screen and (max-width: 1199px) {
  .form-group .entry-control-content .my-date-range-picker-component .mydrp,
  .form-group .filter-entries-component input,
  .form-group .filter-entries-component select {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 991px) {
  .form-group {
    margin-bottom: 0;
  }
  .form-group input,
  .form-group select,
  .form-group .btn-group,
  .form-group .input-group,
  .form-group textarea,
  .form-group button,
  .form-group .ng-select-component,
  .form-group .entry-control-content .my-date-range-picker-component .mydrp {
    margin-bottom: 20px;
  }
  .form-group .btn-group button {
    margin-bottom: 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .filter-list-component .ng-select-component .ng-select-container .ng-value-container .ng-input input {
    margin-bottom: 0;
  }
}
.table-select-list {
  /*@bgColor:@white;
	@textColor:@color-text;
	@hoverColor:lighten(@bgColor, 5);
	@activeColor:lighten(@bgColor, 10);*/
}
.table-select-list tbody tr {
  position: relative;
  cursor: pointer;
}
.table-select-list tbody tr:hover td,
.table-select-list tbody tr:hover th {
  background-color: #ffffff;
  transition: all 0.25s ease-in-out;
}
.table-select-list tbody tr.active td,
.table-select-list tbody tr.active th {
  background-color: #ffffff;
}
.table-select-list tbody tr.no-hover {
  cursor: default;
}
.table-select-list tbody tr.no-hover:hover {
  background-color: transparent;
}
.table-select-list .select-list-toggle {
  width: 70px;
}
.table-select-body tbody:hover tr td {
  background-color: #fff;
  transition: all 0.25s ease-in-out;
}
.sidebar .table-select-list {
  /*@bgColor:@white;
	@textColor:@color-text;
	@hoverColor:lighten(@bgColor, 5);
	@activeColor:lighten(@bgColor, 10);*/
}
.sidebar .table-select-list tbody tr {
  position: relative;
  cursor: pointer;
}
.sidebar .table-select-list tbody tr:hover td,
.sidebar .table-select-list tbody tr:hover th {
  background-color: #848484;
  transition: all 0.25s ease-in-out;
}
.sidebar .table-select-list tbody tr.active td,
.sidebar .table-select-list tbody tr.active th {
  background-color: #919191;
}
.sidebar .table-select-list tbody tr.no-hover {
  cursor: default;
}
.sidebar .table-select-list tbody tr.no-hover:hover {
  background-color: transparent;
}
.sidebar .table-select-list .select-list-toggle {
  width: 70px;
}
.sidebar .table-select-list td {
  border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.attached table {
  margin: 0;
}
.attached table thead th {
  border: 0;
}
.account-container {
  float: right;
  padding-top: 12px;
  text-align: right;
  max-width: 380px;
}
.account-container #account-actions {
  display: block;
  font-size: 13px;
  line-height: 1;
}
.account-container .account-info-component {
  display: flex;
  align-items: center;
}
.account-container .account-info-component #account-action {
  display: inline-block;
  padding: 0 0.5rem;
}
.account-container .account-info-component #logout {
  cursor: pointer;
}
.account-container .account-info-component .location-select {
  appearance: initial;
  -webkit-appearance: initial;
  background-color: #193441;
  border: none;
  color: #F6F6FF;
  padding: 0 10px;
}
.account-container .account-info-component .location-select:focus {
  border: none;
  outline: none;
}
.account-container .account-info-component .location-select option:hover {
  background-color: #275166;
}
.account-container .account-info-component .location-select a#location-select {
  display: block;
  line-height: 13px;
  min-width: 140px;
  text-align: center;
}
.account-container .account-info-component .location-select ul.dropdown-menu {
  left: 0;
  top: 32px;
  position: absolute;
}
.account-container .account-info-component .location-select ul.dropdown-menu a {
  cursor: pointer;
}
.account-container .account-info-component .account-dropdown {
  margin-top: 1px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .account-container .account-info-component .account-dropdown {
    max-width: 132px;
    padding-right: 10px;
  }
  .account-container .account-info-component .account-dropdown a {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.account-container .account-info-component .account-dropdown ul.dropdown-menu {
  right: 0;
  top: 32px;
  position: absolute;
}
.account-container .account-info-component .location-select,
.account-container .account-info-component .account-dropdown {
  position: relative;
}
.account-container .account-info-component .location-select a#location-select,
.account-container .account-info-component .account-dropdown a#location-select,
.account-container .account-info-component .location-select a#account-actions,
.account-container .account-info-component .account-dropdown a#account-actions {
  font-size: 0.585rem;
}
.account-container .account-info-component .location-select a#location-select:after,
.account-container .account-info-component .account-dropdown a#location-select:after,
.account-container .account-info-component .location-select a#account-actions:after,
.account-container .account-info-component .account-dropdown a#account-actions:after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 7px 0 7px;
  border-color: #ffffff transparent transparent transparent;
  position: absolute;
  left: calc(50% - 7px);
  bottom: -15px;
}
.account-container .account-info-component .location-select a#account-actions:after,
.account-container .account-info-component .account-dropdown a#account-actions:after {
  bottom: -14px;
}
.searchbox {
  padding: 1rem 0.5rem;
}
.searchbox.searchbox-fixed {
  width: 15rem;
}
caption .searchbox {
  padding: 0;
}
.search-component form {
  position: relative;
}
.search-component .input-entry.actionable {
  margin-right: 4.5rem;
}
.search-component .input-action {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.job-list-component .search-component input.active,
.supplier-list-component .search-component input.active,
.customer-list-component .search-component input.active,
.work-order-connection-component .search-component input.active,
.purchase-order-connection-component .search-component input.active,
.purchase-order-invoice-connection-component .search-component input.active,
.purchase-order-payable-tab-component .search-component input.active {
  background-color: #CEE7F3;
  border: 2px solid #95BBCE;
}
.job-work-order-list-component .search-component input.active,
.job-purchase-order-list-component .search-component input.active,
.job-change-order-list-component .search-component input.active,
.job-invoice-list-component .search-component input.active,
.job-payable-list-component .search-component input.active {
  background-color: #C3FCFF;
  border: 2px solid #5AC6CC;
}
.btn.btn-condensed {
  padding: 6px;
}
.btn {
  transition: all 0.25s ease-in-out;
}
.btn.btn-outline {
  background: #fff;
  border-color: #2e6da4;
  box-shadow: none;
  color: #2e6da4;
}
.btn.btn-small {
  padding: 0 3px;
  font-size: 0.5rem;
}
.btn.btn-success.active {
  background-color: #91AA9D;
  border-color: #91AA9D;
}
.btn:hover {
  transition: all 0.25s ease-in-out;
}
.btn-group button:last-child {
  border-radius: 0 4px 4px 0;
}
.login-component {
  display: flex;
  width: 100%;
}
#login {
  margin: 4rem auto 2rem;
}
.job-list-component div.container,
.job-list-component .list-container,
.job-list-component .job-list-column {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.job-window-component {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 1rem;
  width: 100%;
}
.job-window-component .breadcrumbs-container {
  background-color: #2c444e;
  color: #F6F6FF;
  flex: 0 0 1.5rem;
  margin: -1rem -1rem 0 -1rem;
}
.job-window-component .breadcrumbs {
  opacity: 0.5;
  transition: opacity 0.5s ease-out;
}
.job-window-component .breadcrumbs:hover {
  opacity: 1;
  transition: opacity 0.2s linear;
  transition: all 0.25s ease-in-out;
}
.job-window-component .breadcrumbs .breadcrumb-item {
  background: transparent;
  display: inline-block;
  margin: 0;
  padding: 0.3rem 0.8rem 0.37rem;
  margin-right: 0.3rem;
  position: relative;
  font-size: 0.6rem;
  opacity: 0.5;
}
.job-window-component .breadcrumbs .breadcrumb-item::after {
  content: '>';
  position: absolute;
  right: -0.3rem;
  text-decoration: none;
  top: 50%;
  transform: translateY(-50%);
}
.job-window-component .breadcrumbs .breadcrumb-item:last-child::after {
  content: none;
}
.job-window-component .breadcrumbs .breadcrumb-item.navigable {
  cursor: pointer;
  opacity: 1;
}
.job-window-component .breadcrumbs .breadcrumb-item.navigable:hover {
  background-color: #4b7486;
  transition: all 0.25s ease-in-out;
}
.job-window-component .breadcrumbs .breadcrumb-item.navigable:last-child {
  cursor: default;
  text-decoration: none;
  opacity: 0.5;
}
.job-window-component .breadcrumbs .breadcrumb-item.navigable:last-child:hover {
  background-color: transparent;
  transition: all 0.25s ease-in-out;
}
.job-window-component .work-order-component .nav-tabs a {
  color: #6daaec;
}
.job-window-component .work-order-component .nav-tabs a.active {
  border-bottom: 1px solid #6daaec;
}
.job-window-component .work-order-component .nav-tabs a.active::after {
  background-color: #6daaec;
}
.job-window-component .purchase-order-component .nav-tabs a {
  color: #cabd64;
}
.job-window-component .purchase-order-component .nav-tabs a.active {
  border-bottom: 1px solid #cabd64;
}
.job-window-component .purchase-order-component .nav-tabs a.active::after {
  background-color: #cabd64;
}
.job-window-component .change-order-component .nav-tabs a {
  color: #d2625d;
}
.job-window-component .change-order-component .nav-tabs a.active {
  border-bottom: 1px solid #d2625d;
}
.job-window-component .change-order-component .nav-tabs a.active::after {
  background-color: #d2625d;
}
.job-window-component .invoice-component .nav-tabs a {
  color: #8dbb8d;
}
.job-window-component .invoice-component .nav-tabs a.active {
  border-bottom: 1px solid #8dbb8d;
}
.job-window-component .invoice-component .nav-tabs a.active::after {
  background-color: #8dbb8d;
}
.job-window-component .payable-component .nav-tabs a {
  color: #a471ab;
}
.job-window-component .payable-component .nav-tabs a.active {
  border-bottom: 1px solid #a471ab;
}
.job-window-component .payable-component .nav-tabs a.active::after {
  background-color: #a471ab;
}
.job-window-component .payable-component .print-menu-component span.dropdown-toggle {
  color: #a471ab;
}
.job-summary-component {
  display: block!important;
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-direction: row;
  position: relative;
}
.job-summary-component #job-info {
  background-color: #3E606F;
}
.job-summary-component #job-info h2,
.job-summary-component #job-info h5 {
  margin-top: 0;
}
.job-summary-component #job-info header {
  position: relative;
  padding: 1rem 1rem 0;
}
.job-summary-component #job-navi {
  display: block;
}
.job-summary-component #job-flags {
  text-align: right;
}
.job-summary-component #job-flags .tax-exempt,
.job-summary-component #job-flags .white,
.job-summary-component #job-flags .closed {
  display: inline-block;
  padding: 0 0.25rem;
  margin: 0.1rem 0;
}
.job-summary-component #job-flags .tax-exempt,
.job-summary-component #job-flags .white {
  color: #F2C650;
}
.job-summary-component #job-flags .closed {
  color: #F25050;
}
.job-summary-component #job-tabs ul.nav {
  margin: 0;
}
.job-summary-component #job-tabs li a {
  padding: 10px 12px;
  text-transform: none;
}
.job-summary-component #job-lists {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
}
.job-summary-component #job-summary-details {
  opacity: 0.8;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.job-summary-component #job-summary-details label {
  display: inline-block;
  width: 150px;
  margin-right: 1rem;
}
.job-summary-component #job-summary-details label::after {
  content: ":";
}
.job-summary-component #job-summary-details #bids span {
  text-align: right;
  float: right;
}
.job-change-order-summary-component {
  display: block;
  padding: 0 0 1rem 0;
}
.job-change-order-summary-component .entry {
  margin: 0.25rem 0;
}
.job-change-order-summary-component .total {
  font-weight: bold;
}
.job-invoice-edit-component .print-menu-component .nav-tabs li a.dropdown-toggle {
  padding: 6px 15px;
}
.job-invoice-edit-component .print-menu .print-menu-option {
  width: 10rem;
}
.job-invoice-edit-component .line-items .input-group input[enforceDecimal] {
  min-width: 55px;
}
.job-work-order-list-component,
.job-purchase-order-list-component,
.job-change-order-list-component,
.job-invoice-list-component,
.job-payable-list-component {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.job-work-order-list-component header,
.job-purchase-order-list-component header,
.job-change-order-list-component header,
.job-invoice-list-component header,
.job-payable-list-component header {
  flex: 0 0 2.3rem;
}
.work-order-list-component,
.purchase-order-list-component,
.change-order-list,
.invoice-list-component {
  flex: 1;
  overflow: auto;
}
.work-order-list-component table,
.purchase-order-list-component table,
.change-order-list table,
.invoice-list-component table {
  margin-bottom: 0 !important;
}
.job-date-arrow {
  float: left;
  padding: 0.25rem;
}
.shared-modal-component .modal-dialog .modal-body .contact-edit .col-md-4 {
  width: 50%;
}
.shared-modal-component .modal-dialog .modal-body .contact-edit .col-md-4 input[required].ng-invalid {
  border-left: solid 5px #F25050;
}
.shared-modal-component .modal-dialog .modal-body .contact-edit .col-md-4 input[required].ng-valid {
  border-left: solid 5px #08910B;
}
.job-edit-form-component .flags-container,
.job-edit-form-component .bid-container {
  margin-top: 27px;
}
@media screen and (max-width: 991px) {
  .job-window-component .work-order-component .print-menu-component,
  .job-window-component .purchase-order-component .print-menu-component,
  .job-window-component .invoice-component .print-menu-component {
    position: relative;
  }
  #job-lists header button.btn-block {
    display: block;
    margin-top: 0.5rem;
  }
  #job-lists .table-select-list {
    font-size: 0.45rem;
  }
  #job-navi .sidebar-tabs .nav-tabs a {
    font-size: 0.6rem;
  }
  .job-edit-form-component .flags-container,
  .job-edit-form-component .bid-container {
    margin-top: 0;
  }
}
@media (min-width: 768px) and (max-width: 990px) {
  .job-window-component {
    padding-top: 0;
  }
  .job-window-component .breadcrumbs-container {
    display: none;
  }
}
.pm-note {
  position: relative;
  list-style: none;
  margin: 0 0 2rem;
  padding: 0;
  cursor: pointer;
}
.pm-notes-list {
  list-style: none;
  margin: 0;
  padding: 0;
}
.pm-note-title {
  border: 1px solid #777;
  padding: 0.35rem;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.6);
}
.pm-note-body {
  border: 1px solid #999;
  border-top: none;
  margin-bottom: 0.5rem;
}
.pm-note-body pre {
  background-color: transparent;
  font-family: inherit;
  border-radius: 0;
  border: none;
  white-space: normal;
}
.pm-note .actions {
  position: absolute;
  right: 18px;
  top: 4px;
}
.customer-list-component div.container,
.customer-list-component .customer-list-container,
.customer-list-component .customer-list-column {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.customer-list-component .no-results {
  color: #888;
  font-size: 1rem;
  padding: 0.5rem;
  text-align: center;
}
.customer-summary-component,
.supplier-summary-component {
  background-color: #3E606F;
  flex: 1 0 0;
}
#customer-summary,
#supplier-summary {
  padding: 1rem 1rem 0;
}
#customer-summary h2,
#supplier-summary h2 {
  margin-top: 0;
}
#customer-summary .customer-name,
#supplier-summary .customer-name,
#customer-summary .supplier-name,
#supplier-summary .supplier-name {
  margin-bottom: 1rem;
}
#customer-summary dt,
#supplier-summary dt {
  float: left;
  clear: left;
  text-align: right;
  font-weight: normal;
  width: 75px;
}
#customer-summary dt::after,
#supplier-summary dt::after {
  content: ":";
}
#customer-summary dd,
#supplier-summary dd {
  margin-left: 75px;
  padding-left: 1rem;
}
#customer-summary .street1,
#supplier-summary .street1,
#customer-summary .street2,
#supplier-summary .street2 {
  display: block;
}
.customer-edit-tabs-component,
.supplier-edit-tabs-component {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.customer-edit-tabs-component nav,
.supplier-edit-tabs-component nav {
  flex: 0 0 20px;
}
.customer-edit-tabs-component #content,
.supplier-edit-tabs-component #content {
  flex: 1;
  overflow: auto;
}
#location-list {
  margin: 2rem 0;
}
#location-list li {
  cursor: pointer;
  padding: 1rem 0;
}
#location-list li:hover {
  background-color: #ffffff;
  transition: all 0.25s ease-in-out;
}
.list-item-date {
  width: 5rem;
}
.list-item-number {
  width: 3.5rem;
}
.list-item-code {
  width: 6rem;
}
.list-item-group {
  background-color: #e7e7ff;
  border-style: solid;
  border-width: 2px 0;
}
.list-item-subitem {
  padding-left: 1rem !important;
}
.list-total {
  border: 1px solid #08910B;
  border-radius: 4px;
  color: #0A1419;
  float: right;
  font-size: 0.575rem;
  font-weight: 600;
  margin: 0;
  padding: 10px 20px;
  position: relative;
  text-align: center;
}
.list-total span {
  margin-left: 40px;
}
.list-total:after {
  content: '';
  position: absolute;
  right: 32px;
  bottom: -6px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: #08910B transparent transparent transparent;
}
thead.spacer {
  height: 0;
}
thead.spacer th,
thead.spacer td {
  border: none !important;
  margin: 0 !important;
  padding: 0 !important;
}
td.list-item-flag,
th.list-item-flag {
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 1px;
}
table tbody th.center-vertical,
table thead th.center-vertical,
table tbody td.center-vertical,
table thead td.center-vertical {
  vertical-align: middle;
}
table tbody th.top-vertical,
table thead th.top-vertical,
table tbody td.top-vertical,
table thead td.top-vertical {
  vertical-align: top;
}
#infinite-scroll-list,
.infinite-scroll-list {
  /* the list of data */
}
#infinite-scroll-list table th,
.infinite-scroll-list table th,
#infinite-scroll-list table td,
.infinite-scroll-list table td {
  font-size: 0.575rem;
  font-weight: 400;
}
@media (max-width: 991px) {
  #infinite-scroll-list table th,
  .infinite-scroll-list table th,
  #infinite-scroll-list table td,
  .infinite-scroll-list table td {
    font-size: 0.5rem;
  }
  #infinite-scroll-list table th.list-actions .btn,
  .infinite-scroll-list table th.list-actions .btn,
  #infinite-scroll-list table td.list-actions .btn,
  .infinite-scroll-list table td.list-actions .btn {
    padding: 6px;
    font-size: 0.5rem;
  }
}
#infinite-scroll-list table th.center-vertical,
.infinite-scroll-list table th.center-vertical,
#infinite-scroll-list table td.center-vertical,
.infinite-scroll-list table td.center-vertical {
  vertical-align: middle;
}
#infinite-scroll-list th.top-vertical,
.infinite-scroll-list th.top-vertical,
#infinite-scroll-list td.top-vertical,
.infinite-scroll-list td.top-vertical {
  vertical-align: top;
}
#infinite-scroll-list table thead tr th,
.infinite-scroll-list table thead tr th {
  padding: 0 8px 7px;
}
#infinite-scroll-list table tr:first-child td,
.infinite-scroll-list table tr:first-child td {
  border-top: 0;
}
#infinite-scroll-list {
  padding-top: 12px;
}
.infinite-scroll-list table thead tr th {
  padding: 0 8px;
}
.sortable-header-component,
.infinite-scroll-header-component.sortable {
  cursor: pointer;
}
.sortable-header-component div,
.infinite-scroll-header-component.sortable div {
  color: #507c90;
  display: inline-block;
  font-weight: 400;
}
.sortable-header-component div.active,
.infinite-scroll-header-component.sortable div.active {
  color: #3E606F;
  position: relative;
}
.sortable-header-component div.active .glyphicon,
.infinite-scroll-header-component.sortable div.active .glyphicon {
  position: absolute;
  right: auto;
  left: -17px;
  top: 4px;
}
.log-note {
  padding: 5px 0;
  border-bottom: 1px solid #ddd;
}
.log-note:last-child {
  border-bottom: none;
}
.pm-name {
  color: #4d4d4d;
  font-size: 0.5rem;
  text-transform: uppercase;
}
/* dragula drag-n-drop module
/* in-flight clone */
.draggable li {
  cursor: grab;
  padding: 0.5rem 0;
}
.draggable li .glyphicon-option-vertical {
  font-size: 0.8rem;
  color: #ccc;
  padding: 0;
}
.gu-mirror {
  box-shadow: 2px 5px 10px #000;
  position: fixed !important;
  margin: 0 !important;
  padding: 0.5rem 0;
  z-index: 9999 !important;
  pointer-events: none;
  list-style-type: none;
  background-color: #F6F6FF;
  opacity: 1;
}
.gu-mirror .glyphicon-option-vertical {
  color: #ccc;
  top: 7px;
}
.gu-mirror .glyphicon-move {
  font-size: 1rem;
  color: #ccc;
  padding: 0;
  left: -8px;
  position: relative;
  top: 6px;
}
.gu-mirror a {
  position: relative;
  top: 6px;
  color: #777 !important;
  text-decoration: underline;
}
.gu-mirror span.afs {
  position: relative;
  top: 6px;
}
/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}
/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
}
/* position */
.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}
.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}
.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-left {
  top: 12px;
  left: 12px;
}
.toast-top-right {
  top: 72px;
  right: 12px;
}
.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}
.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}
/* toast styles */
.toast-title {
  font-weight: bold;
}
.toast-message {
  word-wrap: break-word;
}
.toast-message a,
.toast-message label {
  color: #FFFFFF;
}
.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}
.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}
.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}
/*Additional properties for button version
   iOS requires the button element instead of an anchor tag.
   If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}
.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}
.toast-container * {
  box-sizing: border-box;
}
.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #FFFFFF;
}
.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z'/%3E%3C/svg%3E");
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/%3E%3C/svg%3E");
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E");
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' width='576' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3E%3C/svg%3E");
}
.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}
.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}
.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}
.toast-success {
  background-color: #51A351;
}
.toast-error {
  background-color: #BD362F;
}
.toast-info {
  background-color: #2F96B4;
}
.toast-warning {
  background-color: #F89406;
}
.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}
/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
body .toast-container.toast-bottom-center .ngx-toastr {
  color: #0A1419;
  font-size: 1rem;
  height: 3rem;
  padding: 0.85rem 0.5rem 0.5rem;
  margin: 0;
  text-align: center;
  width: 100%;
  background-image: none;
}
.toast-success {
  background-color: #91AA9D;
}
.toast-error {
  background-color: #F25050;
  color: #260D0D;
}
.toast-info {
  background-color: #6daaec;
}
.toast-warning {
  background-color: #e89c30;
}
.print-button-component {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
}
.print-menu-component {
  position: absolute;
  right: 0;
  top: 36px;
  z-index: 10;
}
.print-menu-component .print-menu .print-menu-option {
  padding: 0.25rem;
  white-space: nowrap;
}
.print-menu-component .print-menu .print-menu-option button {
  margin-right: 0.5rem;
}
.print-menu-component .print-menu .print-menu-execute {
  text-align: center;
}
.print-menu-component .print-menu .print-menu-execute button {
  width: 95%;
}
.print-menu-component .nav-tabs li a.dropdown-toggle {
  border-radius: 0;
}
.print-bold {
  font-weight: bold;
}
.print-table-row-spacer {
  height: 0.5rem;
}
.print-table-row-spacer hr {
  border: 1px solid #0A1419;
  margin: 0.15rem 0.6rem 0 0.1rem;
}
.print-header-indicator {
  border-style: solid;
  border-width: 0.25rem 1rem;
  position: absolute;
  height: 0.5rem;
  width: 2rem;
}
table thead.print-only,
table tr.print-only {
  line-height: 0;
}
table tr.print-only {
  display: none;
}
table.two-column tr td {
  width: 50%;
}
table.three-column tr td {
  width: 33%;
}
table.grid td,
table.grid th {
  border: 1px solid #C0C0C0;
  padding: 0.2rem;
}
@media print {
  body {
    font-size: 12px;
  }
  @page :first {
    margin-top: 0.3625in;
  }
  @page {
    margin-top: 0.675in;
  }
  .print-controls,
  .print-hide,
  div#toast,
  div.sidebar,
  header,
  .sidebar-toggler,
  .anchors {
    display: none!important;
  }
  a[href]:after {
    content: none !important;
  }
  table {
    width: 100%;
  }
  table thead.print-only,
  table tr.print-only {
    line-height: inherit !important;
  }
  table tr.print-only {
    display: table-row;
  }
  table table {
    page-break-inside: auto;
  }
  table tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }
  table thead {
    display: table-header-group;
  }
  table tfoot {
    display: table-footer-group;
  }
  table td,
  table th {
    vertical-align: top;
    font-size: 15px;
  }
  table.table-zebra tbody tr:nth-child(odd) td {
    -webkit-print-color-adjust: exact;
    background-color: #eee !important;
  }
  table.report-total {
    margin-top: 1.75rem !important;
  }
  .layout-sidebar.narrow {
    padding-left: 0;
  }
  .layout-sidebar.narrow .sidebar-main.sidebar-narrow-main {
    left: 0!important;
  }
  .report-window-component {
    display: block !important;
  }
  .report-window-component .report-job-detail-component,
  .report-window-component .report-job-outcome-component,
  .report-window-component .report-job-summary-component {
    display: block !important;
  }
  .report-window-component .report-job-detail-component .screen-title,
  .report-window-component .report-job-outcome-component .screen-title,
  .report-window-component .report-job-summary-component .screen-title {
    font-size: 18pt!important;
    margin: 0!important;
    text-align: center;
  }
  .report-window-component .report-job-detail-component #reportPane,
  .report-window-component .report-job-outcome-component #reportPane,
  .report-window-component .report-job-summary-component #reportPane {
    display: block !important;
    font-size: 0.4rem;
    position: relative;
    left: 0;
    max-width: 100%;
    flex-basis: 100%;
  }
  .report-window-component .report-job-detail-component #reportPane table caption,
  .report-window-component .report-job-outcome-component #reportPane table caption,
  .report-window-component .report-job-summary-component #reportPane table caption {
    font-size: 14pt;
  }
  .report-window-component .report-job-detail-component #reportPane table th,
  .report-window-component .report-job-outcome-component #reportPane table th,
  .report-window-component .report-job-summary-component #reportPane table th {
    font-size: 10pt;
    padding: 3px;
  }
  .report-window-component .report-job-detail-component #reportPane table td,
  .report-window-component .report-job-outcome-component #reportPane table td,
  .report-window-component .report-job-summary-component #reportPane table td {
    font-size: 10pt;
    padding: 3px;
  }
  .report-window-component .report-job-outcome-component {
    padding-left: 0;
  }
  .report-window-component .report-job-outcome-component table th,
  .report-window-component .report-job-outcome-component table td {
    font-size: 9px!important;
  }
  #reportPane,
  .jobList-print {
    margin: 0;
  }
  #reportPane .screen-title,
  .jobList-print .screen-title {
    font-size: 18pt!important;
    text-align: center;
    margin: 0!important;
  }
  #reportPane table caption,
  .jobList-print table caption {
    font-size: 14pt;
  }
  #reportPane table th,
  .jobList-print table th {
    font-size: 10pt;
    padding: 3px;
  }
  #reportPane table td,
  .jobList-print table td {
    font-size: 10pt;
    padding: 3px;
  }
  hr {
    margin: 0.2rem;
  }
  .print {
    font-size: 0.6rem;
    min-height: 100%;
    page-break-after: always;
    position: relative;
  }
  .print .print-header {
    font-size: 0.7rem;
    font-weight: bold;
    position: absolute;
    right: 0;
    top: 0;
  }
  .print.invoice-print .invoice-details tbody tr td,
  .print.invoice-print .invoice-footer-cost tr td,
  .print.invoice-print .invoice-footer-totals tr td {
    font-size: 10pt!important;
  }
  .print.invoice-print .print-header {
    left: 0;
    max-width: 8rem;
    top: 0;
  }
  .print.invoice-print .invoice-indicator {
    left: 0;
  }
  .print.invoice-print .invoice-indicator-projectManager {
    border-color: orange;
  }
  .print.invoice-print .invoice-indicator-order {
    border-color: yellow;
  }
  .print.invoice-print .invoice-indicator-accounting {
    border-color: magenta;
  }
  .print.invoice-print .invoice-indicator-customer {
    border-color: transparent;
  }
  .print.invoice-print .invoice-indicator-field {
    border-color: orange;
  }
  .print.invoice-print .invoice-indicator-tagging {
    border-color: green;
  }
  .print.invoice-print .invoice-header tr {
    margin-top: 1rem;
  }
  .print.invoice-print .invoice-header .logo {
    display: inline-block;
    width: auto;
    position: relative;
    top: -22px;
  }
  .print.invoice-print .invoice-header .logo img {
    max-width: 200px;
  }
  .print.invoice-print .invoice-header .invoice-remit {
    display: block;
    font-size: 1em;
    font-weight: bold;
    margin-top: -5px;
  }
  .print.invoice-print .invoice-header .print-header {
    display: block;
    position: relative;
    text-align: right;
    margin: 0 0 0.125in 0;
    width: auto;
    max-width: none;
  }
  .print.invoice-print .invoice-header .print-header .print-header-indicator {
    display: block;
    position: relative;
    margin-top: 3px;
    float: right;
  }
  .print.invoice-print .invoice-meta {
    float: right;
  }
  .print.invoice-print .invoice-meta .two-column {
    max-width: 2in;
    text-align: left;
    float: right;
    white-space: nowrap;
  }
  .print.invoice-print .invoice-meta .two-column td {
    padding: 3px;
  }
  .print.invoice-print .invoice-meta td + td {
    border: 1px solid #999;
  }
  .print.invoice-print .invoice-location {
    display: inline-block;
    font-size: 0.75em;
    padding-left: 0.25in;
  }
  .print.invoice-print .invoice-info td,
  .print.invoice-print .invoice-info th {
    font-size: 0.5rem;
    text-align: center;
    width: 16%;
    border: 1px solid #999;
  }
  .print.invoice-print .top-align {
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }
  .print.invoice-print .invoice-flag .glyphicon {
    margin-right: 3px;
  }
  .print.invoice-print .invoice-notes legend {
    margin-bottom: 0;
  }
  .print.invoice-print .invoiceNotes-banner {
    border: 1px solid #0A1419;
    padding: 0.1rem 0;
    text-align: center;
  }
  .print.invoice-print .invoice-address,
  .print.invoice-print .invoice-details,
  .print.invoice-print .invoice-footer,
  .print.invoice-print .invoice-info,
  .print.invoice-print .invoice-notes {
    margin-top: 1rem;
  }
  .print.invoice-print .invoice-footer2 {
    position: fixed;
    bottom: 0;
    margin-bottom: 3em;
  }
  .print.invoice-print.Customer .invoice-address-customer,
  .print.invoice-print.invoice-print .invoice-address-customer {
    height: 9em;
    font-size: 0.8em;
  }
  .print.invoice-print.Customer .invoice-address-customer #customer-address,
  .print.invoice-print.invoice-print .invoice-address-customer #customer-address {
    position: absolute;
    top: 1.8in;
    left: 0.75in;
  }
  .print.invoice-print.Customer .invoice-address-delivery,
  .print.invoice-print.invoice-print .invoice-address-delivery {
    font-size: 0.8em;
  }
  .print.invoice-print.Customer .invoice-address-delivery #delivery-address,
  .print.invoice-print.invoice-print .invoice-address-delivery #delivery-address {
    position: absolute;
    left: 4.5in;
    top: 1.8in;
  }
  .print.invoice-print .invoice-footer-disclaimer {
    font-size: 0.4rem;
    padding-right: 1rem;
  }
  .print.invoice-print .invoice-total {
    background-color: #000;
    color: #fff;
  }
  .purchaseOrder-print {
    margin-top: 0.3125in;
  }
  .purchaseOrder-print .purchaseOrder-indicator,
  .exported-po .purchaseOrder-indicator {
    right: 0;
  }
  .purchaseOrder-print .purchaseOrder-indicator-job,
  .exported-po .purchaseOrder-indicator-job {
    border-color: transparent;
  }
  .purchaseOrder-print .purchaseOrder-indicator-shop,
  .exported-po .purchaseOrder-indicator-shop {
    border-color: orange;
  }
  .purchaseOrder-print .purchaseOrder-indicator-accounting,
  .exported-po .purchaseOrder-indicator-accounting {
    border-color: yellow;
  }
  .purchaseOrder-print .purchaseOrder-indicator-supplier,
  .exported-po .purchaseOrder-indicator-supplier {
    border-color: transparent;
  }
  .purchaseOrder-print .purchaseOrder-info,
  .exported-po .purchaseOrder-info {
    text-align: center;
  }
  .purchaseOrder-print .purchaseOrder-info .date,
  .exported-po .purchaseOrder-info .date {
    text-align: left;
  }
  .purchaseOrder-print .purchaseOrder-info .location-name,
  .exported-po .purchaseOrder-info .location-name {
    font-size: 0.8rem;
  }
  .purchaseOrder-print .purchaseOrder-info .purchaseOrder-number,
  .exported-po .purchaseOrder-info .purchaseOrder-number {
    font-size: 0.4rem;
    padding-top: 2rem;
  }
  .purchaseOrder-print .purchaseOrder-info .purchaseOrder-number-box,
  .exported-po .purchaseOrder-info .purchaseOrder-number-box {
    border: 1px solid #0A1419;
    padding: 0.5rem;
  }
  .purchaseOrder-print .purchaseOrder-info .purchaseOrder-number-number,
  .exported-po .purchaseOrder-info .purchaseOrder-number-number {
    font-size: 0.6rem;
  }
  .purchaseOrder-print .shipping-misc td,
  .exported-po .shipping-misc td {
    border: 1px solid #666;
    padding: 0.2rem;
  }
  .purchaseOrder-print .purchaseOrder-parts .purchaseOrder-banner,
  .exported-po .purchaseOrder-parts .purchaseOrder-banner {
    border: 1px solid #0A1419;
    padding: 0.1rem 0;
    text-align: center;
  }
  .purchaseOrder-print .purchaseOrder-parts table thead,
  .exported-po .purchaseOrder-parts table thead {
    margin: 0.5rem 0;
  }
  .purchaseOrder-print .purchaseOrder-parts table thead th.quantity,
  .exported-po .purchaseOrder-parts table thead th.quantity {
    width: 3rem;
  }
  .purchaseOrder-print .purchaseOrder-parts table thead th.unit,
  .exported-po .purchaseOrder-parts table thead th.unit {
    width: 3rem;
  }
  .purchaseOrder-print .purchaseOrder-parts table thead th.cost,
  .exported-po .purchaseOrder-parts table thead th.cost {
    width: 5rem;
  }
  .purchaseOrder-print .purchaseOrder-parts table tbody tr,
  .exported-po .purchaseOrder-parts table tbody tr {
    margin: 0.25rem 0;
  }
  .purchaseOrder-print .purchaseOrder-notes,
  .exported-po .purchaseOrder-notes,
  .purchaseOrder-print .purchaseOrder-parts,
  .exported-po .purchaseOrder-parts,
  .purchaseOrder-print .shipping-misc,
  .exported-po .shipping-misc {
    margin-top: 1rem;
  }
  .workOrder-print {
    margin-top: 0.3125in;
  }
  .workOrder-print .workOrder-indicator {
    right: 0;
  }
  .workOrder-print .workOrder-indicator-job {
    border-color: transparent;
  }
  .workOrder-print .workOrder-indicator-shop {
    border-color: green;
  }
  .workOrder-print .workOrder-indicator-field {
    border-color: orange;
  }
  .workOrder-print .workOrder-indicator-control {
    border-color: transparent;
  }
  .workOrder-print .workOrder-info .projectManager {
    width: 30%;
  }
  .workOrder-print .workOrder-info .customer {
    width: 45%;
  }
  .workOrder-print .workOrder-info .workOrder-number {
    width: 20%;
  }
  .workOrder-print .job-info,
  .workOrder-print .purchaseOrder-info,
  .workOrder-print .workOrder-notes,
  .workOrder-print .workOrder-otherParts,
  .workOrder-print .workOrder-pmNotes {
    margin-top: 0.5rem;
  }
  .workOrder-print .workOrder-pmNotes-title {
    border: 2px solid #0A1419;
    font-weight: bold;
    margin-bottom: 0.25rem;
    padding: 0.25rem;
    text-align: center;
  }
  .workOrder-print .workOrder-pmNotes-description {
    margin-bottom: 0.5rem;
  }
  .workOrder-print .purchaseOrder-info .purchaseOrder-summary {
    font-size: 0.8rem;
    font-weight: bold;
  }
  .workOrder-print .purchaseOrder-info .purchaseOrder-notes {
    clear: both;
  }
  .workOrder-print .purchaseOrder-name {
    border: 2px solid #0A1419;
    font-weight: bold;
    padding: 0.25rem;
    text-align: center;
  }
  .workOrder-print .workOrder-otherParts-header {
    font-size: 0.7rem;
    font-weight: bold;
  }
  .workOrder-print .workOrder-otherParts .purchaseOrder-parts th.part-quantity {
    width: 80px;
  }
  .workOrder-print .workOrder-otherParts .purchaseOrder-parts th.part-unit {
    width: 120px;
  }
  .jobList-print .jobList-header {
    font-size: 1.2rem;
    text-align: center;
  }
  .jobList-print .jobList-flag {
    font-size: 0.6rem;
    font-weight: bold;
  }
}
@media screen {
  table {
    width: 100%;
  }
  table td,
  table th {
    vertical-align: top;
  }
  .print-controls {
    background-color: #193441;
    height: 2.3rem;
    padding: 0.4rem;
    position: absolute;
    right: 0;
    text-align: right;
    top: 0;
    width: 70%;
    z-index: 1000;
  }
  .print {
    background-color: #fff;
    border: 1px solid #0A1419;
    font-size: 0.6rem !important;
    height: 11in;
    left: 50%;
    margin: 0.25in;
    padding: 0.25in;
    position: relative;
    transform: translateX(-50%);
    width: 8.5in;
  }
  .print .print-header {
    font-size: 0.7rem;
    font-weight: bold;
    margin: 0.25in;
    position: absolute;
    right: 0;
    top: 0;
  }
  .workOrder-print .workOrder-indicator {
    right: 0;
  }
  .workOrder-print .workOrder-indicator-job {
    border-color: transparent;
  }
  .workOrder-print .workOrder-indicator-shop {
    border-color: green;
  }
  .workOrder-print .workOrder-indicator-field {
    border-color: orange;
  }
  .workOrder-print .workOrder-indicator-control {
    border-color: transparent;
  }
  .workOrder-print .workOrder-info .projectManager {
    width: 30%;
  }
  .workOrder-print .workOrder-info .customer {
    width: 50%;
  }
  .workOrder-print .workOrder-info .workOrder-number {
    width: 19%;
  }
  .workOrder-print .job-info,
  .workOrder-print .purchaseOrder-info,
  .workOrder-print .workOrder-notes,
  .workOrder-print .workOrder-otherParts,
  .workOrder-print .workOrder-pmNotes {
    margin-top: 1rem;
  }
  .workOrder-print .workOrder-pmNotes-title {
    border: 2px solid #0A1419;
    font-weight: bold;
    margin-bottom: 0.25rem;
    padding: 0.25rem;
    text-align: center;
  }
  .workOrder-print .workOrder-pmNotes-description {
    margin-bottom: 0.5rem;
  }
  .workOrder-print .purchaseOrder-info .purchaseOrder-summary {
    font-size: 0.8rem;
    font-weight: bold;
  }
  .workOrder-print .purchaseOrder-info .purchaseOrder-summary-basic {
    float: left;
  }
  .workOrder-print .purchaseOrder-info .purchaseOrder-notes {
    clear: both;
  }
  .workOrder-print .purchaseOrder-name {
    border: 2px solid #0A1419;
    font-weight: bold;
    padding: 0.25rem;
    text-align: center;
  }
  .workOrder-print .workOrder-otherParts-header {
    font-size: 0.7rem;
    font-weight: bold;
  }
  .workOrder-print .workOrder-otherParts .purchaseOrder-parts th.part-quantity {
    width: 80px;
  }
  .workOrder-print .workOrder-otherParts .purchaseOrder-parts th.part-unit {
    width: 120px;
  }
  .purchaseOrder-print .purchaseOrder-indicator {
    right: 0;
  }
  .purchaseOrder-print .purchaseOrder-indicator-job {
    border-color: transparent;
  }
  .purchaseOrder-print .purchaseOrder-indicator-shop {
    border-color: orange;
  }
  .purchaseOrder-print .purchaseOrder-indicator-accounting {
    border-color: yellow;
  }
  .purchaseOrder-print .purchaseOrder-indicator-supplier {
    border-color: transparent;
  }
  .invoice-print .print-header {
    left: 0;
    max-width: 8rem;
    top: 0;
  }
  .invoice-print .invoice-indicator {
    left: 0;
  }
  .invoice-print .invoice-indicator-projectManager {
    border-color: orange;
  }
  .invoice-print .invoice-indicator-order {
    border-color: yellow;
  }
  .invoice-print .invoice-indicator-accounting {
    border-color: magenta;
  }
  .invoice-print .invoice-indicator-customer {
    border-color: transparent;
  }
  .invoice-print .invoice-indicator-field {
    border-color: orange;
  }
  .invoice-print .invoice-indicator-tagging {
    border-color: green;
  }
  .invoice-print .invoice-header tr {
    margin-top: 1rem;
  }
  .invoice-print .invoice-header .logo {
    display: inline-block;
    text-align: center;
    position: relative;
    top: -28px;
  }
  .invoice-print .invoice-header .logo img {
    max-width: 200px;
  }
  .invoice-print .invoice-meta {
    width: 100%;
  }
  .invoice-print .invoice-meta .print-header {
    display: block;
    position: relative;
    text-align: right;
    margin: 0 0 0.125in 0;
    width: auto;
    max-width: none;
  }
  .invoice-print .invoice-meta .print-header .print-header-indicator {
    display: block;
    position: relative;
    margin-top: 3px;
    float: right;
  }
  .invoice-print .invoice-meta .two-column {
    float: right;
  }
  .invoice-print .invoice-remit {
    display: block;
    font-size: 1em;
    font-weight: bold;
    margin-top: -5px;
  }
  .invoice-print .invoice-location {
    display: inline-block;
    font-size: 12px;
    padding-left: 20px;
    position: relative;
    margin-top: 50px;
  }
  .invoice-print .invoice-info td,
  .invoice-print .invoice-info th {
    font-size: 0.5rem;
    text-align: center;
    width: 16%;
  }
  .invoice-print .invoice-notes legend {
    margin-bottom: 0;
  }
  .invoice-print .invoice-address {
    margin-top: 1.25rem;
  }
  .invoice-print .invoice-details,
  .invoice-print .invoice-footer,
  .invoice-print .invoice-info,
  .invoice-print .invoice-notes {
    margin-top: 1rem;
  }
  .invoice-print .invoice-footer-disclaimer {
    font-size: 0.4rem;
    padding-right: 1rem;
  }
  .invoice-print .invoice-total {
    background-color: #000;
    color: #fff;
  }
  .jobList-print .jobList-header {
    font-size: 1.2rem;
    text-align: center;
  }
  .jobList-print .jobList-flag {
    font-size: 0.6rem;
    font-weight: bold;
  }
}
.report-sidebar-component {
  flex: 1 0 0;
}
.report-sidebar-component header h2 {
  margin-top: 0;
}
.report-sidebar-component .report-group-header {
  background-color: #476e7f;
  border-bottom: 1px solid #ddd;
  font-size: 0.8rem;
  font-weight: bold;
  margin: 0;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  text-transform: uppercase;
}
.report-sidebar-component .report-list {
  line-height: 1.2rem;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.report-sidebar-component .report-list li a {
  display: block;
  padding: 0.5rem;
  text-decoration: none;
  width: 100%;
}
.report-sidebar-component .report-list li a.active.report-active {
  background-color: #91AA9D;
}
.report-sidebar-component .report-list li:hover {
  background-color: #91AA9D;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
}
.report-controls {
  background-color: #D9D9E6;
  flex: 0 0 auto;
  margin: 0 -1rem;
  padding: 1rem 0.5rem;
}
.report-controls .job-selector {
  float: left;
  margin-right: 1rem;
  margin-bottom: 0;
  width: 400px;
}
.report-controls .job-selector .job-info {
  font-size: 0.6rem;
  font-weight: bold;
  line-height: 1.6rem;
}
.report-controls .job-selector .job-results {
  background-color: #fff;
  border: 1px solid #0A1419;
  position: absolute;
  top: 2.6rem;
  z-index: 5;
}
.report-controls .job-selector .job-results button {
  clear: both;
  float: right;
  margin-left: 1rem;
}
.report-controls .job-selector .job-results .job-results-item {
  border-bottom: solid 1px #193441;
  line-height: 1.5;
  padding: 0.25rem 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}
.report-controls .job-selector .job-results .job-results-item:last-child {
  border-bottom: none;
}
.report-controls .number-selector {
  display: inline-block;
  padding: 0 0.25rem;
  width: 150px;
}
.report-controls .number-selector .form-control {
  display: inline-block;
  width: 80px;
}
.report-controls .pm-selector {
  display: flex;
  align-items: center;
}
.report-controls .pm-selector label {
  margin-bottom: 0;
}
.report-controls input[type=date].form-control {
  max-width: 160px;
}
#reportPane {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 1rem 0rem -1rem 0rem;
  padding: 0 1rem;
  position: relative;
}
#reportPane table caption {
  color: #0A1419;
  font-weight: bold;
  font-size: 22px;
}
#reportPane table th {
  font-size: 16px;
}
#reportPane.dirty::after {
  background-color: rgba(217, 217, 230, 0.92);
  bottom: 0;
  content: 'Please re-run the report to see the most up-to-date data';
  font-size: 0.7rem;
  font-weight: bold;
  left: -1rem;
  padding-top: 1rem;
  position: absolute;
  right: -1rem;
  text-align: center;
  top: -0.5rem;
}
#reportPane .titleArea {
  line-height: 1.5;
}
#reportPane .titleArea .title {
  font-size: 18px;
  font-weight: bold;
}
#reportPane .noBorder {
  border: none;
}
#reportPane .noBorder td {
  border-top: none;
  width: 20%;
}
#reportPane .noBorder td:nth-child(1) {
  width: 40%;
}
#reportPane .noBorder td.underline {
  border-bottom: 1px solid #ddd;
}
#reportPane .noBorder tr:first-child td {
  padding-top: 24px;
}
#reportPane .total {
  font-size: 0.9rem;
}
.report-job-outcome-component #reportPane {
  font-size: 0.6rem;
}
.report-window-component .no-wrap {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
.report-window-component .table-subhead {
  color: #777;
  text-transform: uppercase;
  font-size: 80%;
}
.report-window-component .table-total {
  font-weight: bold;
  margin-top: 8px;
  text-transform: uppercase;
}
.report-window-component .bid-number {
  background-color: #efefef;
  color: #777;
  padding: 10px;
}
.report-window-component .bid-number.tight {
  padding: 0 10px;
}
.report-window-component .bid-number-spacer {
  background-color: transparent;
  color: inherit;
  padding: 10px 0;
}
.report-window-component .item-total {
  padding-right: 10px;
}
.report-job-detail-component .search-component .input-entry.actionable,
.report-job-summary-component .search-component .input-entry.actionable {
  margin-right: 3.5rem;
}
.report-job-detail-component {
  overflow: visible;
}
.report-job-detail-component .table > tbody > tr > td {
  line-height: 2;
}
.report-job-detail-component tr.caption td {
  text-transform: uppercase;
  font-size: 0.85rem;
}
.anchors {
  display: inline-block;
  text-align: right;
}
.anchors ul {
  list-style-type: none;
  padding: 0;
}
.anchors ul li {
  border-left: 1px solid #777;
  display: inline-block;
  padding: 0 5px;
  text-transform: none;
}
.anchors ul li:first-child {
  border: none;
}
.anchors ul li:nth-child(2) {
  border: none;
}
@media screen and (max-width: 1024px) {
  .outcome-report th,
  .outcome-report td {
    width: 7%;
    font-size: 0.45rem !important;
  }
  #reportPane .table td,
  .report-window-component .table td,
  #reportPane .table th,
  .report-window-component .table th {
    font-size: 0.5rem;
  }
}
.report-journal-cost-component .attached table tbody:hover td {
  background-color: #fff;
  transition: all 0.25s ease-in-out;
}
@media screen and (max-width: 991px) {
  .report-controls {
    padding: 1rem;
    margin: 0 -0.5rem;
  }
  .report-controls .number-selector {
    margin-bottom: 0.5rem;
  }
  .report-controls .number-selector:last-child {
    margin-bottom: 0;
  }
  .report-controls button {
    margin-top: 0.5rem;
  }
  .report-controls .search-component button {
    margin-top: 0;
  }
  .report-controls .input-group button {
    margin-top: 0;
  }
  #reportPane.dirty::after {
    left: -0.5rem;
    right: -0.5rem;
  }
  .labor-codes-component,
  .job-time-entry-component,
  .job-costs-component,
  .work-order-edit-component,
  .work-order-connection-component,
  .work-order-pm-notes-component,
  .work-order-parts-component,
  .purchase-order-edit-component,
  .purchase-order-connection-component,
  .purchase-order-invoice-connection-component,
  .purchase-order-payable-tab-component,
  .purchase-order-parts-component,
  .purchase-order-costs-component,
  .purchase-order-receipts-component,
  .report-job-list-component,
  .report-journal-cost-component,
  .report-time-entry-component,
  .report-job-outcome-component,
  .report-monthly-costs-component,
  .report-job-detail-component,
  .report-job-summary-component,
  .report-invoice-summary-component {
    padding: 0.5rem;
  }
  .report-journal-cost-component .report-controls input.form-control {
    margin-bottom: 0.5rem;
  }
  .report-job-outcome-component .report-controls .col-md-6 {
    margin-top: 0.5rem;
  }
}
@media screen and (max-width: 767px) {
  .report-job-list-component {
    padding: 0;
    margin: 0.5rem;
    width: calc(100vw - 1rem);
  }
  .report-job-list-component .report-controls {
    margin: 0;
  }
  .report-job-list-component .report-controls button {
    margin-top: 0.5rem;
  }
}
td.copy button {
  margin: -5px 0 0 0;
}
.invoice-flag {
  display: inline-block;
  width: auto;
  margin-right: 0.5rem;
}
.invoice-flag:last-child {
  margin-right: 0;
}
.invoice-amount {
  width: 6rem;
}
.tax-rate {
  float: left;
  width: 4rem;
}
.invoice-edit label {
  margin-top: 5px;
}
ul.dropdown-menu.openDropdown {
  display: block;
}
ul.dropdown-menu.openDropdown li {
  padding: 10px 5px;
  cursor: pointer;
}
ul.dropdown-menu.openDropdown li:hover {
  background-color: #286090;
  color: #fff;
  transition: all 0.25s ease-in-out;
}
.invoice-edit-component form table th {
  padding: 8px;
}
.receivable-amounts {
  float: right;
}
.receivable-amounts table tr td:first-child {
  text-align: right;
}
.invoice-search-component .check-col {
  width: 6%;
}
.invoice-search-component .invoice-col {
  width: 13%;
}
.invoice-search-component .date-col {
  width: 10%;
}
.invoice-search-component .customer-col {
  width: 18%;
}
.invoice-search-component .job-col {
  width: 16%;
}
.invoice-search-component .job-col-lg {
  width: 21%;
}
.invoice-search-component .status-col {
  width: 12%;
}
.invoice-search-component .amount-col {
  width: 15%;
}
.invoice-search-component .list-actions {
  width: 10%;
  padding-right: 0;
}
@media screen and (max-width: 767px) {
  .receivable-amounts {
    float: left;
  }
  .receivable-amounts table tr td:first-child {
    text-align: left;
  }
}
.purchase-order-component .print-menu-component .nav-tabs a {
  padding: 9px 15px 11px;
}
.purchase-order-invoice-connection-component .connection-section {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.purchase-order-invoice-connection-component .connection-section .name {
  width: 40%;
}
.purchase-order-invoice-connection-component .connection-section .connection-actions {
  text-align: right;
}
.purchase-order-costs-component .hide-for-po {
  display: none;
}
.purchase-order-receipts-component .receipt-actions {
  padding-bottom: 1rem;
}
.purchase-order-receipts-component .receipt-actions-date {
  float: left;
}
.purchase-order-receipts-component .receipt-actions-add {
  float: right;
}
.po-receipts-container tbody::after {
  content: "";
  display: block;
  height: 2rem;
}
.po-receipts-container .caption {
  font-weight: bold;
  padding: 0.5rem;
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px solid #ddd;
}
table .quantity {
  width: 40px;
}
table .unit {
  width: 48px;
}
table .recd {
  width: 86px;
}
table .location {
  width: 100px;
}
table .damaged {
  width: 100px;
}
table .buttons {
  width: 100px;
}
.purchaseOrder-cost tr {
  line-height: 1.5rem;
}
.wo-attachment-summary .date {
  display: block;
}
.wo-attachment-summary .number {
  font-weight: bold;
}
.po-cost-list-container tbody::after {
  content: "";
  display: block;
}
.documents {
  padding: 1rem;
}
input.form-control.combo-date {
  border-radius: 3px 0 0 3px;
  width: calc(100% - 35px);
}
.date-selector-overlay input[type="date"],
.date-selector-overlay .month-selector {
  background-color: #91AA9D;
  border: none;
  cursor: pointer;
  outline: none;
  position: absolute;
  right: 0;
  top: -34px;
  height: 34px;
  width: 3rem;
  -webkit-appearance: button;
}
.date-selector-overlay .month-selector {
  border: 1px solid #ccc;
  border-radius: 0 4px 4px 0;
  width: 1.5rem;
  -webkit-appearance: unset;
}
.date-selector-overlay .month-selector i {
  background-color: transparent;
  right: 14px;
  top: 3px;
}
.date-selector-overlay select::-ms-expand {
  display: none;
}
.date-selector-overlay i {
  background-color: #91AA9D;
  line-height: 1.2rem;
  padding-left: 0.6rem;
  pointer-events: none;
  position: absolute;
  right: 15px;
  top: -31px;
  width: 1rem;
}
.date-button .btn.btn-outline.btn-warning.active {
  color: #fff;
  background-color: #e89c30;
  border-color: #e89c30;
}
.mobile-button {
  display: none;
}
.desktop-button {
  display: block;
}
.purchase-order-component .print-menu-component {
  top: 0;
}
.purchase-order-component .print-menu-option {
  white-space: nowrap;
}
.purchase-order-search-component .batch-check {
  width: 4%;
}
.purchase-order-search-component .po-col {
  width: 7%;
}
.purchase-order-search-component .customer-col {
  width: 20%;
}
.purchase-order-search-component .pm-col {
  width: 15%;
}
.purchase-order-search-component .name-col {
  width: 15%;
}
.purchase-order-search-component .supplier-col {
  width: 15%;
}
.purchase-order-search-component .status-col {
  width: 10%;
}
.purchase-order-search-component .date-col {
  width: 10%;
}
.purchase-order-search-component .list-actions {
  width: 8%;
  padding-right: 0;
}
.purchase-order-search-component .is-admin .customer-col {
  width: 19%;
}
.purchase-order-search-component .is-admin .pm-col {
  width: 14%;
}
.purchase-order-search-component .is-admin .name-col {
  width: 14%;
}
.purchase-order-search-component .is-admin .supplier-col {
  width: 14%;
}
@media screen and (max-width: 1299px) {
  .purchase-order-component .nav-tabs li {
    font-size: 0.55rem;
  }
}
@media screen and (max-width: 1199px) {
  .mobile-button {
    display: block;
  }
  .desktop-button {
    display: none;
  }
}
@media screen and (min-width: 991px) and (max-width: 1024px) {
  .purchase-order-component .nav-tabs li {
    font-size: 0.4rem;
  }
}
@media screen and (max-width: 991px) {
  .po-receipts-add table thead th,
  .po-receipts-container table thead th {
    font-size: 0.4rem;
  }
  .date-selector-overlay input[type="date"] {
    top: -74px;
  }
  .date-selector-overlay i {
    top: -71px;
  }
  .date-selector-overlay .month-selector {
    top: -54px;
  }
  .purchase-order-search-component .po-col {
    width: 8%;
  }
  .purchase-order-search-component .customer-col {
    width: 17%;
  }
  .purchase-order-search-component .pm-col {
    width: 14%;
  }
  .purchase-order-search-component .name-col {
    width: 14%;
  }
  .purchase-order-search-component .supplier-col {
    width: 15%;
  }
  .purchase-order-search-component .status-col {
    width: 12%;
  }
  .purchase-order-search-component .date-col {
    width: 12%;
  }
  .purchase-order-search-component .list-actions {
    width: 8%;
  }
}
.purchase-order-payable-tab-component .connection-section {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.purchase-order-payable-tab-component .connection-section .amount,
.purchase-order-payable-tab-component .connection-section .connection-actions {
  text-align: right;
}
.purchase-order-payable-tab-component .connection-section .date,
.purchase-order-payable-tab-component .connection-section .number,
.purchase-order-payable-tab-component .connection-section .amount,
.purchase-order-payable-tab-component .connection-section .connection-actions {
  width: 25%;
}
.payable-search-component table {
  table-layout: fixed;
}
.payable-search-component #infinite-scroll-list th {
  white-space: normal;
  word-break: break-word;
}
.payable-search-component .check-col {
  width: 6%;
}
.payable-search-component .invoice-col {
  width: 10%;
}
.payable-search-component .job-col {
  width: 14%;
}
.payable-search-component .supplier-col {
  width: 13%;
}
.payable-search-component .status-col {
  width: 10%;
}
.payable-search-component .rec-col {
  width: 10%;
}
.payable-search-component .date-col {
  width: 10%;
}
.payable-search-component .posted-col {
  width: 10%;
}
.payable-search-component .amount-col {
  width: 10%;
}
.payable-search-component .list-actions {
  width: 7%;
  padding-right: 0;
}
.payable-search-component .infinite-scroll-list {
  white-space: normal;
}
@media screen and (max-width: 1199px) {
  .payable-search-component #infinite-scroll-list table th,
  .payable-search-component .infinite-scroll-list table th,
  .payable-search-component #infinite-scroll-list table td,
  .payable-search-component .infinite-scroll-list table td {
    font-size: 0.5rem;
  }
}
.work-order-connection-component {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.work-order-connection-component .searchbox {
  padding: 0 0 1rem 0;
}
.work-order-connection-component .connection-section {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.work-order-connection-component .connection-section td {
  text-align: left;
}
.work-order-connection-component .connection-section:first-child {
  margin-right: 0.5rem;
}
.work-order-connection-component .connection-section:last-child {
  margin-left: 0.5rem;
}
.work-order-connection-component .connection-section .date,
.work-order-connection-component .connection-section .number {
  white-space: nowrap;
}
.work-order-connection-component .connection-section .connection-actions {
  width: 220px;
}
.work-order-connection-component .connection-section td.actions {
  text-align: right;
}
.workorder-area .purchase-order .purchase-order-info {
  font-weight: bold;
}
.workorder-area .purchase-order .purchase-order-info-number {
  padding-right: 3rem;
}
.workorder-area .purchase-order .purchase-order-actions {
  float: right;
  margin-bottom: 10px;
}
.workorder-area .purchase-order .purchase-order-name {
  text-align: center;
  clear: both;
  padding: 0.3rem;
  border: 1px solid #777;
  background-color: rgba(255, 255, 255, 0.6);
}
.work-order-parts-component .table-select-list .select-all td {
  border: none;
}
.work-order-parts-component .table-select-list .select-all:hover td {
  background-color: transparent!important;
  transition: all 0.25s ease-in-out;
}
.work-order-search-component .wo-col {
  width: 9%;
}
.work-order-search-component .pm-col {
  width: 15%;
}
.work-order-search-component .name-col {
  width: 18%;
}
.work-order-search-component .cust-col {
  width: 15%;
}
.work-order-search-component .date-col {
  width: 12%;
}
.work-order-search-component .priority-col {
  width: 10%;
}
.work-order-search-component .status-col {
  width: 12%;
}
.work-order-search-component .list-actions {
  width: 8%;
}
.purchase-order-connection-component .connection-section {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.purchase-order-connection-component .connection-section .connection-actions {
  text-align: right;
}
.purchase-order-connection-component .connection-section .date,
.purchase-order-connection-component .connection-section .number,
.purchase-order-connection-component .connection-section .name,
.purchase-order-connection-component .connection-section .connection-actions {
  width: 25%;
}
@media screen and (max-width: 991px) {
  .work-order-connection-component .actions .input-group {
    margin-bottom: 0.5rem;
  }
  .workorder-area .purchase-order .purchase-order-info-number {
    padding-right: 1rem;
  }
  .workorder-area .purchase-order .purchase-order-actions {
    margin-bottom: 0.5rem;
  }
  .workorder-area table {
    font-size: 0.5rem;
  }
}
.job-search-component div.row:last-child {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.job-search-component .container {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.job-search-component .incomplete::after {
  background-color: rgba(217, 217, 230, 0.8);
  bottom: -0.5rem;
  content: 'Please select a job';
  font-size: 0.8rem;
  font-weight: bold;
  left: 0;
  padding-top: 1rem;
  position: absolute;
  right: 0;
  text-align: center;
  top: -0.5rem;
  z-index: 3;
}
.job-search-component .time-entry-job-selector {
  margin-top: 1rem;
}
.job-search-component .time-entry-job-selector .time-entry-job-info {
  font-size: 0.9rem;
  font-weight: bold;
  line-height: 2.5rem;
}
.job-search-component .time-entry-job-selector .time-entry-job-results {
  background-color: #fff;
  border: 1px 1px 0 1px solid #0A1419;
  position: absolute;
  top: 2rem;
  left: 123px;
  z-index: 20;
}
.job-search-component .time-entry-job-selector .time-entry-job-results button {
  clear: both;
  float: right;
  margin-left: 1rem;
}
.job-search-component .time-entry-job-selector .time-entry-job-results .time-entry-job-results-item {
  border-bottom: solid 1px #193441;
  line-height: 1.5;
  padding: 0.25rem 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}
.job-search-component .time-entry-form {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
}
.job-search-component .time-entry-form label {
  font-weight: bold;
}
.job-search-component .time-entry-form > div {
  padding: 0 0.25rem;
}
.job-search-component .time-entry-form > div:first-child {
  padding-left: 0;
}
.job-search-component .time-entry-form > div:last-child {
  padding-right: 0;
}
.job-search-component .time-entry-form-date {
  flex: 0 0 200px;
}
.job-search-component .time-entry-form-staff {
  flex: 1;
}
.job-search-component .time-entry-form-rate {
  flex: 0 0 160px;
  text-align: right;
}
.job-search-component .time-entry-form-hours {
  flex: 0 0 160px;
  text-align: right;
}
.job-search-component .time-entry-form-total {
  flex: 0 0 160px;
  text-align: right;
}
.job-search-component .time-entry-form-actions {
  flex: 0 0 80px;
}
.job-search-component .time-entry-list-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 1rem;
}
.new-entry {
  border-bottom: 1px solid #3E606F;
}
.new-entry:nth-child(odd) {
  background-color: #fff;
}
.width-10 {
  width: 10%;
}
.width-15 {
  width: 15%;
}
.width-20 {
  width: 20%;
}
.width-40 {
  width: 40%;
}
.job-search-component input.unmatched,
.time-entry-component input.unmatched,
.job-time-entry-component input.unmatched {
  background-color: #C3FCFF;
  border-top: 2px solid #5AC6CC;
  border-right: 2px solid #5AC6CC;
  border-bottom: 2px solid #5AC6CC;
}
section.time-entry .new-entry input {
  margin-bottom: 20px;
}
section.time-entry .attached .checkbox-column {
  margin-left: 24px;
  width: 36px;
  float: left;
}
section.time-entry .attached .data-entry-column {
  float: left;
  width: calc(100% - 90px);
}
section.time-entry .attached .data-entry-column input,
section.time-entry .attached .data-entry-column .input-group,
section.time-entry .attached .data-entry-column select {
  margin-bottom: 20px;
}
section.time-entry .attached table tbody:hover {
  background-color: #fff;
  transition: all 0.25s ease-in-out;
}
section.time-entry .checkbox-container {
  width: 3%;
}
section.time-entry .date-staff {
  width: 20%;
}
section.time-entry .hours {
  width: 11%;
}
section.time-entry .rate {
  width: 9%;
}
section.time-entry .total {
  width: 10%;
}
section.time-entry .buttons {
  width: 27%;
}
section.time-entry .edit-view .date-staff {
  width: 25%;
}
section.time-entry .edit-view .hours {
  width: 15%;
}
section.time-entry .edit-view .rate {
  width: 10%;
}
section.time-entry .edit-view .rate .input-group {
  width: 100px;
}
section.time-entry .edit-view textarea {
  border-radius: 4px;
  width: 100%;
}
section.time-entry .edit-view .no-padding {
  padding-right: 0;
}
section.time-entry .table-select-list th {
  padding: 2px 8px 8px;
}
section.time-entry .table-select-list th input[type=checkbox] {
  height: 20px;
  width: 20px;
  position: relative;
  top: 5px;
  left: 1px;
}
section.time-entry .table-select-list td.checkbox-container input[type=checkbox],
section.time-entry .table-select-list div.checkbox-container input[type=checkbox] {
  height: 20px;
  width: 20px;
  position: relative;
  top: -3px;
}
.job-search {
  margin-bottom: 4rem;
}
@media screen and (max-width: 1199) {
  section.time-entry .edit-view .no-padding {
    padding-right: 15px;
  }
}
@media screen and (max-width: 991px) {
  section.time-entry .date-staff.staff-member {
    width: 50%;
  }
  section.time-entry table.table.table-select-list tbody tr td {
    font-size: 0.5rem;
  }
  section.time-entry table.table.table-select-list tbody tr td .entry-label {
    font-size: 0.4rem;
  }
}
.costs-component div.row:last-child {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.costs-component .container {
  display: flex;
  flex-direction: column;
  flex: 1;
  display: inline-block;
}
.costs-component .incomplete::after {
  background-color: rgba(217, 217, 230, 0.8);
  bottom: -0.5rem;
  content: 'Please select a job';
  font-size: 0.8rem;
  font-weight: bold;
  left: 0;
  padding-top: 1rem;
  position: absolute;
  right: 0;
  text-align: center;
  top: -0.5rem;
  z-index: 3;
}
.costs-component .costs-job-selector .costs-job-info {
  font-size: 0.9rem;
  font-weight: bold;
  line-height: 2.5rem;
}
.costs-component .costs-job-selector .costs-job-results {
  background-color: #fff;
  border: 1px solid #0A1419;
  position: absolute;
  top: 1.9rem;
  left: 125px;
  z-index: 5;
}
.costs-component .costs-job-selector .costs-job-results button {
  clear: both;
  float: right;
  margin-left: 1rem;
}
.costs-component .costs-job-selector .costs-job-results .costs-job-results-item {
  border-bottom: solid 1px #193441;
  line-height: 1.5;
  padding: 0.25rem 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}
.costs-component .costs-job-selector .costs-job-results .costs-job-results-item:last-child {
  border-bottom: none;
}
.costs-component .costs-list-container {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
}
.costs-list-component {
  margin-top: 1rem;
}
.costs-list-component .type-controls button.active {
  pointer-events: none;
}
.costs-list-component .costs-type-required {
  border-left: 5px solid #F25050;
  border-radius: 3px 0 0 3px;
  float: left;
  height: 34px;
}
.costs-list-component .costs-type-required.valid {
  border-left: 5px solid #08910B;
}
.costs-list-component .costs-total {
  font-weight: bold;
  line-height: 1.4rem !important;
}
.costs-list-component label {
  display: block;
  font-weight: bold;
  font-size: 0.5rem;
}
.costs-list-component .po-cost-list-container table thead th {
  padding: 2px 8px 8px;
}
.costs-list-component .po-cost-list-container tbody:hover {
  background-color: #fff;
  transition: all 0.25s ease-in-out;
  cursor: pointer;
}
@media screen and (max-width: 991px) {
  .costs-list-component table.table.table-select-list tbody tr td {
    font-size: 0.5rem;
  }
  .costs-list-component table.table.table-select-list tbody tr td .entry-label {
    font-size: 0.4rem;
  }
}
.costs-list-container .costs-list-component .attach-po {
  display: none;
}
#filter-entries {
  margin: 0;
}
#filter-entries .sort-container {
  display: inline-block;
}
#filter-entries label {
  display: block;
  font-weight: bold;
  font-size: 0.5rem;
}
#filter-entries .ng-select-component {
  display: inline-block;
  min-width: 100%;
}
#filter-entries .ng-select-component .glyphicon.glyphicon-remove {
  position: absolute;
  top: 9px;
  right: 20px;
}
#filter-entries .input-group .form-control:first-child {
  border-radius: 4px;
}
#filter-entries .input-group .form-control:last-child {
  border-radius: 4px;
}
#filter-entries input[type="date"].form-control {
  line-height: 1!important;
}
.filter-row {
  display: flex;
  align-items: flex-start;
  margin-bottom: 12px;
}
.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}
.pseudo-link {
  color: #507c90;
  cursor: pointer;
  text-transform: uppercase;
}
.pseudo-link.clear {
  color: #F25050;
}
.pseudo-link.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}
.action-link {
  color: #507c90;
  cursor: pointer;
  text-decoration: none;
}
.action-link.delete {
  color: #F25050;
}
.small-label {
  color: inherit;
  font-size: 0.5rem;
  text-transform: uppercase;
}
.add-on.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}
.clearfix {
  clear: both;
}
.clearfix:before {
  clear: both;
}
.clearfix:after {
  clear: both;
}
.pointer {
  cursor: pointer;
}
.capitalize {
  text-transform: capitalize !important;
}
.click-input {
  color: #777;
  cursor: pointer;
  font-family: 'Noto Sans', Tahoma, sans-serif;
}
.text-divider {
  margin: 0 0.5rem;
}
.toggle-control {
  cursor: pointer;
}
.inline-block {
  display: inline-block !important;
}
.line-break {
  white-space: pre-line;
}
ul.unstyled {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
select.unstyled {
  border: none;
  background-color: #F6F6FF;
  font-weight: bold;
  font-size: 0.6rem;
}
.font-normal {
  font-weight: normal!important;
}
.font-bold {
  font-weight: bold!important;
}
.hr {
  border-bottom: 1px solid #ddd;
  width: 100%;
}
.border-top {
  border-top: 1px solid #ddd;
}
.border-right {
  border-right: 1px solid #ddd;
}
.border-bottom {
  border-bottom: 1px solid #ddd;
}
.border-left {
  border-left: 1px solid #ddd;
}
.border-left-md {
  border-left: 1px solid #ddd;
}
@media screen and (max-width: 767px) {
  .border-left-md {
    border-left: none;
  }
}
.shaded {
  background-color: #C0C0C0;
}
.shaded th,
.shaded td {
  border-bottom: none!important;
}
.optional-tag {
  float: right;
  font-size: 0.5rem;
  font-style: italic;
  font-weight: normal;
  color: #777;
  padding-right: 4px;
}
.error {
  border: 1px solid #F25050 !important;
  border-radius: 4px;
}
.error-msg {
  color: #F25050;
}
.entry-label {
  font-size: 0.5rem;
  text-transform: uppercase;
}
.x-small {
  font-size: 75%;
}
.unsaved-change {
  color: #e89c30;
  font-size: 75%;
  margin-top: 5px;
  text-align: right;
}
.unsaved-change .glyphicon-exclamation-sign {
  margin-right: 4px;
  position: relative;
  top: 2px;
}
table.no-border tbody tr td,
table.no-border thead tr td,
table.no-border tbody tr th,
table.no-border thead tr th {
  border: 0;
}
table.no-hover tbody tr:hover td {
  background-color: transparent;
}
table.border-right td {
  border-right: 1px solid #ddd;
}
table.border-right td:last-child {
  border-right: none;
}
.toggle {
  font-size: 0.6rem;
  color: #777;
  cursor: pointer;
  display: inline-block;
}
.toggle .toggle-icon {
  border: 1px solid #777;
  border-radius: 3px;
  display: inline-block;
  font-size: 14px;
  line-height: 1;
  padding: 1px 2px 2px;
  text-align: center;
  width: 18px;
}
.add-on {
  font-size: 0.6rem;
  font-weight: normal;
  color: #507c90;
  cursor: pointer;
  display: inline-block;
}
.add-on .add-icon {
  border: 1px solid #507c90;
  border-radius: 12px;
  display: inline-block;
  padding: 1px 4px 2px 5px;
  line-height: 1;
  color: #507c90;
  margin-right: 5px;
  transition: all 0.25s ease-in-out;
}
.add-on:hover {
  text-decoration: none;
}
.add-on:hover .add-icon {
  background-color: #507c90;
  color: #fff;
  transition: all 0.25s ease-in-out;
}
.mt-0 {
  margin-top: 0!important;
}
.mt-1 {
  margin-top: 0.5rem !important;
}
.mt-2 {
  margin-top: 1rem!important;
}
.mt-3 {
  margin-top: 1.5rem !important;
}
.mb-0 {
  margin-bottom: 0!important;
}
.mb-1 {
  margin-bottom: 0.5rem !important;
}
.mb-2 {
  margin-bottom: 1rem!important;
}
.mb-3 {
  margin-bottom: 1.5rem !important;
}
.ml-0 {
  margin-left: 0!important;
}
.ml-1 {
  margin-left: 0.5rem !important;
}
.ml-2 {
  margin-left: 1rem!important;
}
.ml-3 {
  margin-left: 1.5rem !important;
}
.mr-0 {
  margin-right: 0!important;
}
.mr-1 {
  margin-right: 0.5rem !important;
}
.mr-2 {
  margin-right: 1rem!important;
}
.mr-3 {
  margin-right: 1.5rem !important;
}
.pt-0 {
  padding-top: 0!important;
}
.pt-1 {
  padding-top: 0.5rem !important;
}
.pt-2 {
  padding-top: 1rem!important;
}
.pt-3 {
  padding-top: 1.5rem !important;
}
.pb-0 {
  padding-bottom: 0!important;
}
.pb-1 {
  padding-bottom: 0.5rem !important;
}
.pb-2 {
  padding-bottom: 1rem!important;
}
.pb-3 {
  padding-bottom: 1.5rem !important;
}
.pl-0 {
  padding-left: 0!important;
}
.pl-1 {
  padding-left: 0.5rem !important;
}
.pl-2 {
  padding-left: 1rem!important;
}
.pl-3 {
  padding-left: 1.5rem !important;
}
.pr-0 {
  padding-right: 0!important;
}
.pr-1 {
  padding-right: 0.5rem !important;
}
.pr-2 {
  padding-right: 1rem!important;
}
.pr-3 {
  padding-right: 1.5rem !important;
}
/* Modal window */
.shared-modal-component .backdrop {
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 100;
  display: none;
}
.shared-modal-component .modal-dialog {
  max-width: 55%;
  width: auto;
}
.shared-modal-component .modal-dialog .close {
  font-size: 40px;
}
.shared-modal-component .modal-dialog .modal-header {
  padding: 15px 15px 0;
  border: none;
}
.shared-modal-component .modal-dialog .modal-header .modal-title {
  float: left;
  padding-top: 2px;
  font-size: 1rem;
}
.shared-modal-component .modal-dialog .modal-body input[type=checkbox],
.shared-modal-component .modal-dialog .modal-body input[type=radio] {
  position: relative;
  top: 2px;
  margin-right: 5px;
}
.shared-modal-component .modal-dialog .modal-body .form-horizontal .control-label {
  text-align: left;
}
.shared-modal-component .modal-dialog .modal-footer {
  border: none;
  padding: 0;
}
.glyphicon.glyphicon-minus-sign {
  color: #F25050;
}
.x-small {
  font-size: 75%;
}
.user-list-component,
.staff-list-component {
  font-size: 15px;
}
.user-list-component .staff-list-item td.muted,
.staff-list-component .staff-list-item td.muted {
  color: #C0C0C0;
}
.user-list-component .staff-list-item.disabled,
.staff-list-component .staff-list-item.disabled {
  opacity: 1;
}
table thead.column-spacer {
  max-height: 0;
}
table thead.column-spacer th {
  padding: 0;
}
.sr-only {
  position: absolute;
  left: -99999px;
  visibility: hidden;
}
nav.export {
  cursor: pointer;
}
nav.export ul.nav {
  border: none;
}
nav.export ul.nav .export-toggle span {
  color: #cabd64;
  text-transform: uppercase;
}
nav.export ul.nav .export-toggle ul li {
  padding: 0.25rem;
}
nav.export ul.nav .export-toggle ul li button {
  margin-right: 0.5rem;
}
nav.export ul.nav .export-toggle ul li.print-menu-execute button {
  width: 100%;
}
#exportPO {
  border: none;
  display: none;
}
#exportPO.viewPO {
  display: block;
}
#exportPO.viewPO .exported-po {
  background-color: #fff;
  font-size: 0.6rem !important;
  max-height: 11in;
  min-height: 11in;
  left: 50%;
  padding: 0.375in;
  position: relative;
  transform: translateX(-50%);
  width: 8.5in;
}
#exportPO.viewPO .exported-po .export-header {
  font-size: 0.7rem;
  font-weight: bold;
  position: absolute;
  right: 0.375in;
  top: 0;
}
#exportPO.viewPO .exported-po .header-indicator {
  border-style: solid;
  border-width: 0.25rem 1rem;
  position: absolute;
  height: 0.5rem;
  width: 2rem;
  right: 0;
}
#move-entry {
  margin: 0;
}
#move-entry label {
  display: block;
  font-weight: bold;
  font-size: 0.5rem;
}
#move-entry .ng-select-component {
  display: inline-block;
  min-width: 100%;
}
#move-entry .ng-select-component .glyphicon.glyphicon-remove {
  position: absolute;
  top: 9px;
  right: 20px;
}
#move-entry .input-group .form-control:first-child {
  border-radius: 4px;
}
#move-entry .input-group .form-control:last-child {
  border-radius: 4px;
}
#move-entry input[type="date"].form-control {
  line-height: 1!important;
}
#move-entry .disabled {
  color: #777;
  cursor: not-allowed;
  pointer-events: none;
}
#move-entry .margin-fix-1 {
  margin-top: 21px;
}
.entry-controls .toggle-control .glyphicon {
  font-size: 22px;
  top: 5px;
}
.entry-controls .disabled {
  color: #777;
  cursor: not-allowed;
  pointer-events: none;
}
.entry-controls .btn.disabled {
  color: #fff;
}
.labor-codes-component table tr.new-entry:nth-child(odd) {
  background-color: transparent;
}
.labor-codes-component table tr.new-entry:nth-child(even) {
  background-color: #fff;
}
.labor-codes-component table tr.new-entry td {
  padding: 0.3rem 0.5rem 0.5rem;
}
.labor-codes-component .checkbox-container {
  width: 3%;
}
.labor-codes-component .code-id {
  width: 20%;
}
.labor-codes-component .code-name {
  width: 20%;
}
.labor-codes-component .code-title {
  width: 40%;
}
.labor-codes-component .buttons {
  width: 17%;
  min-width: 100px;
  text-align: right;
}
.labor-codes-component .buttons .save {
  position: relative;
  top: 6px;
}
.labor-codes-component .attached table tr td {
  vertical-align: middle;
}
.labor-codes-component .attached table tr td.buttons {
  width: auto;
}
.labor-codes-component .attached table tr:hover td {
  background-color: #fff;
  transition: all 0.25s ease-in-out;
}
@media screen and (max-width: 991px) {
  .labor-codes-component section.labor-codes table.table.table-select-list tbody tr td {
    font-size: 0.5rem;
  }
  .labor-codes-component section.labor-codes table.table.table-select-list tbody tr td .entry-label {
    font-size: 0.4rem;
  }
}
.dashboard .tools,
.dashboard .message {
  align-self: flex-end;
}
.dashboard .tools h1,
.dashboard .message h1 {
  font-family: 'Noto Sans', Tahoma, sans-serif;
  font-size: 1rem;
  margin: 0;
}
.dashboard .entity-row {
  display: flex;
  flex-wrap: wrap;
}
.dashboard .entity-container {
  flex: 1 0 50%;
  max-width: 50%;
}
.dashboard .card {
  background-color: #fff;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
}
.dashboard .card .total {
  font-size: 0.834rem;
  text-align: center;
}
.dashboard .card .total .number {
  color: #F25050;
  font-size: 2.5rem;
}
.dashboard .card.entity-summary {
  cursor: pointer;
}
.dashboard .card.entity-summary .title {
  font-size: 1rem;
  line-height: 1;
}
.dashboard .card.entity-summary .title .entity-label {
  text-transform: lowercase;
}
.dashboard .card.entity-summary .item {
  color: #C0C0C0;
  border-right: 1px solid #C0C0C0;
  display: inline-block;
  font-size: 0.5rem;
  padding: 0 0.5rem 0 0;
  margin-right: 0.5rem;
}
.dashboard .card.entity-summary .item:last-child {
  border-right: none;
}
.dashboard .card.entity-summary .item .pseudo-link {
  color: #C0C0C0;
  text-transform: lowercase;
}
.dashboard .card.entity-full {
  margin-bottom: 0;
  margin-top: 1rem;
}
.dashboard .card.entity-full .entity-color {
  display: inline-block;
  height: 10px;
  width: 10px;
  margin-right: 5px;
}
.dashboard .card.entity-full .entity-color.entity-type1 {
  background-color: #8dbb8d;
}
.dashboard .card.entity-full .entity-color.entity-type2 {
  background-color: #a471ab;
}
.dashboard .card.entity-full .entity-color.entity-type3 {
  background-color: #cabd64;
}
.dashboard .card.entity-full .entity-color.entity-type4 {
  background-color: #6daaec;
}
.dashboard .card.entity-full .secondary-label {
  color: #C0C0C0;
  font-size: 0.5rem;
  font-style: italic;
}
.dashboard .card.entity-full .entity-header {
  border-left: 4px solid #fff;
  border-bottom: 4px solid #F6F6FF;
  font-size: 0.5rem;
  padding-bottom: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  text-transform: uppercase;
}
.dashboard .card.entity-full .item {
  border-left: 4px solid #fff;
  cursor: pointer;
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
  transition: all 0.25s ease-in-out;
}
.dashboard .card.entity-full .item .item-status {
  font-size: 10px;
  text-transform: uppercase;
}
.dashboard .card.entity-full .item a {
  text-transform: uppercase;
}
.dashboard .card.entity-full .item:hover {
  background-color: #F6F6FF;
  transition: all 0.25s ease-in-out;
}
.dashboard .card.entity-full .item .glyphicon-time {
  display: none;
  color: #F25050;
  position: absolute;
  left: -17px;
  top: 3px;
}
.dashboard .card.entity-full .item.late {
  border-left: 4px solid #F25050;
}
.dashboard .card.entity-full .item.late .glyphicon-time {
  display: block;
}
.dashboard .card.entity-full .item div {
  overflow: hidden;
  text-overflow: ellipsis;
}
.dashboard .card.entity-full .nav-link {
  font-size: 11px;
  text-transform: uppercase;
}
@media screen and (max-width: 767px) {
  .dashboard .add-on {
    margin-top: 18px;
  }
}
@media (max-width: 991px) {
  .dashboard .entity-row {
    display: block;
  }
  .dashboard .entity-container {
    max-width: 100%;
  }
}
.pagination-component .go-to-page-container {
  align-items: center;
  display: inline-flex;
  margin-left: 1rem;
}
.pagination-component .go-to-page-container label {
  display: inline-flex;
  font-weight: bold;
  font-size: 0.5rem;
}
.pagination-component .go-to-page-container .go-to-page {
  display: inline-flex;
  max-width: 66px;
  margin-left: 5px;
  padding: 5px;
  width: auto;
  text-align: right;
}
.pagination-component .pagination-container {
  display: inline-flex;
  margin-bottom: 0.5rem;
}
.pagination-component .pagination-container ul.pagination {
  margin: 4px 0 0;
}
.pagination-component .pagination-container ul.pagination > li {
  font-size: 0.6rem;
}
.pagination-component .pagination-container ul.pagination > li > a,
.pagination-component .pagination-container ul.pagination > li > span {
  border-color: #ccc;
  color: #507c90;
}
.pagination-component .pagination-container ul.pagination > li.active > a {
  color: #fff;
}
@media (max-width: 991px) {
  .pagination-component .pagination-container {
    display: block;
    margin-top: 0;
    text-align: left;
  }
  .pagination-component .pagination-container .go-to-page-container {
    display: block;
    margin: 0.5rem 0 0;
  }
}
.app-version {
  padding: 2px 15px;
  background-color: #efefef;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
}
.app-version__text {
  color: #787878;
  font-size: 0.9em;
  font-style: italic;
  text-align: right;
}
.app-version__notice {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 250px;
  margin: 0 auto;
  padding: 10px 15px;
  background-color: hsl(35.2, 80%, 89.9%);
  border-color: #e89c30;
  border-style: solid;
  border-width: 1px 1px 0 1px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 10000;
}
.app-version-menu-item .app-version {
  background-color: transparent;
}
.app-version-menu-item .app-version .app-version__text {
  color: #fff;
  font-style: normal;
  font-size: inherit;
  text-align: center;
}
header .app-version__notice,
footer .app-version__text {
  display: none!important;
}
