@import '_variables';
@import '_mixins';

.table-select-list(@bgColor:@white, @textColor:@color-text, @hoverColor:lighten(@bgColor, 5), @activeColor:lighten(@bgColor, 10) )
{
    /*@bgColor:@white;
	@textColor:@color-text;
	@hoverColor:lighten(@bgColor, 5);
	@activeColor:lighten(@bgColor, 10);*/

    tbody tr
    {
        position: relative;

        cursor: pointer;

        &:hover
        {
            td,
            th
            {
                background-color: @hoverColor;
                transition: @gobal-transition;
            }
        }

        &.active
        {
            td,
            th
            {
                background-color: @activeColor;
            }
        }

        &.no-hover
        {
            cursor: default;

            &:hover
            {
                background-color: transparent;
            }
        }
    }

    .select-list-toggle
    {
        width: 70px;
    }

}

.table-select-list
{
    .table-select-list();
}

.table-select-body
{
    tbody:hover
    {
        tr td
        {
            background-color: #fff;
            transition: @gobal-transition;
        }
    }
}

.sidebar .table-select-list
{
    .table-select-list(@sidebar-bg);

    //special style overrides when select list is in sidebar

    td
    {
        border-top: 1px solid rgba(255, 255, 255, .2);
    }
}



.attached
{
    table
    {
        margin: 0;

        thead th
        {
            border:0;
        }
    }
}

