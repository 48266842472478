@import '_variables';

.app-version {
	padding: 2px 15px;
	background-color: @gray-lighter;
	box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
}

.app-version__text {
	color: rgb(120, 120, 120);
	font-size: 0.9em;
	font-style: italic;
	text-align: right;
}

.app-version__notice {
	position: fixed;
	right: 0;
	bottom: 0;
	left: 0;
	max-width: 250px;
	margin: 0 auto;
	padding: 10px 15px;
	background-color: lighten(hsla(35.2, 80%, 54.9%, 1), 35%);
	border-color: @alert-color;
	border-style: solid;
	border-width: 1px 1px 0 1px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	z-index:10000;
}
.app-version-menu-item .app-version {
	background-color: transparent;
	.app-version__text {
		color:@white-pure;
		font-style: normal;
		font-size: inherit;
		text-align: center;
	}
}
header .app-version__notice,
footer .app-version__text {
	display:none!important;
}