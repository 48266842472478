@import "_variables";

.account-container {
	float:right;
	padding-top:12px;
	text-align:right;
	max-width:380px;
	#account-actions {
		display:block;
		font-size: 13px;
		line-height: 1;
	}
	.account-info-component {
		display:flex;
		align-items: center;
		#account-action {
			display: inline-block;
			padding: 0 0.5rem;
		}
	
		#logout {
			cursor:pointer;
		}
		.location-select {
			appearance: initial;
			-webkit-appearance: initial;
			background-color:@dark;
			border:none;
			color:@white;
			padding:0 10px;
			&:focus {
				border:none;
				outline: none;
			}
			option:hover {
				background-color: #275166;
			}
			a#location-select {
				display: block;
				line-height:13px;
				min-width: 140px;
				text-align: center;
			}
			ul.dropdown-menu {
				left:0;
				top:32px;
				position: absolute;
				a {
					cursor:pointer;
				}
			}
		}
		.account-dropdown {
			margin-top:1px;
			@media (min-width: 768px) and (max-width:991px) {
				max-width:132px;
				padding-right:10px;
				a {
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
			ul.dropdown-menu {
				right:0;
				top:32px;
				position: absolute;
			}
		}
		.location-select,
		.account-dropdown {
			position: relative;
			a#location-select,
			a#account-actions {
				font-size:.585rem;
				&:after {
					content: '';
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 7px 7px 0 7px;
					border-color: #ffffff transparent transparent transparent;
					position: absolute;
					left: calc(50% - 7px);
					bottom:-15px;
				}
			}
			a#account-actions {
				&:after {
					bottom:-14px;
				}
			}
		}
	}
}
