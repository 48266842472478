@import "_variables";
@import "_mixins";


#filter-entries{
    margin:0;
    .sort-container {
        display: inline-block;
    }
    label {
        display:block;
        font-weight: @font-bold;
        font-size:.5rem;
    }
    .ng-select-component {
        display: inline-block;
        min-width: 100%;
        .glyphicon.glyphicon-remove {
            position: absolute;
            top:9px;
            right:20px;
        }
    }
    .input-group {
        .form-control {
            &:first-child {
                border-radius: @global-border-radius
            }
            &:last-child {
                border-radius: @global-border-radius
            }
        }
    }
    input[type="date"].form-control {
        line-height:1!important;
    }
}
.filter-row {
    display:flex;
    align-items:flex-start;
    margin-bottom:12px;
}
