@import "_variables";

#location-list
{
	margin:2rem 0;

	li
	{
		cursor:pointer;
		padding:1rem 0;
		&:hover
		{
			background-color:lighten(@body-bg, 5);
			transition: @gobal-transition;
		}
	}
}

.list-item {
	&-date {
		width: 5rem;
	}

	&-number {
		width: 3.5rem;
	}

	&-code {
		width: 6rem;
	}
}

.list-item-group {
	background-color:darken(@body-bg, 3);
	border-style: solid;
	border-width: 2px 0;
}

.list-item-subitem {
	padding-left: 1rem !important;
}

.list-total {
	border: 1px solid @green;
	border-radius:@global-border-radius; 
	color:@black;
	float:right;
	font-size:.575rem;
	font-weight: 600;
	margin:0;
	padding:10px 20px;
	position: relative;
	text-align:center;
	span {
		margin-left:40px;
	}
	&:after {
		content:'';
		position:absolute;
		right: 32px;
	    bottom: -6px;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 5px 5px 0 5px;
		border-color: @green transparent transparent transparent;
	}
}
thead.spacer {
	height: 0;
	th, td {
		border: none !important;
		margin: 0 !important;
		padding: 0 !important;
	}
}

td.list-item-flag, th.list-item-flag {
	padding-left: 0 !important;
	padding-right: 0 !important;
	width: 1px;
}
table tbody,
table thead {
	th, td {
		&.center-vertical {
			vertical-align: middle;
		}
	}
	th, td {
		&.top-vertical {
			vertical-align: top;
		}
	}
}

#infinite-scroll-list, /* for targeting the list header */
.infinite-scroll-list { /* the list of data */
	table th,
	table td {
		font-size:.575rem;
		font-weight:400;
		@media (max-width:991px) {
			font-size: .5rem;
			&.list-actions .btn {
				padding:6px;
				font-size:.5rem;
			}
		}

		&.center-vertical {
			vertical-align: middle;
		}
	}
	th, td {
		&.top-vertical {
			vertical-align: top;
		}
	}

	table thead tr th {
		padding:0 8px 7px;
	}
	table tr:first-child td {
		border-top:0;
	}
}
#infinite-scroll-list {
	padding-top:12px;
}
.infinite-scroll-list {
	table thead tr th {
		padding:0 8px;
	}
}
.sortable-header-component,
.infinite-scroll-header-component.sortable {
	cursor: pointer;

	div {
		color: lighten(@aqua-deep,10);
		display: inline-block;
		font-weight:400;

		&.active {
			color: @aqua-deep;
			position: relative;
			.glyphicon {
				position: absolute;
				right: auto;
				left: -17px;
				top: 4px;
			}
		}
	}
}
.log-note {
	padding: 5px 0;
	border-bottom: 1px solid @border-color;
	&:last-child {
		border-bottom:none;
	}
}
.pm-name {
	color:@dark-gray;
	font-size:.5rem;
	text-transform: uppercase;
}
/* dragula drag-n-drop module
/* in-flight clone */
.draggable {
	li {
		cursor: grab;
		padding:.5rem 0;
		.glyphicon-option-vertical {
			font-size: .8rem;
			color: #ccc;
			padding: 0;
		}
	}
}
.gu-mirror {
	box-shadow: 2px 5px 10px #000;
	position: fixed !important;
	margin: 0 !important;
	padding:.5rem 0;
	z-index: 9999 !important;
	pointer-events: none;
	list-style-type: none;
	background-color:@white;
	opacity:1;
	.glyphicon-option-vertical {
		color:@input-border;
		top:7px;
	}
	.glyphicon-move {
		font-size: 1rem;
		color: #ccc;
		padding: 0;
		left: -8px;
		position:relative;
		top:6px;
	}
	a {
		position:relative;
		top:6px;
		color:@gray!important;
		text-decoration: underline;
	}
	span.afs {
		position: relative;
		top:6px;
	}
}
/* high-performance display:none; helper */
.gu-hide {
	left: -9999px !important;
}
/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
	-webkit-user-select: none !important;
	-moz-user-select: none !important;
	-ms-user-select: none !important;
	user-select: none !important;
}
/* added to the source element while its mirror is dragged */
.gu-transit {
	opacity: 0.2;
}

