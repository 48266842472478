@import "_mixins";

@label-width:75px;

.customer-list-component {
	div.container, .customer-list-container, .customer-list-column {
		.scrollable-container();
	}

	.no-results {
		color: #888;
		font-size: 1rem;
		padding: 0.5rem;
		text-align: center;
	}
}

.customer-summary-component,.supplier-summary-component {
	background-color: @sidebar-header-bg;
	flex:1 0 0;
}

#customer-summary,#supplier-summary
{
	padding:1rem 1rem 0;

	h2 { margin-top:0; }

	.customer-name,.supplier-name
	{
		margin-bottom:1rem;
	}

	dt
	{
		float:left;
		clear:left;
		text-align:right;
		font-weight:normal;

		width: @label-width;

		&::after
		{
			content:":"
		}
	}
	dd
	{
		margin-left:@label-width;
		padding-left:1rem;
	}

	.street1, .street2
	{
		display:block;
	}
}

.customer-edit-tabs-component, .supplier-edit-tabs-component {
	.scrollable-container();

	nav {
		flex: 0 0 20px;
	}

	#content {
		.scrollable-region();
	}
}
