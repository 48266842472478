@import "_variables";
@import "_mixins";



.work-order-connection-component {
    .scrollable-container();

    .searchbox {
        padding: 0 0 1rem 0;
    }

    .connection-section {
        .scrollable-container();

		td
		{
			text-align:left;
		}

        &:first-child {
            margin-right: 0.5rem;
        }

        &:last-child {
            margin-left: 0.5rem;
        }

        .date, .number {
			white-space: nowrap;
        }

        .connection-actions {
            width: 220px;
        }

		td.actions
		{
			text-align:right;
		}
    }
}

//parts tab

.workorder-area .purchase-order {
	.purchase-order-info {
		font-weight:bold;
	}

	.purchase-order-info-number {
		padding-right:3rem;
	}

	.purchase-order-actions {
		float:right;
		margin-bottom:10px;
	}

	.purchase-order-name {
		text-align:center;
		clear:both;
		padding:0.3rem;
		border:1px solid @gray;
		background-color:rgba(255,255,255, 0.6);
	}
}
.work-order-parts-component {
	.table-select-list {
		.select-all {
			td {
				border:none;
			}
			&:hover {
				td {
					background-color:transparent!important;
					transition: @gobal-transition;
				}
			}
		}
	}
}

.work-order-search-component {
	.wo-col {
		width:9%;
	}
	.pm-col {
		width:15%;
	}
	.name-col {
		width:18%;
	}
	.cust-col {
		width:15%;
	}
	.date-col {
		width:12%;
	}
	.priority-col {
		width:10%;
	}
	.status-col {
		width:12%;
	}
	.list-actions {
		width:8%;
	}
}

.purchase-order-connection-component {
    .connection-section {
        .scrollable-container();
        .connection-actions {
            text-align:right;
        }
        .date,
        .number,
        .name,
        .connection-actions {
            width:25%;
        }
    }
}

@media screen and (max-width:991px) {
	.work-order-connection-component {
		.actions {
			.input-group {
				margin-bottom:.5rem;
			}
		}
	}
	.workorder-area {
		.purchase-order {
			.purchase-order-info-number {
				padding-right:1rem;
			}
		
			.purchase-order-actions {
				margin-bottom:.5rem;
			}
		}
		table {
			font-size:.5rem;
		}
	}
}