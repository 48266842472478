.dashboard {
    .tools,
    .message {
        align-self: flex-end;
        h1 {
            font-family: @font-body;
            font-size: 1rem;
            margin:0;
        }
    }
    .entity-row {
        display:flex;
        flex-wrap:wrap;
    }
    .entity-container {
        flex: 1 0 50%;
        max-width: 50%;
    }
    .card {
        background-color: @white-pure;
        padding: .5rem;
        margin-bottom: .5rem;
        .total {
            font-size: .834rem;
            text-align: center;
            .number {
                color:@reddish;
                font-size: 2.5rem;
            }
        }
        &.entity-summary {
            cursor: pointer;
            .title {
                font-size: 1rem;
                line-height:1;
                .entity-label {
                    text-transform: lowercase;
                }
            }
            .item {
                color: @gray-light;
                border-right: 1px solid @gray-light;
                display:inline-block;
                font-size: .5rem;
                padding:0 .5rem 0 0;
                margin-right:.5rem;
                &:last-child {
                    border-right:none;
                }
                .pseudo-link {
                    color:@gray-light;
                    text-transform: lowercase;
                }
            }
        }
        &.entity-full {
            margin-bottom:0;
            margin-top:1rem;
            .entity-color {
                display:inline-block;
                height:10px;
                width:10px;
                margin-right:5px;
                &.entity-type1 {
                    background-color: @color-invoice;
                }
                &.entity-type2 {
                    background-color: @color-payable;
                }
                &.entity-type3 {
                    background-color: @color-purchaseorder;
                }
                &.entity-type4 {
                    background-color: @color-workorder;
                }
            }
            .secondary-label {
                color:@gray-light;
                font-size:.5rem;
                font-style: italic;
            }
            .entity-header {
                border-left:4px solid @white-pure;
                border-bottom: 4px solid @white;
                font-size:.5rem;
                padding-bottom:5px;
                margin-top:5px;
                margin-bottom:5px;
                text-transform: uppercase;
            }
            .item {
                border-left:4px solid @white-pure;
                cursor: pointer;
                font-size:14px;
                padding-top:5px;
                padding-bottom:5px;
                transition:@gobal-transition;
                .item-status {
                    font-size:10px;
                    text-transform: uppercase;
                }
                a {
                    text-transform: uppercase;
                }
                &:hover {
                    background-color:@white;
                    transition:@gobal-transition;
                }
                .glyphicon-time {
                    display:none;
                    color:@reddish;
                    position: absolute;
                    left: -17px;
                    top: 3px;
                }
                &.late {
                    border-left:4px solid @reddish;
                    .glyphicon-time {
                        display:block;
                    }
                }
                div {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
            .nav-link {
                font-size:11px;
                text-transform: uppercase;
            }
        }

    }
    @media screen and (max-width:767px) {
        .add-on {
            margin-top:18px;
        }
    }
}
@media (max-width:991px) {
    .dashboard {
        .entity-row {
            display:block;
        }
        .entity-container {
            max-width:100%;
        }
    }
}
