@import "_variables";

form {
	&.form-horizontal {
		width: 100%;
	}
	legend {
		border-bottom:none;
	}
}
.form-group {
	margin-bottom:20px;
	label {
		font-weight: bold;
		font-size: .5rem;
		text-align:left;
		white-space: normal;
		@media (min-width: 768px) and (max-width:991px) {
			font-size: .45rem;
		}
		&.control-label {
			input[type=checkbox].inline {
				position: relative;
				top:2px;
			}
		}
		&.inline-label {
			padding-top: 8px;
		    width: 100%;
		}
	}
}
.checkbox {
	width: 1rem;
}
input[type=checkbox].large {
	height: 20px;
	width: 20px;
	position: relative;
	top: 3px;
}

.btn-checkbox {
	i {
		opacity: 0;
	}

	&.btn-danger,
	&.btn-primary,
	&.btn-warning {
		i {
			opacity: 1.0;
		}
	}
}
.form-control {
	max-width:100%;
}
.form-control-auto {
	float: right;
	width: auto;
}
.form-control:focus {
	border-left:1px solid @color-workorder!important;
}
.ta-results {
	background-color: @white-pure;
	border-width: 0 1px 1px 1px;
    border-color: @input-border;
    border-style: solid;
	width:auto;
	z-index:5;
	.ta-item {
		border: none;
		border-radius:none;
		font-size:.58rem;
		padding:3px 20px;
		&:hover {
			background-color:#337ab7;
			color:@white-pure;
		}
	}
}

label {
	&.city-st-zip {
		width: 100%;
		text-align: left!important;
	}
	.city {
		display: inline-block;
		width:50%;
		&.full-width {
			width:100%;
		}
	}
	.state {
		display: inline-block;
		width:20%;
	}
	.zip {
		display: inline-block;
		width:30%;
	}
	&.province-pcode {
		width: 100%;
		text-align: left!important;
	}
	.province {
		display: inline-block;
		width:75%;
	}
	.postal-code {
		display: inline-block;
		width:25%;
	}
}
.form-control {
	&.city {
		width:50%;
		&.full-width {
			width:100%;
		}
	}
	&.state {
		width:20%;
	}
	&.zip {
		width:30%;
	}
	&.province {
		display: inline-block;
		width:75%;
	}
	&.postal-code {
		display: inline-block;
		width:25%;
	}
}
.full-width {
	width: 100%;
}

.input-group-addon {
	cursor: default;
}

.read-only {
	background-color: transparent;
	border: none;
	box-shadow: none;
	cursor: not-allowed;
	font-size: 0.7rem;
	font-weight: bold;
	padding-left: 0;
}

table {
	&.line-items {
		tr {
			line-height: 1.5rem;
		}
	}
	&.table-select-list {
		label {
			font-weight: bold;
			font-size: .5rem;
		}
	}
	tr {
		&.new-entry {
			td {
				width:14.29%;
				padding: .5rem;
			}
			&:nth-child(even) {
				background-color: #ffffff;
			}
		}
	}
}
.progress {
	height:7px;
	.progress-bar {
		background-color:@color-invoice;
	}
}
#file-uploader {
	background-color:transparent!important;
}
.document-upload-component label.add-on span {
	font-size: .6rem;
	font-weight: normal;
}
.uploader {
	thead {
		tr {
			th {
				font-size:.5rem;
			}
		}
	}
}
.upload-controls {
	.progress {
		background-color:@gray;
	}
	.pseudo-link {
		display: inline-block;
		position: relative;
		top:2px;
		text-transform: capitalize;
	}
}
ul.docs-list {
	margin-bottom:0.5rem;
	li {
		align-items: center;
		border-top: 1px solid @input-border;
		display:flex;
		padding: 0.5rem 0;	
		&:last-child {
			border-bottom: 1px solid @input-border;
			padding-bottom: 11px;
			margin-bottom:0.5rem;
		}
		a {
			color:@gray;
			text-decoration: underline;
		}
	}
}
table tr.docs-list {
	&:last-child {
		td {
			border-bottom: 1px solid @input-border;
		}
	}
	td {
		padding:.5rem .2rem;
		line-height: 2.2;
	}
}
.documents-section {
	padding:1rem;
}
input[enforceDecimal] {
	text-align: right;
}

input,
select,
option,
textarea {
	font-family: @font-body;
	&.ng-invalid,
	&.unmatched {
		border-left: solid 5px @reddish!important;
	}
}

input[required],
select[required],
textarea[required] {
	&.ng-valid {
		border-left: solid 5px @green;
	}
}
.ng-select-component[required] {
	&.ng-invalid {
		.ng-value-container {
			border-left: solid 5px @reddish!important;
		}
	} 
	&.ng-valid {
		.ng-value-container {
			border-left: solid 5px @green;
		}
	}
}

select.status-options {
	font-size: .5rem;
	width: auto;
	text-transform: uppercase;
}
.status-options {
	&.green {
		color: @color-invoice;
	}
	&.bright-green {
		color: @green;
	}
	&.blue {
		color: @color-workorder;
	}
	&.orange {
		color: @alert-color;
	}
	&.red {
		color: @reddish;
	}
	&.gray {
		color: @gray;
	}
	option {
		font-weight: bold;
		font-size: .5rem;
		&.green {
			color: @color-invoice;
		}
		&.bright-green {
			color: @green;
		}
		&.blue {
			color: @color-workorder;
		}
		&.orange {
			color: @alert-color;
		}
		&.red {
			color: @reddish;
		}
		&.black {
			color: @black;
		}
		&.gray {
			color: @gray;
		}
		text-transform: uppercase;
		&:disabled {
			display:none;
			&.locked {
				display:block;
			}
		}
		&.locked {
			color:@gray-light;
		}
	}
}
.filtered-select {
	option[disabled] {
		color:@gray-light;
	}
}
.width-auto {
	width:auto;
}
.vertial-middle {
	vertical-align: middle;
}
.inline-control {
	label {
		float: left;
	}

	input,
	select {
		float: right;
	}
}

label {
	white-space: nowrap;
}

textarea.notes {
	min-height: 1.4rem;
	height: 1.4rem;

	&:focus {
		min-height: 4rem;
	}

	&.notes-large {
		height: 8.5rem;
		min-height: 8.5rem;
		padding: 0.25rem;
		width: 100%;

		&:focus {
			min-height: 8.5rem;
		}
	}
}

textarea.text-area-md {
	min-height: 3rem;
}

.readonly-multiline {
	white-space: pre-wrap;
}
textarea {
	border-radius: @global-border-radius;
	border-color:@input-border;
	box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
	width:100%;
}

.ng-select-component {
	.ng-select-container {
		&.ng-has-value {
			.ng-placeholder {
				display:none;
			}
		}
		.ng-value-container {
			text-overflow: ellipsis;
			width: 100%;
			min-height: 34px;
			padding: 3px 5px;
			font-size: 14px;
			line-height: 1.42857143;
			color: #555;
			background-color: @white-pure;
			background-image: none;
			border: 1px solid @input-border;
			border-radius: 4px;
			box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
			transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
			.ng-placeholder {
				padding: 3px 10px 1px 5px;
				position: absolute;
				z-index: 1;
			}
			.ng-value {
				background-color:@white-pure;
				border: 1px solid @input-border;
				color:#555;
				padding: 2px 5px 1px 5px;
				font-size: 12px;
				line-height: 1.5;
				border-radius: 3px;
				margin: 1px 3px 1px 8px;
				outline: 0;
				position: relative;
				z-index: 10;
			}
			.ng-input input {
				padding:3px 13px!important;
			}
		}
		.ng-clear-wrapper {
			position: absolute;
			right:0;
			top:3px;
		}
	}
	.ng-value .ng-value-icon,
	.ng-clear-wrapper {
		color: #337ab7;
		float: right;
		font-weight: bold;
		font-size: 1.6em;
		line-height: 0.75;
		margin-top: 1px;
		margin-left: 5px;
		opacity: 1;
		padding: 0;				
	}

	.ng-dropdown-panel {
		padding: 0;
		font-size: 14px;
		line-height: 1.42857143;
		color: #555;
		background-color: @white-pure;
		border: 1px solid @input-border;
		border-radius: 4px;
		-webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
		box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
		-webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
		-o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
		transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
		.ng-dropdown-panel-items {
			padding: 0;
			.ng-option {
				padding: 6px 12px;
				width:100%;
				&:hover {
					color: @white-pure;
					background-color: #428bca;
					font-weight:400;
				}
			}
		}
	}
	&.ng-select-multiple {
		.ng-select-container {
			overflow: unset;
		}
	}
	&.ng-select-single {
		.ng-value-container {
			.ng-value {
				border: none;
				padding: 1px 5px 1px 5px;
				font-size: 14px;
			}
		}
	}
	&:focus {
		border-color: #66afe9;
		outline: 0;
		box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%);
	}
}

.my-date-range-picker-component {
	border: 1px solid @input-border;
	border-radius: 4px;
	height:32px;
	width:100%;
	padding-left:5px;
	padding-right:5px;
	button.btnpicker {
		margin-bottom:0!important;
	}
	.selectiongroup {
		input,
		.selbtngroup {
			height:32px!important;
		}
	}
}

.form-group.month-field {
	width:180px;
}
.month-picker-component {
	.month-picker {
		background-color: @white-pure;
		border:1px solid @input-border;
		max-width:150px;
		position: absolute;
		z-index: 100;
		.header,
		.body {
			background-color: transparent;
			border-radius:0;
			width:100%;
		}
		button {
			background-color: transparent;
			border-radius:0;
			font-size: .675rem;
			&.btn-primary {
				background-color: #337ab7;
			}
		}
	}
}
.custom-checkbox {
	display: block;
	position: relative;
	padding-left: 30px;
	padding-top:3px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
		&:checked ~ .checkmark {
			background-color: @button-primary-color;
			border: 1px solid @button-primary-color;
		}
		&:checked ~ .checkmark:after {
			display: block;
		}
	}
	.custom-label {
		margin-left:10px;
		font-size:.65rem;
		position: relative;
	    top: -9px;
	}
	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 22px;
		width: 22px;
		background-color: @white-pure;
		border-radius: @global-border-radius;
		border: 1px solid @input-border;
		&:after {
			content: "";
			position: absolute;
			display: none;
			left: 8px;
			top: 4px;
			width: 5px;
			height: 10px;
			border: solid @white-pure;
			border-width: 0 3px 3px 0;
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
			transform: rotate(45deg);
		}
	}
	&:hover input ~ .checkmark {
		background-color: @color-workorder;
		transition: @gobal-transition;
	}
	&:hover input:checked ~ .checkmark {
		background-color: @button-primary-color-hover;
		transition: @gobal-transition;
	}

}

/* Customize the label (the container) */
.custom-radio {
	display: block;
	position: relative;
	padding-left: 30px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 22px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
		&:checked ~ .radiobtn {
			background-color: @button-primary-color;
			&:after {
				display: block;
			}
		}
	}
	.custom-label {
		font-size:.65rem;
	}
	.radiobtn {
		position: absolute;
		top: 0;
		left: 0;
		height: 22px;
		width: 22px;
		background-color: @white-pure;
		border-radius: 50%;
		border: 1px solid @input-border;
		&:after {
			content: "";
			position: absolute;
			display: none;
			top: 6px;
			left: 6px;
			width: 8px;
			height: 8px;
			border-radius: 50%;
			background: @white-pure;
		}
	}
	&:hover input ~ .radiobtn {
		background-color: @color-workorder;
		transition: @gobal-transition;
	}
	&:hover input:checked ~ .radiobtn {
		background-color: @button-primary-color-hover;
		transition: @gobal-transition;
	}
}
.ui-select-container.ui-select-multiple.dropdown.form-control {
	min-height:34px;
	.close {
		color:#337ab7;
		font-weight:bold;
		margin-top:1px;
		opacity: 1;
	}
	input.ui-select-search {
		font-family: @font-body;
		color:@color-text;
		padding:2px 7px 0;
		&:focus {
			border-left:none!important;
		}
	}
}
typeahead-container.dropdown.open {
	width:100%;
}
button.dropdown-item.ng-trigger {
	border: none;
    background-color: transparent;
    display: block;
    padding: 5px 12px;
	text-align:left;
	width:100%;
	&:hover,
	&.active {
		background-color: #337ab7;
		color:@white-pure;
		transition: @gobal-transition;
	}
}
.ng-dropdown-panel-items .ng-option.ng-option-marked {
	background-color: #337ab7;
	color:@white-pure;
	transition: @gobal-transition;
}

@media screen and (max-width:1199px) {
	.form-group {
		.entry-control-content .my-date-range-picker-component .mydrp,
		.filter-entries-component input,
		.filter-entries-component select {
			margin-bottom:20px;
		}
	}
}
@media screen and (max-width:991px) {
	.form-group {
		margin-bottom:0;
		input,
		select,
		.btn-group,
		.input-group,
		textarea,
		button,
		.ng-select-component,
		.entry-control-content .my-date-range-picker-component .mydrp {
			margin-bottom:20px;
		}
		.btn-group button {
			margin-bottom:0;
		}
	}
}
@media (min-width: 768px) and (max-width:991px) {
	.filter-list-component {
		.ng-select-component {
			.ng-select-container {
				.ng-value-container {
					.ng-input input {
						margin-bottom:0;
					}
				}
			}
		}
	}
}
