@import "_variables";
@import "_mixins";

@color-button:#ccc;

.actions {
	text-align:right;
}

.list-actions {
	text-align:right;
}

.list-item-actions {
	text-align:right;
}

.btn-icon {
	i {
		display:none;
	}
}
@media (min-width: 768px) and (max-width:991px) {
	.report-window-component {
		.actions {
			text-align:left;
		}
	}
}

