@import "_variables";
@import "_mixins";


#move-entry{
    margin:0;
    label {
        display:block;
        font-weight: @font-bold;
        font-size:.5rem;
    }
    .ng-select-component {
        display: inline-block;
        min-width: 100%;
        .glyphicon.glyphicon-remove {
            position: absolute;
            top:9px;
            right:20px;
        }
    }
    .input-group {
        .form-control {
            &:first-child {
                border-radius: @global-border-radius
            }
            &:last-child {
                border-radius: @global-border-radius
            }
        }
    }
    input[type="date"].form-control {
        line-height:1!important;
    }
    .disabled {
        color:@gray;
        cursor: not-allowed;
        pointer-events: none;
    }
    .margin-fix-1 {
        margin-top:21px;
    }
}

.entry-controls {
    .toggle-control {
        .glyphicon {
            font-size: 22px;
            top: 5px;
        }
    }
    .disabled {
        color:@gray;
        cursor: not-allowed;
        pointer-events: none;
    }
    .btn.disabled {
        color:@white-pure;
    }
}
