@import "_variables";
@import "_mixins";

.job-search-component {
    div.row:last-child {
        .scrollable-container();
    }

    .container {
        .scrollable-container();
    }

    .incomplete::after {
        background-color: fade(@white-burnt, 80%);
        bottom: -0.5rem;
        content: 'Please select a job';
        font-size: 0.8rem;
        font-weight: bold;
        left: 0;
        padding-top: 1rem;
        position: absolute;
        right: 0;
        text-align: center;
        top: -0.5rem;
        z-index: 3;
    }

    .time-entry-job-selector {
        margin-top: 1rem;

        .time-entry-job-info {
            font-size: 0.9rem;
            font-weight: bold;
            line-height: 2.5rem;
        }

        .time-entry-job-results {
            background-color: @white-pure;
            border: 1px 1px 0 1px solid @black;
            position: absolute;
            top: 2rem;
            left:123px;
            z-index: 20;

            button {
                clear: both;
                float: right;
                margin-left: 1rem;
            }

            .time-entry-job-results-item {
                border-bottom: solid 1px @dark;
                line-height: 1.5;
                padding: 0.25rem 0.5rem;
                display:flex;
                flex-direction: row;
                align-items: baseline;
                justify-content: space-between;
            }
        }
    }

    .time-entry-form {
        display: flex;
        flex-direction: row;
        margin-top: 1rem;

        label {
            font-weight: bold;
        }

        > div {
            padding: 0 0.25rem;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }
        }

        &-date {
            flex: 0 0 200px;
        }

        &-staff {
            flex: 1;
        }

        &-rate {
            flex: 0 0 160px;
            text-align: right;
        }

        &-hours {
            flex: 0 0 160px;
            text-align: right;
        }

        &-total {
            flex: 0 0 160px;
            text-align: right;
        }

        &-actions {
            flex: 0 0 80px;
        }
    }

    .time-entry-list-container {
        .scrollable-container();

        margin-top: 1rem;
    }
}
.new-entry {
    border-bottom:1px solid @sidebar-header-bg;
    &:nth-child(odd) {
        background-color:@white-pure;
    }
}
.width-10 {
    width:10%;
}
.width-15 {
    width:15%;
}
.width-20 {
    width:20%;
}
.width-40 {
    width:40%;
}
.job-search-component,
.time-entry-component, 
.job-time-entry-component {
    input.unmatched {
        background-color: #C3FCFF;
        border-top: 2px solid #5AC6CC;
        border-right: 2px solid #5AC6CC;
        border-bottom: 2px solid #5AC6CC;
    }
}
section.time-entry {
    .new-entry {
        input {
            margin-bottom:20px;
        }
    }
    .attached {
        .checkbox-column {
            margin-left: 24px;
            width: 36px;
            float: left;
        }
        .data-entry-column {
            float: left;
            width: calc(~"100% - 90px");
            input,
            .input-group,
            select {
                margin-bottom:20px;
            }
        }
        table tbody:hover {
            background-color: @white-pure;
            transition: @gobal-transition;
        }
    }
    .checkbox-container {
        width:3%;
    }
    .date-staff {
		width: 20%;
	}

	.hours {
		width: 11%;
	}

	.rate {
		width: 9%;
	}

	.total {
		width: 10%;
    }
    .buttons {
        width:27%;
    }
    .edit-view {
        .date-staff {
            width: 25%;
        }
    
        .hours {
            width: 15%;
        }
    
        .rate {
            width: 10%;
            .input-group {
                width:100px;
            }
        }
        textarea {
            border-radius: @global-border-radius;
            width:100%;
        }
        .no-padding {
            padding-right:0;
        }

    }
    .table-select-list {
        th {
            padding:2px 8px 8px;
            input[type=checkbox] {
                height:20px;
                width:20px;
                position: relative;
                top:5px;
                left:1px;
            }
        }
        td.checkbox-container,
        div.checkbox-container {
            input[type=checkbox] {
                height:20px;
                width:20px;
                position: relative;
                top:-3px;
            }
        }
    }

}
.job-search {
    margin-bottom:4rem;
}
@media screen and (max-width:1199) {
    section.time-entry {
        .edit-view {
            .no-padding {
                padding-right:15px;
            }
        }
    }
}
@media screen and (max-width:991px) {
    section.time-entry {
        .date-staff.staff-member {
            width: 50%;
        }
        table.table.table-select-list tbody tr td {
            font-size:.5rem;
            .entry-label {
                font-size:.4rem;
            }

        }
    }
}