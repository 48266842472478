@import "_variables";
@import "_mixins";

.report-sidebar-component {
	flex:1 0 0;

	header
	{
		h2 { margin-top:0;}
	}
    .report-group-header {
        background-color:lighten(@sidebar-header-bg, 5%);
        border-bottom: 1px solid #ddd;
        font-size: 0.8rem;
        font-weight: bold;
        margin: 0;
        padding:0.5rem 1rem .5rem .5rem;
        text-transform: uppercase;
    }

    .report-list {
        line-height: 1.2rem;
        list-style-type: none;
        margin: 0;
        padding: 0;

        li {
            a {
                display: block;
                padding: 0.5rem;
                text-decoration: none;
                width: 100%;

                &.active.report-active {
                    background-color: @seafoam;
                }
            }

            &:hover {
                background-color: @seafoam;
                cursor: pointer;
                transition: @gobal-transition;
            }
        }
    }
}

.report-controls {
    background-color: @white-burnt;
    flex: 0 0 auto;
    margin: 0 -1rem;
    padding: 1rem .5rem;

    .job-selector {
        float: left;
        margin-right: 1rem;
        margin-bottom:0;
        width: 400px;

        .job-info {
            font-size: 0.6rem;
            font-weight: bold;
            line-height: 1.6rem;
        }

        .job-results {
            background-color: @white-pure;
            border: 1px solid @black;
            position: absolute;
            top: 2.6rem;
            z-index: 5;

            button {
                clear: both;
                float: right;
                margin-left: 1rem;
            }

            .job-results-item {
                border-bottom: solid 1px @dark;
                line-height: 1.5;
                padding: 0.25rem 0.5rem;
                display:flex;
                flex-direction: row;
                align-items: baseline;
                justify-content: space-between;
                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }

    .number-selector {
        display: inline-block;
        padding: 0 0.25rem;
        width: 150px;

        .form-control {
            display: inline-block;
            width: 80px;
        }
    }
    .pm-selector {
        display:flex;
        align-items: center;
        label {
            margin-bottom:0;
        }
    }
    input[type=date].form-control {
        max-width:160px;
    }
}

#reportPane {
    .scrollable-container();

    margin: 1rem 0rem -1rem 0rem;
    padding:0 1rem;
    position: relative;

    table {
        caption {
            color: @black;
            font-weight: bold;
            font-size: 22px;
        }
        th {
            font-size: 16px;
        }
    }

    &.dirty {
        &::after {
            background-color: fade(@white-burnt, 92%);
            bottom: 0;
            content: 'Please re-run the report to see the most up-to-date data';
            font-size: 0.7rem;
            font-weight: bold;
            left: -1rem;
            padding-top: 1rem;
            position: absolute;
            right: -1rem;
            text-align: center;
            top: -0.5rem;
        }
    }

    .titleArea {
        line-height: 1.5;

        .title {
            font-size: 18px;
            font-weight: bold;
        }
    }

    .noBorder{
        border: none;

        td {
            border-top: none;
            width: 20%;

            &:nth-child(1) {
                width: 40%;
            }

            &.underline {
                border-bottom:1px solid #ddd;
            }
        }

        tr:first-child {
            td {
                padding-top: 24px;
            }
        }
    }

    .total {
        font-size: .9rem;
    }
}

.report-job-outcome-component {
    #reportPane {
        font-size: 0.6rem;
    }
}
.report-window-component {
    .no-wrap {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px;
    }
    .table-subhead {
        color: @gray;
        text-transform: uppercase;
        font-size: 80%;  
    }
    .table-total {
        font-weight: bold;
        margin-top:8px;
        text-transform: uppercase;
    }
    .bid-number {
        background-color:@gray-lighter;
        color:@gray;
        padding:10px;
        &.tight {
            padding: 0 10px;
        }
    }
    .bid-number-spacer {
        background-color:transparent;
        color:inherit;
        padding:10px 0;
    }
    .item-total {
        padding-right:10px;
    }
}

.report-job-detail-component,
.report-job-summary-component {
    .search-component .input-entry.actionable {
        margin-right:3.5rem;
    }
}
.report-job-detail-component {
    overflow: visible;
    .table>tbody>tr>td {
        line-height:2;
    }    
    tr {
        &.caption {
            td {
                text-transform: uppercase;
                font-size: .85rem;
            }
        }
    }
}
.anchors {
    display:inline-block; 
    text-align:right;
    ul {
        list-style-type: none;
        padding:0;
        li {
            border-left:1px solid @gray;
            display:inline-block;
            padding:0 5px;
            text-transform: none;
            &:first-child {
                border:none;
            }
            &:nth-child(2) {
                border:none;
            }
        }
    }
}
@media screen and (max-width:1024px) {
    .outcome-report {
        th, td {
          width:7%;  
          font-size:.45rem!important;
        }
    }
    #reportPane,
    .report-window-component {
        .table {
            td, th {
                font-size:.5rem;
            }
        }
    }

}
.report-journal-cost-component {
    .attached {
        table tbody:hover td {
            background-color:@white-pure;
            transition: @gobal-transition;
        }
    }
}
@media screen and (max-width:991px) {
    .report-controls {
        padding:1rem;
        margin:0 -.5rem;
        .number-selector {
            margin-bottom:.5rem;
            &:last-child {
                margin-bottom:0;
            }
        }
        button {
            margin-top:.5rem;
        }
        .search-component {
            button {
                margin-top:0;
            }
        }
        .input-group button {
            margin-top:0;
        }
    }
    #reportPane.dirty::after {
        left:-.5rem;
        right:-.5rem;
    }
    .labor-codes-component, 
    .job-time-entry-component, 
    .job-costs-component, 
    .work-order-edit-component, 
    .work-order-connection-component, 
    .work-order-pm-notes-component, 
    .work-order-parts-component, 
    .purchase-order-edit-component, 
    .purchase-order-connection-component,
    .purchase-order-invoice-connection-component, 
    .purchase-order-payable-tab-component, 
    .purchase-order-parts-component, 
    .purchase-order-costs-component, 
    .purchase-order-receipts-component, 
    .report-job-list-component, 
    .report-journal-cost-component, 
    .report-time-entry-component, 
    .report-job-outcome-component, 
    .report-monthly-costs-component, 
    .report-job-detail-component,
    .report-job-summary-component, 
    .report-invoice-summary-component {
        padding:.5rem;
    }
    .report-journal-cost-component {
        .report-controls {
            input.form-control {
                margin-bottom:.5rem;
            }
        }
    }
    .report-job-outcome-component {
        .report-controls {
            .col-md-6 {
                margin-top:.5rem;
            }
        }
    }
}

@media screen and (max-width:767px) {
    .report-job-list-component {
        padding: 0;
        margin: .5rem;
        width: calc(100vw - 1rem);
        .report-controls {
            margin:0;
            button {
                margin-top:.5rem;
            }
        }
    }
}
