
@import "_variables";

.login-component {
	display:flex;
	width:100%;
}

#login {
	margin:4rem auto 2rem;
}