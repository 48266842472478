@import "_variables";

.costs-component {
    div.row:last-child {
        .scrollable-container();
    }

    .container {
        .scrollable-container();
        display: inline-block;
    }

    .incomplete::after {
        background-color: fade(@white-burnt, 80%);
        bottom: -0.5rem;
        content: 'Please select a job';
        font-size: 0.8rem;
        font-weight: bold;
        left: 0;
        padding-top: 1rem;
        position: absolute;
        right: 0;
        text-align: center;
        top: -0.5rem;
        z-index: 3;
    }

    .costs-job-selector {

        .costs-job-info {
            font-size: 0.9rem;
            font-weight: bold;
            line-height: 2.5rem;
        }

        .costs-job-results {
            background-color: @white-pure;
            border: 1px solid @black;
            position: absolute;
            top: 1.9rem;
            left: 125px;
            z-index: 5;

            button {
                clear: both;
                float: right;
                margin-left: 1rem;
            }

            .costs-job-results-item {
                border-bottom: solid 1px @dark;
                line-height: 1.5;
                padding: 0.25rem 0.5rem;
                display:flex;
                flex-direction: row;
                align-items: baseline;
                justify-content: space-between;

                &:last-child {
                    border-bottom: none;
                }
            }
        }
    }

    .costs-list-container {
        display: flex;
        flex-direction: row;
        margin-top: 1rem;
    }
}

.costs-list-component {
    margin-top: 1rem;
    .type-controls {
        button.active {
            pointer-events: none;
        }
    }
    .costs-type-required {
        border-left: 5px solid @reddish;
        border-radius: 3px 0 0 3px;
        float: left;
        height: 34px;

        &.valid {
            border-left: 5px solid @green;
        }
    }

    .costs-total {
        font-weight: bold;
        line-height: 1.4rem !important;
    }

    label {
        display:block;
        font-weight: @font-bold;
        font-size:.5rem;
    }
    .po-cost-list-container {
        table thead th {
            padding:2px 8px 8px;
        }
        tbody:hover {
            background-color: @white-pure;
            transition: @gobal-transition;
            cursor: pointer;
        }
    }
    @media screen and (max-width:991px) {
        table.table.table-select-list tbody tr td {
            font-size:.5rem;
            .entry-label {
                font-size:.4rem;
            }
        }
    }
}
.costs-list-container {
    .costs-list-component {
        .attach-po {
            display:none;
        }
    }
}

