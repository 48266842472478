@import "_variables";

.searchbox {
	padding: 1rem .5rem;

	&.searchbox-fixed {
		width: 15rem;
	}
}

caption {
	.searchbox {
		padding: 0;
	}
}

.search-component {
	form {
		position: relative;
	}

	.input-entry.actionable {
		margin-right: 4.5rem;
	}

	.input-action {
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
	}
}

.job-list-component,
.supplier-list-component,
.customer-list-component,
.work-order-connection-component,
.purchase-order-connection-component,
.purchase-order-invoice-connection-component,
.purchase-order-payable-tab-component {
	.search-component input.active {
		background-color: #CEE7F3;
		border: 2px solid #95BBCE;
	}
}

.job-work-order-list-component,
.job-purchase-order-list-component,
.job-change-order-list-component,
.job-invoice-list-component,
.job-payable-list-component {
	.search-component input.active {
		background-color: #C3FCFF;
		border: 2px solid #5AC6CC;
	}
}
