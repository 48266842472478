
@import "_variables";

.select-list-item(@bgColor:@white, @textColor:@color-text, @hoverColor:lighten(@bgColor, 5), @activeColor:lighten(@bgColor, 10)) {
	cursor:pointer;
	padding:0.5rem 0;
	position:relative;

	&:hover
	{
		background-color:@hoverColor;
		transition: @gobal-transition;
	}

	&.active,
	&.active td
	{
		background-color:@activeColor;
	}

	.sidebar &.active::after
	{
		@height:0.83rem;//odd line height set by bootstrap
		@width:0.5rem;
		content:"";
		width:0;
		height:0;
		border-top:@height solid transparent;
		border-bottom:@height solid transparent;
		border-left:0.5rem solid @activeColor;

		position:absolute;
		right:-@width;
		z-index:0;

		filter:drop-shadow(0 1px 1px rgba(0,0,0,0.4));
	}
}

.scrollable-container() {
	display: flex;
	flex-direction: column;
	flex: 1;
}

.scrollable-region() {
	flex: 1;
	overflow: auto;
}
