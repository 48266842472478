@import "_variables";

.btn.btn-condensed {
    padding:6px;
}
.btn {
    &.btn-outline {
        background: @white-pure;
        border-color: @button-border;
        box-shadow: none;
        color: @button-border;
    }
    &.btn-small {
        padding: 0 3px;
        font-size: .5rem;
    }
    &.btn-success.active {
        background-color: @color-action;
        border-color:@color-action;
    }
    transition: @gobal-transition;
    &:hover {
        transition: @gobal-transition;
    }
}

.btn-group {
    button:last-child {
        border-radius: 0 @global-border-radius @global-border-radius 0;
    }
}