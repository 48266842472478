@import "_variables";
@import "_mixins";

.disabled {
    opacity:.5;
    cursor: not-allowed;
    pointer-events: none;
}
.pseudo-link {
    color:@link-color;
    cursor: pointer;
	text-transform: uppercase;
	&.clear {
		color:@reddish;
    }
    &.disabled {
        opacity:.5;
        cursor: not-allowed;
        pointer-events: none;
    }
}
.action-link {
    color:@link-color;
    cursor: pointer;
    text-decoration: none;
    &.delete {
        color:@reddish;
    }
}
.small-label {
    color: inherit;
    font-size: 0.5rem;
    text-transform: uppercase;
}
.add-on.disabled {
    opacity:.5;
    cursor: not-allowed;
    pointer-events: none;
}
.clearfix {
    clear: both;
    &:before {
        clear: both;
    }
    &:after {
        clear: both;
    }
}
.pointer {
    cursor: pointer;
}
.capitalize {
    text-transform: capitalize!important
}
.click-input {
    color:#777;
    cursor: pointer;
    font-family: @font-body;
}
.text-divider {
	margin: 0 .5rem;
}
.toggle-control {
    cursor: pointer;
}
.inline-block {
    display:inline-block!important;
}
.line-break {
    white-space: pre-line;
}
ul.unstyled {
    list-style-type: none;
    padding: 0;
    margin: 0;   
}
select {
    &.unstyled {
        border: none;
        background-color: @white;
        font-weight: bold;
        font-size: .6rem;
    }
}
.font-normal {
    font-weight:normal!important;
}
.font-bold {
    font-weight:bold!important;
}
.hr {
    border-bottom: 1px solid #ddd;
    width:100%;
}
.border-top {
    border-top: 1px solid #ddd;
}
.border-right {
    border-right: 1px solid #ddd;
}
.border-bottom {
    border-bottom: 1px solid #ddd;
}
.border-left {
    border-left: 1px solid #ddd;
}
.border-left-md {
    border-left: 1px solid #ddd;
    @media screen and (max-width:767px) {
        border-left: none;
    }
}
.shaded {
    background-color:@gray-light;
    th, td {
        border-bottom:none!important;
    }
}
.optional-tag {
    float:right; 
    font-size: .5rem;
    font-style: italic; 
    font-weight:normal;
    color:@gray;
    padding-right:4px;
}
.error {
    border: 1px solid @reddish!important;
    border-radius: @global-border-radius;
}
.error-msg {
    color: @reddish;
}
.entry-label {
    font-size:.5rem;
    text-transform:uppercase;
}
.x-small {
    font-size:75%;
}
.unsaved-change {
    color: @alert-color;
    font-size: 75%;
    margin-top:5px;
    text-align: right;
    .glyphicon-exclamation-sign {
        margin-right:4px;
        position: relative;
        top:2px;
    }
}
table{
    &.no-border {
        tbody,thead {
            tr {
                td, th {
                    border:0;
                }
            }
        }
    }
    &.no-hover {
        tbody {
            tr {
                &:hover {
                    td {
                        background-color: transparent; 
                    }
                }
            }
        }
    }
    &.border-right {
        td {
            border-right: 1px solid #ddd;
            &:last-child {
                border-right: none;
            }
        }
    }
}
.toggle {
    font-size:.6rem;
    color:@gray;
    cursor: pointer;
    display:inline-block;
    .toggle-icon {
        border: 1px solid @gray;
        border-radius:3px;
        display:inline-block;
        font-size: 14px;
        line-height: 1;
        padding:1px 2px 2px;
        text-align:center;
        width:18px;
    }
}
.add-on {
    font-size:.6rem;
    font-weight:normal;
    color:@link-color;
    cursor: pointer;
    display:inline-block;
    .add-icon {
        border: 1px solid @link-color;
        border-radius: 12px;
        display: inline-block;
        padding: 1px 4px 2px 5px;
        line-height: 1;
        color: @link-color;
        margin-right: 5px;
        transition: @gobal-transition;
    }
    &:hover {
        text-decoration: none;
        .add-icon {
            background-color: @link-color;
            color: @white-pure;
            transition: @gobal-transition;
        }
    }
}
// margin
.mt-0 {
    margin-top:0!important;
}
.mt-1 {
    margin-top:.5rem!important;
}
.mt-2 {
    margin-top:1rem!important;
}
.mt-3 {
    margin-top:1.5rem!important;
}
.mb-0 {
    margin-bottom:0!important;
}
.mb-1 {
    margin-bottom:.5rem!important;
}
.mb-2 {
    margin-bottom:1rem!important;
}
.mb-3 {
    margin-bottom:1.5rem!important;
}
.ml-0 {
    margin-left:0!important;
}
.ml-1 {
    margin-left:.5rem!important;
}
.ml-2 {
    margin-left:1rem!important;
}
.ml-3 {
    margin-left:1.5rem!important;
}
.mr-0 {
    margin-right:0!important;
}
.mr-1 {
    margin-right:.5rem!important;
}
.mr-2 {
    margin-right:1rem!important;
}
.mr-3 {
    margin-right:1.5rem!important;
}

// padding
.pt-0 {
    padding-top:0!important;
}
.pt-1 {
    padding-top:.5rem!important;
}
.pt-2 {
    padding-top:1rem!important;
}
.pt-3 {
    padding-top:1.5rem!important;
}
.pb-0 {
    padding-bottom:0!important;
}
.pb-1 {
    padding-bottom:.5rem!important;
}
.pb-2 {
    padding-bottom:1rem!important;
}
.pb-3 {
    padding-bottom:1.5rem!important;
}
.pl-0 {
    padding-left:0!important;
}
.pl-1 {
    padding-left:.5rem!important;
}
.pl-2 {
    padding-left:1rem!important;
}
.pl-3 {
    padding-left:1.5rem!important;
}
.pr-0 {
    padding-right:0!important;
}
.pr-1 {
    padding-right:.5rem!important;
}
.pr-2 {
    padding-right:1rem!important;
}
.pr-3 {
    padding-right:1.5rem!important;
}
/* Modal window */
.shared-modal-component {
    .backdrop{ 
        background-color:rgba(0,0,0,0.6);
        position:fixed;
        top:0;
        left:0;
        width:100%;
        height:100vh;
        z-index: 100;
        display:none;
    }
    .modal-dialog {
        max-width:55%;
        width:auto;
        .close {
            font-size: 40px;
        }
        .modal-header {
            padding:15px 15px 0;
            border: none;
            .modal-title {
                float: left;
                padding-top: 2px;
                font-size: 1rem;
            }
        }
        .modal-body {
            input[type=checkbox], 
            input[type=radio] {
                position: relative;
                top: 2px;
                margin-right: 5px;
            }
            .form-horizontal .control-label {
                text-align: left;
            }
        }
        .modal-footer {
            border: none;
            padding:0;
        }
    }
}
    
.glyphicon.glyphicon-minus-sign {
    color:@error-bg;
}

.x-small {
    font-size:75%;
}

.user-list-component,
.staff-list-component {
    font-size:15px;
    .staff-list-item {
        td.muted {
            color:@gray-light;
        }
        &.disabled {
            opacity:1;
        }
    }
}

table thead.column-spacer {
    max-height: 0;
    th {
        padding:0;
    }
}

.sr-only {
    position: absolute;
    left:-99999px;
    visibility: hidden;
}

nav.export {
    cursor: pointer;
    ul.nav {
        border:none;
        .export-toggle {
            span {
                color: @color-purchaseorder;
                text-transform: uppercase;
                }
            ul {
                li {
                    padding: 0.25rem;
                    button {
                        margin-right: 0.5rem;
                    }
                    &.print-menu-execute {
                        button {
                            width:100%;
                        }
                    }
                }
            }
        }
    }
}
#exportPO {
    border:none;
    display: none;
    &.viewPO {
        display: block;
        .exported-po {
            background-color: #fff;
            font-size: 0.6rem !important;
            max-height: 11in;
            min-height: 11in;
            left: 50%;
            padding: 0.375in;
            position: relative;
            transform: translateX(-50%);
            width: 8.5in;
            .export-header {
                font-size: 0.7rem;
                font-weight: bold;
                position: absolute;
                right: .375in;
                top: 0;
            }
            .header-indicator {
                border-style: solid;
                border-width: 0.25rem 1rem;
                position: absolute;
                height: 0.5rem;
                width: 2rem;
                right: 0;
            }
        }
    }
}
