@import "_variables";
@import "_mixins";

//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//ADDITIONAL IMPORTS AT BOTTOM OF FILE
//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

.job-colorize(@color:@seafoam)
{
	.nav-tabs a {
		color: @color;

		&.active {
			border-bottom:1px solid @color;
			&::after {
				background-color: @color;
			}
		}
	}
}
.job-list-component {
	div.container, 
	.list-container, 
	.job-list-column {
		.scrollable-container();
	}
}

.job-window-component {
	.scrollable-container();
	padding: 1rem;
	width: 100%;

	//breadcrumb colors
	@bc-color-text: @color-text-inverted;
	@bc-bg: @sidebar-header-bg;
	@bc-bg-hover: lighten(@bc-bg, 7%);
	@bc-color-text-hover: @bc-color-text;

	.breadcrumbs-container {
		background-color: @nav-color-dark;
		color: @bc-color-text;
		flex: 0 0 1.5rem;
		margin: -1rem -1rem 0 -1rem;

	}

	.breadcrumbs {
		opacity: 0.5;
		transition: opacity 0.5s ease-out;

		&:hover {
			opacity: 1;
			transition: opacity 0.2s linear;
			transition: @gobal-transition;
		}

		.breadcrumb-item {
			background: transparent;
			display: inline-block;
			margin: 0;
			padding: 0.3rem 0.8rem 0.37rem;
			margin-right: 0.3rem; //space for carat
			position: relative;
			font-size: 0.6rem;
			opacity: 0.5;

			&::after {
				content: '>';
				position: absolute;
				right: -0.3rem;
				text-decoration: none;
				top: 50%;
				transform: translateY(-50%);
			}

			&:last-child::after {
				content: none;
			}

			&.navigable {

				cursor: pointer;
				opacity: 1;

				&:hover {
					background-color: @bc-bg-hover;
					transition: @gobal-transition;
				}

				&:last-child {
					cursor: default;
					text-decoration: none;
					opacity: 0.5;

					&:hover {
						background-color: transparent;
						transition: @gobal-transition;
					}
				}
			}
		}
	}

	//colorize the different areas
	.work-order-component {
		.job-colorize(@color-workorder);
	}
	.purchase-order-component {
		.job-colorize(@color-purchaseorder);
	}
	.change-order-component {
		.job-colorize(@color-changeorder);
	}
	.invoice-component {
		.job-colorize(@color-invoice);
	}
	.payable-component {
		.job-colorize(@color-payable);
		.print-menu-component span.dropdown-toggle {
			color:@color-payable;			
		}
	}
}
.job-summary-component {
	display:block!important;
	.scrollable-container();
	flex-direction:row;
	position: relative;

	#job-info {
		background-color:@sidebar-header-bg;

		h2,h5 {
			margin-top: 0;
		}

		header
		{
			position:relative;
			padding: 1rem 1rem 0;
		}
	}

	#job-navi
	{
		display:block;
	}

	#job-flags {
		text-align: right;

		.tax-exempt, .white, .closed {
			display: inline-block;
			padding:0 0.25rem;
			margin: 0.1rem 0;
		}

		.tax-exempt, .white {
			color: @yellowish;
		}

		.closed {
			color: @reddish;
		}
	}

	#job-tabs {
		ul.nav {
			margin: 0;
		}

		li a {
			padding: 10px 12px;
			text-transform: none;
		}
	}

	#job-lists {
		.scrollable-container();
		position: relative;
	}

	#job-summary-details
	{
		opacity:0.8;
		margin-top:2rem;
		margin-bottom:1rem;
		label
		{
			display:inline-block;
			width:150px;
			margin-right:1rem;
			&::after
			{
				content:":";
			}
		}

		#bids
		{
			span
			{
				text-align:right;
				float:right;
			}
		}
	}
}

.job-change-order-summary-component {
	display: block;
	padding: 0 0 1rem 0;

	.entry {
		margin: 0.25rem 0;
	}

	.total {
		font-weight: bold;
	}
}

.job-invoice-edit-component {
	.print-menu-component {
		.nav-tabs {
			li a.dropdown-toggle {
				padding:6px 15px;
			}
		}
	}
	.print-menu {
		.print-menu-option {
			width: 10rem;
		}
	}
	.line-items {
		.input-group {
			input[enforceDecimal] {
				min-width:55px;
			}
		}
	}
}

.job-work-order-list-component, 
.job-purchase-order-list-component, 
.job-change-order-list-component, 
.job-invoice-list-component,
.job-payable-list-component {
	.scrollable-container();

	header {
		flex: 0 0 @header-height;
	}
}

.work-order-list-component, 
.purchase-order-list-component, 
.change-order-list, 
.invoice-list-component {
	.scrollable-region();

	table {
		margin-bottom: 0 !important;
	}
}

.job-date-arrow {
	float: left;
	padding: 0.25rem;
}
.shared-modal-component .modal-dialog .modal-body {
	.contact-edit {
		.col-md-4 {
			width:50%;
			input[required].ng-invalid {
				border-left: solid 5px #F25050;
			}
			input[required].ng-valid {
				border-left: solid 5px #08910B;
			}
		}
	}
}


.job-edit-form-component .flags-container,
.job-edit-form-component .bid-container {
	margin-top:27px;
}
@media screen and (max-width:991px){
	.job-window-component {
		.work-order-component,
		.purchase-order-component,
		.invoice-component {
			.print-menu-component {
				position: relative;
			}
		}
	}

	#job-lists {
		header {
			button.btn-block {
				display:block;
				margin-top:.5rem;
			}
		}
		.table-select-list {
			font-size:0.45rem;
		}
	}
	#job-navi .sidebar-tabs .nav-tabs a {
		font-size:0.6rem;
	}
	.job-edit-form-component .flags-container,
	.job-edit-form-component .bid-container {
		margin-top:0;
	}
}
@media (min-width: 768px) and (max-width:990px) {
	.job-window-component {
		padding-top:0;
		.breadcrumbs-container {
			display:none;
		}
	}
}

@import "_pmNotes";
