@import "_variables";

.pagination-component {
    .go-to-page-container {
        align-items: center;
        display: inline-flex;
        margin-left:1rem;
        label {
            display: inline-flex;
            font-weight: bold;
            font-size: 0.5rem;
        }
        .go-to-page {
            display: inline-flex;
            max-width: 66px;
            margin-left: 5px;
            padding: 5px;
            width: auto;
            text-align: right;
        }
    }
    .pagination-container {
        display: inline-flex;
        margin-bottom:.5rem;
        ul.pagination {
            margin:4px 0 0;
            > li {
                font-size:.6rem;
                > a,
                > span {
                    border-color: @input-border;
                    color:@link-color;
                }
                &.active {
                    > a {
                        color:@white-pure;
                    }
                }
            }
        }
    }
    @media (max-width:991px) {
        .pagination-container {
            display:block;
            margin-top: 0;
            text-align:left;
            .go-to-page-container {
                display:block;
                margin: .5rem 0 0;
            }
        }
    }
}
