@import "_variables";
@import "_mixins";

body
{
	display:flex;
	min-height:100vh;
	flex-direction:column;
	a:hover {
		transition: @gobal-transition;
	}
}

.my-app-component {
	display: flex;
	flex-direction: column;
	min-height:100vh;
	.logo-container {
		a.disabled {
			pointer-events: none;
		}
	}
}

header#header
{
	display: flex;
	justify-content: space-evenly;
	background-color:@header-bg;
	color:@color-text-inverted;
	flex: 0 0 @header-height;
	padding:0.5rem;
	z-index:100;
	position:fixed;
	width:100%;
	height:52px;

	a
	{
		color:@color-text-inverted;
		line-height:30px; /* logo height */
	}
	.flex-container {
		display:flex;
		justify-content:space-between;
		.nav-container {
			text-align:center;
		}
	}
}

/* scrolling and sticky header */
main {
	.scrollable-container();
	flex: 1 1 0;
	position: relative;
	.labor-codes-component,
	.job-costs-component,
	.job-time-entry-component {
		height:100%;
		overflow: hidden;
		padding: 1rem 0;
	}
}


.job-list-component,
.job-component,
.customer-component,
.customer-list-component,
.work-order-search-component,
.purchase-order-search-component,
.invoice-search-component,
.payable-search-component,
.report-component,
.admin-component,
.supplier-list-component,
.customer-add-component,
.profile-edit-form-component,
.dashboard {
	margin-top:52px;
}
.job-list-component,
.customer-list-component,
.work-order-search-component,
.purchase-order-search-component,
.invoice-search-component,
.payable-search-component,
.supplier-list-component {
	div.container.fixed {
		z-index: 99;
		background-color: @white;
		left: 50%;
		margin-left: calc(-1170px / 2);
	}
	.customer-list-container {
		margin-top:178px;
	}
	.list-container {
		margin-top:219px;
	}
}
.payable-search-component {
	.list-container {
		margin-top:155px;
	}
}

.customer-list-component,
.supplier-list-component {
	table>tbody>tr>td {
		vertical-align: middle;
	}
}
.supplier-list-component {
	.filter-list-component .filters .col-xs-12 {
		width:100%!important;
	}
	@media screen and (max-width:991px){
		.filter-row {
			align-items:center;
		}
	}
}

.admin-component {
  display: flex;
  flex:1;
}

.location-component {
  display: block;
  width: 100%;
  text-align: center;

  .container {
    text-align: left;
  }
}

#scroll-container {
	height:100%;
	overflow-y: scroll;
	width:100%;
}
#list-container {
	height:100%;
	width:100%;
}
.sticky-header {
	background-color: @white;
	position: -webkit-sticky;
  	position: sticky;
  	top: 0;
  	z-index: 10;
}

/*---------------------------*/

.layout-sidebar {
	position: relative;
	.scrollable-container();
	flex-direction: row;
	.sidebar-toggler {
		background-color:@header-bg;
		border:none;
		bottom:0;
		box-shadow: none;
		color:#fff;
		position: fixed;
		top: 52px;
		min-width: 40px;
		height: 100vh;
		z-index: 90;
		left: 0;
		transition: all .5s ease-in-out;
		&:focus,
		&:active {
			outline:none;
		}
	}
	&.narrow {
		padding-left:40px;
		.sidebar.sidebar-narrow {
			background-color: @sidebar-header-bg;
			display:inline-block;
			width:20%;
			position: relative;
			left:0;
			overflow: hidden;
			transition: all .5s ease-in-out;
			flex-basis:auto;
		}
		.sidebar-narrow-main {
			display:inline-block;
			left:0;
			width:80%;
			transition: all .5s ease-in-out;
			flex-basis:auto;
		}
		&.hidden-sidebar {
			.sidebar.sidebar-narrow {
				left:-30%;
				transition: all .5s ease-in-out;
			}
			.sidebar-narrow-main {
				width:100%;
				left:-20%;
				transition: all .5s ease-in-out;
			}
		}
	}
}

.sidebar
{
	.scrollable-container();
	flex: 0 0 @sidebar-width-wide; /* override default flex options */

	&.sidebar-wide
	{
		flex-basis:@sidebar-width-wide;
	}

	.padding-collapse
	{
		margin:0 -1rem;
	}
	.btn {
		white-space: normal;
	}
}

.sidebar-main /* Not the sidebar, the main window */
{
	flex-basis: @sidebar-main-width-wide;
	.scrollable-container();

	position:relative;
}

#content
{
	padding:1rem;
}

article
{
	padding:1rem 0;
}

/* loading screen before angular kicks in */
#loading
{
	position:absolute;
	top:50%;
	left:50%;
	transform: translate(-50%, -50%);

	font-size:2rem;
	font-weight:bold;

	color:darken(@body-bg, 5%);
	text-shadow: 1px 1px 1px lighten(@body-bg, 5%);

	&.data-loading {
		top: 100%;
	}
}

.screen-title {
	font-family: @font-body;
	font-size: 1.2rem;
	margin-bottom: 1rem;
	position: relative;
}

.screen-subtitle {
	font-family: @font-body;
	font-size: 0.9rem;
	margin-bottom: 0.5rem;
	position: relative;
}

.currency-display {
	padding-right: 0.5rem;
	text-align: right;
}

.no-wrap {
	white-space: nowrap;
}

/*----------------------------
the following are unused container nodes output by angular2 component router
They need to be styled in order to allow the flex layout to work properly
*/

.component
{
	display:block;
	width:100%;
}

.customer-component,
.supplier-component,
.customer-list-component,
.supplier-list-component,
.job-component,
.job-list-component,
.report-component,
.report-window-component,
.time-entry-component,
.job-search-component,
.time-entry-list.component,
.costs-list-component {
	.scrollable-container();
}

.work-order-component,
.purchase-order-component,
.invoice-component  {
	.scrollable-container();

	margin: 0 -1rem -1rem -1rem;
	position: relative;

	nav {
		flex: 0 0 20px;
	}
}

.timeout-component {
	display: flex;
	flex-direction: column;
	flex: 1 1 0;
}

.job-edit-component,
.job-change-order-add-component,
.job-change-order-edit-component,
.job-invoice-add-component,
.job-invoice-edit-component,
.job-payable-add-component,
.job-payable-edit-component,
.job-warranty-component,
.work-order-add-component,
.purchase-order-add-component {
	.scrollable-region();

	display: block;
	margin: 0 -1rem -1rem -1rem;
	padding: 1rem 1rem 4rem;
}

.labor-codes-component,
.job-time-entry-component,
.job-costs-component {
	margin: 0 -1rem -1rem -1rem;
}

.labor-codes-component,
.job-time-entry-component,
.job-costs-component,
.work-order-edit-component,
.work-order-connection-component,
.work-order-pm-notes-component,
.work-order-parts-component,
.purchase-order-edit-component,
.purchase-order-connection-component,
.purchase-order-invoice-connection-component,
.purchase-order-payable-tab-component,
.purchase-order-parts-component,
.purchase-order-costs-component,
.purchase-order-receipts-component,
.invoice-edit-component,
.invoice-connection-component,
.report-job-list-component,
.report-journal-cost-component,
.report-time-entry-component,
.report-job-outcome-component,
.report-monthly-costs-component,
.report-job-detail-component,
.report-job-summary-component,
.report-invoice-summary-component {
	.scrollable-container();
	// .scrollable-region();

	padding: 1rem;
}

.large-text {
	font-size:2rem;
	font-weight:bold;

	&.light {
		color:darken(@body-bg, 5%);
		text-shadow: 1px 1px 1px lighten(@body-bg, 5%);
	}
}
.sortable-header-component {
	.glyphicon.glyphicon-chevron-down {
		font-size:.65rem;
	}
}
.entry-controls {
	margin-bottom:.5rem;
	position: relative;
	min-height:1rem;
	.entry-control {
		float:left;
		width:100%;
		.entry-control-tab {
			position: absolute;
			top:0;
			&:nth-child(2) {
				left:200px;
			}
		}
		.entry-control-content {
			position: relative;
			left:0;
			top:24px;
			right:0;
			margin-bottom:1rem;
		}
	}
}
.fixed {
	position: fixed;
}

/* bootstrap overrides
 @media screen and (-webkit-min-device-pixel-ratio: 0){
 	input[type=date].form-control, input[type=time].form-control, input[type=datetime-local].form-control, input[type=month].form-control {
 		line-height: 1;
 	}
 }
*/
@media (min-width: 768px) {
	.form-horizontal .control-label {
		padding-top: 0;
		margin-bottom: 4px;
	}
}
@media screen and (max-width:1199px) {
	.job-list-component,
	.customer-list-component,
	.work-order-search-component,
	.purchase-order-search-component,
	.invoice-search-component,
	.payable-search-component,
	.supplier-list-component {
		div.container.fixed {
			margin-left: calc(-970px / 2);
		}
	}
	.invoice-search-component .list-container,
	.work-order-search-component .list-container,
	.purchase-order-search-component .list-container {
		margin-top:292px;
	}

	.sidebar {
		flex-basis:40%;
	}
	.sidebar-main {
		flex-basis: 60%;
	}
}
.align-center {
	display:flex;
	align-items:center;
}

@media screen and (max-width:991px) {
	h2 {
		font-size: 1rem;
	}
	.job-list-component,
	.customer-list-component,
	.work-order-search-component,
	.purchase-order-search-component,
	.invoice-search-component,
	.payable-search-component,
	.supplier-list-component {
		div.container.fixed {
			margin-left: calc(-750px / 2);
		}
	}
	.job-list-component .customer-list-container,
	.customer-list-component .customer-list-container,
	.work-order-search-component .customer-list-container,
	.purchase-order-search-component .customer-list-container,
	.invoice-search-component .customer-list-container,
	.payable-search-component .customer-list-container,
	.supplier-list-component .customer-list-container,
	.customer-list-component .job-list-container,
	.work-order-search-component .list-container,
	.purchase-order-search-component .list-container,
	.invoice-search-component .list-container,
	.payable-search-component .list-container,
	.supplier-list-component .job-list-container {
		margin-top:162px;
	}
	.customer-list-component .wo-list-container,
	.supplier-list-component .wo-list-container {
		margin-top:128px;
	}
	.customer-list-component .wo-list-container {
		margin-top:128px;
	}
	.work-order-search-component .list-container,
	.purchase-order-search-component .list-container,
	.invoice-search-component .list-container {
		margin-top:275px;
	}
	.job-list-component .list-container {
		margin-top:202px;
	}
	.job-list-component .supplier-list-container,
	.customer-list-component .supplier-list-container,
	.work-order-search-component .supplier-list-container,
	.purchase-order-search-component .supplier-list-container,
	.invoice-search-component .supplier-list-container,
	.payable-search-component .supplier-list-container,
	.supplier-list-component .supplier-list-container {
		margin-top:145px;
	}
	.table-select-list th,
	.sortable-header-component div,
	.infinite-scroll-header-component.sortable div {
		font-size: .5rem;
	}
	.entry-controls {
		.entry-control {
			.entry-control-content {
				input {
					margin-bottom:.5rem;
				}
			}
		}
	}
	.add-supplier-component {
		fieldset {
			padding-right:0!important;
		}
	}
	.sidebar.sidebar-wide {
		flex-basis: 35%;
	}
	.sidebar-main.sidebar-wide-main {
		flex-basis: 65%;
	}
	.layout-sidebar.narrow {
		.sidebar.sidebar-narrow {
			width:30%;
		}
		.sidebar-narrow-main {
			width:70%;
			min-width:70%;
		}
		&.hidden-sidebar {
			.sidebar.sidebar-narrow {
				left:-40%;
			}
			.sidebar-narrow-main {
				left:-30%;
				min-width:100%;
			}
		}
	}
}
#document-uploader,
#file-uploader {
	.my-drop-zone {
		border: dashed 2px @gray-light;
		color:@gray;
		margin:0;
		min-height:90px;
		padding:32px;
		text-align: center;
		label {
			font-weight:normal;
			cursor: pointer;
			margin:0;
		}
		&.nv-file-over {
			border-color: @green;
		}
		&.another-file-over-class {
			border-color: @green;
		}
	}
  &.dnd-hover {
    .my-drop-zone {
      border-color: @green;
    }
  }
}
.document-upload-component,
.gu-unselectable {
	li,
	li.gu-transit {
		.afs {
			font-style: italic;
			margin-left:5px;
		}
		.glyphicon-lock {
			color: @gray;
			padding:9px 11px;
		}
	}
  }

.timestamp {
	font-size: .5rem;
	text-transform:uppercase;
	color: @gray;
}

.custom-label.supplier-status {
	&.preferred {
		color:@color-invoice;
	}
	&.neutral {
		color:@alert-color;
	}
	&.onwatch {
		color:@color-workorder;
	}
}
.supplier-status-container {
	padding-right:0!important;
	.supplier-status-icon {
		border-radius:10px;
		display: inline-block;
		height:10px;
		width:10px;
		&.preferred {
			background-color:@color-invoice;
		}
		&.neutral {
			background-color:@alert-color;
		}
		&.onwatch {
			background-color:@color-workorder;
		}
	}
	&.color-key {
		font-size:14px;
		margin-bottom:.5rem;
		.supplier-status-icon {
			margin-right:8px;
		}
	}
}
.supplier-status.typeahead,
#supplier-list tr {
	&.preferred {
		background-color:rgba(141, 187, 141, .3);
	}
	&.neutral {
		background-color:rgba(232, 156, 48, .3);
	}
	&.onwatch {
		background-color:rgba(109, 171, 236, .3);
	}
}
@media (min-width: 768px) and (max-width:991px) {
	header {
		.account-container {
			font-size:.5rem;
		}
		.site-area-component nav.navbar-default .navbar-nav li a {
			padding:10px 8px;
		}
	}
	body {
		header#header {
			.container {
				.logo-container {
					img {
						width:64px;
					}
				}
			}
		}
	}
	.layout-sidebar.narrow {
		&.hidden-sidebar {
			.sidebar-narrow-main {
				left:-30%;
				min-width:80%;
			}
		}
	}
}

@media screen and (max-width: 767px) {
	body {
		header#header {
			justify-content: space-evenly;
			padding:0;
			position:relative;
			.container {
				padding:0;
				margin:0;
				width:100vw;
				.logo-container {
					position: absolute;
					left:.5rem;
					z-index: 2;
					padding: .5rem 0;
				}
				.flex-container {
					background-color:#193441;
					width:100vw;
					z-index: 1;
					.nav-container {
						padding:.5rem 0 .5rem 0;
						width:100%;
						.collapse.navbar-collapse.menu-open {
							border:none;
							box-shadow:none;
							.account-container {
								width:100%;
								max-width: none;
								#location {
									float:left;
									padding-left:.75rem;
									top:0;
								}
								#account-actions {
									float:right;
									padding-right:.75rem;
									top:0;
								}
								ul.dropdown-menu {
									margin-top:-1px;
								}
							}
						}
					}
				}
			}
		}
		.btn-group-lg>.btn,
		.btn-lg,
		#searchField {
			font-size:.65rem;
		}
		.screen-title {
			font-size: 1rem;
		}

		.job-list-component,
		.job-component,
		.customer-list-component,
		.work-order-search-component,
		.purchase-order-search-component,
		.invoice-search-component,
		.payable-search-component,
		.report-component,
		.admin-component,
		.supplier-list-component,
    .supplier-summary-component,
		.customer-summary-component,
		.customer-edit-tabs-component,
		.customer-add-component,
		.profile-edit-form-component {
			margin-top:0;
			.container {
				margin:0;
				&.fixed {
					margin:0;
					left:0;
					position: relative;
					top:7px;
					.searchbox {
						padding:.5rem;
					}
					.list-actions {
						text-align: left;
						.list-filters {
							margin-bottom:.5rem;
						}
					}
				}
			}
			table.table-select-list {
				th,
				td {
					font-size:.5rem;
				}
			}
		}
		.job-list-component,
		.customer-list-component,
		.work-order-search-component,
		.purchase-order-search-component,
		.invoice-search-component,
		.payable-search-component,
		.supplier-list-component {
			.customer-list-container,
			.list-container,
			.supplier-list-container {
				margin-top:6px!important;
			}
		}
		.layout-sidebar.narrow {
			.sidebar.sidebar-narrow {
				width:60%;
			}
			.sidebar-narrow-main {
				width:30%;
				min-width:30%;
			}
			&.hidden-sidebar {
				.sidebar.sidebar-narrow {
					left:-70%;
				}
				.sidebar-narrow-main {
					left:-60%;
					min-width:100%;
				}
			}
		}
	}
}

/* loading animation */
.lds-ellipsis {
	display: inline-block;
	position: relative;
	width: 64px;
	height: 64px;
	top:30px;
  }
  .lds-ellipsis div {
	position: absolute;
	top: 27px;
	width: 11px;
	height: 11px;
	border-radius: 50%;
	background: darken(@body-bg, 5%);
	animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
	left: 6px;
	animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
	left: 6px;
	animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
	left: 26px;
	animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
	left: 45px;
	animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
	0% {
	  transform: scale(0);
	}
	100% {
	  transform: scale(1);
	}
  }
  @keyframes lds-ellipsis3 {
	0% {
	  transform: scale(1);
	}
	100% {
	  transform: scale(0);
	}
  }
  @keyframes lds-ellipsis2 {
	0% {
	  transform: translate(0, 0);
	}
	100% {
	  transform: translate(19px, 0);
	}
  }
